import React from "react";
import TeamBar from "./TeamBar";
import {
  Select,
  Table,
  Dropdown,
  Button,
  Menu,
  Form,
  Input,
  Modal,
} from "antd";
import { MDBCol } from "mdb-react-ui-kit";
import { LeaveStatus } from "../Options";
import Reject from "../me/leaveModule/Reject";
import AxiosInstance from "../../../service/AxiosInstance";
import Logs from "../me/leaveModule/Logs";
import moment from "moment";
import { EllipseText } from "../../../components/common/Coomon";

function TeamsLeave() {
  const [query, setQuery] = React.useState("");
  const [filteredResults, setFilteredResults] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [statusLeave, setStatusLeave] = React.useState("");
  const [leave, setLeave] = React.useState("");
  const [leaveStatus, setLeaveStatus] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [myTeamLeave, setmyTeamLeave] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [logs, setLogs] = React.useState([]);
  const [employeeIds, setEmployeeIds] = React.useState([]);
  const [rejectComment, setRejectComment] = React.useState("");
  const [form] = Form.useForm();

  const { confirm } = Modal


  //table columns set up basis
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      width: 100,
      sort: "disabled",
    },
    {
      title: "Leave Dates",
      dataIndex: "LeaveStartDate",
      width: 150,
      sort: "disabled",
    },
    {
      title: "Leave Type ",
      dataIndex: "leave_type",
      sort: "disabled",
      width: 100,
    },
    {
      title: "CH Status",
      dataIndex: "Leave_status",
      sort: "disabled",
      width: 100,
    },
    // {
    //   title: "HR Status",
    //   dataIndex: "Status",
    //   sort: "disabled",
    //   width: 100,
    // },
    {
      title: "Leave Note",
      dataIndex: "Reason",
      sort: "disabled",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "Action",
      sort: "disabled",
      width: 100,
    },
  ];

  const leaveDates = (items) => {
    const dateArray = items.Leave_Date_to_Date.split(",");
    const newStartDate = new Date(dateArray[0]);
    const newEndDate = new Date(dateArray[1]);
    const halfStart = items.Leave_half_from;
    const halfTo = items.Leave_half_to;

    let appliedDate = "";
    let days = "";
    if (
      moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY") &&
      halfStart == "First Half" &&
      halfTo == "Second Half"
    ) {
      appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      days = "1 Day";
    } else {
      appliedDate =
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") +
        " - " +
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      if (
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY")
      ) {
        appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
        if (halfStart == "Second Half") {
          days = "0.5 Day (Second Half)";
        } else {
          days = "0.5 Day (First Half)";
        }
      } else {
        let daysLeft =
          moment(newEndDate, "DD/MM/YYYY").diff(
            moment(newStartDate, "DD/MM/YYYY"),
            "days"
          ) + 1;
        if (halfStart == "First Half" && halfTo == "Second Half") {
          days = daysLeft + " Days";
        } else {
          if (halfStart == "Second Half") {
            daysLeft -= 0.5;
          }
          if (halfTo == "First Half") {
            daysLeft -= 0.5;
          }
          days = daysLeft + " Days (" + halfStart + " - " + halfTo + ")";
        }
      }
    }

    return (
      <>
        {appliedDate} <span className="info">{days}</span>{" "}
      </>
    );
  };

  const handleClick = (items, status) => {
    console.log("#####",items,status)
    setLeave(items);
    setLeaveStatus(status);
    if (status == "logs") {
      getLeavesLog(items._id);
      showDrawer();
    } else if (status == "Rejected" || status == "Conditional Approved") {
      setIsModalVisible(true);
    } else {
      confirm({
        title: "Are you sure " + status + " this leave?",
        // title: "Are you sure  this leave?",
        cancelText: "No",
        okText: "Yes",
        okType: "danger",

        onOk() {
          leaveStatusChange(status, items);
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const actionMenu = (items, listAction) => (
    <Menu items={listAction} onClick={(e) => handleClick(items, e.key)} />
  );

  const leaveStatusChange = (status, leaveId) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("leave_id", leaveId._id);
    urlencoded.append("status", status);

    AxiosInstance.post("auth/leave/action/takenByTeamLeader", urlencoded)
      .then((res) => {
        if (res.data.status == 1) {
          setRejectComment("");
          form.resetFields();
          getTeamLeavesss();
        }
      })
      .catch((err) => console.log(err, "err"));
  };

  const actions = (items) => {
    let listAction = [
      {
        key: "Approved",
        label: "Approve",
        items: items,
      },
      {
        key: "Rejected",
        label: "Reject",
        items: items,
      },
      {
        key: "logs",
        label: "Logs",
        items: items,
      },
    ];
    return (
      <>
        <Dropdown.Button
          overlay={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    );
  };

  const getTeamLeavesss = () => {
    const formData1 = new URLSearchParams();

    AxiosInstance.get("/auth/project-team-leave").then(async (res) => {
      let data = [];
      res?.data?.data?.filter((ele) => ele?.Leave_type !== "Exceptional Leave")?.map(async (row) => {
        let detail = {
          // LeaveStartDate: `${moment(row?.StartDate).format("DD/MM/YYYY")} To ${moment(row?.EndDate).format("DD/MM/YYYY")}`,
          employee_name:
            row?.Employee_ID?.First_name + " " + row?.Employee_ID?.Middle_name + " " + row?.Employee_ID?.Last_name,

          LeaveStartDate: leaveDates(row),
          name: `${row?.Employee_ID.First_name} ${row?.Employee_ID.Last_name}`,
          leave_type: (
            <p>
              Unpaid{" "}
              <span className="info">
                Requested on{" "}
                {moment(row?.Create_at).format("MMM DD, YYYY HH:mm a")}
                {" By " + row?.Created_by}
              </span>
            </p>
          ),
          Status: (
            <p>
              {row?.Status}
              <span className="info">
                {row?.Action_on
                  ? " " +
                    "On" +
                    " " +
                    moment(row?.Action_on).format("DD MMM YYYY hh:mm a")
                  : ""}
                {row.Action_by &&
                  " " +
                    "By " +
                    row?.Action_by?.First_name +
                    " " +
                    row?.Action_by?.Middle_name +
                    " " +
                    row?.Action_by?.Last_name}
              </span>
            </p>
          ),
          Leave_status: (
            <p>
              {row?.Status}

              <span className="info">
                {row?.Action_on
                  ? " " +
                    "On" +
                    " " +
                    moment(row?.Action_on).format("DD MMM YYYY hh:mm a")
                  : ""}
                {row.Action_by &&
                  " By " +
                    row?.Action_by?.First_name +
                    " " +
                    row?.Action_by?.Middle_name +
                    " " +
                    row?.Action_by?.Last_name}
              </span>
            </p>
          ),
          // hrRejectReason: <EllipseText text={row.HR_reject_reason} />,
          tlRejectReason: <EllipseText text={row.TL_reject_reason} />,
          Reason: `${row?.Reason}`,
          Created_by: `${row?.Created_by}`,
          action_on: row.Action_on
            ? moment(row?.Action_on).format("MMM DD, YYYY hh:mm a")
            : "",
          Action: actions(row),
        };
        data.push(detail);
      });

      setFilteredResults(data);
      setFilteredData(data)
    });
  };

  const showDrawer = () => {
    setVisible(true);
  };

  function getLeavesLog(id) {
    AxiosInstance.get(
      `auth/leaves/leaveLogs?id=${id}&employee=${employeeIds.join()}`
    ).then(async (res) => {
      // res.data.data
      setLogs(res.data.data);
      showDrawer();
    });
  }

  // when the page first time load then used...
  React.useEffect(() => {
    getTeamLeavesss();
  }, []);

  // when the get team Leaves then used...
  const reRenderFun = (input) => {
    getTeamLeavesss();
  };

  // when the user search then used...
  const handleSearch = (event) => {
    const getSearch = event.target.value;
    if (getSearch.length > 0) {
      const FilterData = filteredResults?.filter((item) =>
        item?.employee_name.toLowerCase().includes(getSearch?.toLowerCase())
      );
      setFilteredData(FilterData);
    } else {
      setFilteredData(filteredResults);
    }
    setQuery(getSearch);
  };
  const handleChange = (value) => {
    setStatusLeave(value);
    const filtered = filteredResults?.filter((item) => {
      return item?.Leave_status?.props?.children[0].includes(value);
    });
    setFilteredData(filtered);
  };

  // when the user close the modal then used...
  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="event-main event-main2 team-leave-view">
        <TeamBar />
        <div className="page-content">
          <div className="attendance-head">
            <p className="tablHeader">Team Leave</p>
          </div>
          <MDBCol size="md-2" className="teamsearch create-project">
            <div className="searchname">
              <Input
                placeholder="Search Employee Name"
                value={query}
                onChange={handleSearch}

                // onChange={(e) => setSearchStr(e.target.value)}
              />
            </div>
            <Select
              showSearch
              style={{ width: "100%" }}
              className="dropdown-size123"
              onChange={handleChange}
              placeholder="ALL"
              options={LeaveStatus}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
            <Reject
              leaveId={leave}
              status={leaveStatus}
              isModalVisible={isModalVisible}
              setIsModalVisible={(value) => setIsModalVisible(value)}
              propData={reRenderFun}
            />
            {leave && (
              <Logs
                leave={leave}
                visible={visible}
                logs={logs}
                onClose={(value) => onClose(value)}
              />
            )}
          </MDBCol>
          {
            filteredData.length > 0 ?
            <Table
            className="team-leave-table "
            columns={columns}
            dataSource={
              filteredData.length > 0 && filteredData 
            }
          /> : (<p> No data found </p>)
             
          }
         
        </div>
      </div>
    </>
  );
}

export default TeamsLeave;
