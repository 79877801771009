import React, { useState } from 'react';
import { DatePicker } from 'antd';
// const {RangePicker} = DatePicker;

const PmsDatePicker = (props) => {
    return (
        <DatePicker
        className={props.className}
        onChange={props.onChange}
        onClick={props.onClick}
        disabledDate={props.disabledDate}
        disabled={props.disabled}
        style={props.style}
        defaultValue={props.defaultValue}
        >

        </DatePicker>
    );
}
export default PmsDatePicker;