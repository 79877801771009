import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Tabs,
  TimePicker,
  Row,
  Col,
  Select,
} from "antd";
import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
import AllocatedResource from "./AllocatedResource";
import Members from "./Members";
import ProjectBar from "./ProjectBar";
import RequestedResource from "./RequestedResource";
import SortingSelect from "../SortingSelect";
import moment from "moment";
import Permission from "../Permission";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AllPermissions } from "../../../redux/actions/PermissionActions";

export const AddMemberModel = ({
  id,
  setFlage,
  flage,
  visible,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [projectData, setProjectData] = useState({});
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [employees, setEmployees] = useState([]);
  const [options, setOptions] = useState([]);
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);

  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("id", id);

    //API
    AxiosInstance.post("auth/projects/detail?addmember", formData).then(
      async (res) => {
        if (res.data.status == 1) {
          setProjectData(res.data.data);
        }
      }
    );
  }, [visible, flage]);

  useEffect(() => {
    if (projectData?.Project_member_id) {
      setSelectedMemberId(projectData?.Project_member_id);
    }
  }, [projectData]);

  // employee list
  useEffect(() => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      if (res.data.status == 1) {
        setEmployees(res.data.data);

        let EmList = await res.data.data.map((item) => {
          return {
            label:
              item.First_name + " " + item.Last_name + " (" + item.Role + ")",
            value: item._id,
            id: item._id,
          };
        });
        setOptions(EmList);
      }
    });
  }, []);
  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
  };
  const onFinish = (values) => {
    if (values) {
      const formData = new URLSearchParams();

      let ProjectMemberNames = employees
        ?.filter((item) => {
          return selectedMemberId.includes(item._id);
        })
        .map((item) => {
          return item.First_name + " " + item.Last_name;
        });

      formData.append("Project_member", ProjectMemberNames);
      formData.append("Project_member_id", selectedMemberId);
      formData.append("id", id);

      AxiosInstance.post("auth/projects/edit", formData)
        .then((res) => {
          toast.success("Member Added Successfully");
          setFlage((pre) => pre + 1);
          if (res.data.status == 1) {
            const formData1 = new URLSearchParams();
            formData1.append("Project_id", id);
            formData1.append(
              "Updated_by",
              userData[0]?.First_name + " " + userData[0]?.Last_name
            );
            formData1.append(
              "Action",
              `${res.data.data[0].Project_name} Member`
            );
            AxiosInstance.post("auth/history/add", formData1).then((res) => {
              if (res.data.status == 1) {
                toast.success("Task created Successfully");
                console.log(res.data, "history data:::::");
              }
            });
          }
        })
        .catch((err) => console.log(err, "err"));
    }
    form.resetFields();
    setVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleSearch = (newValue) => {
    if (newValue) {
      let newOptions = employees
        ?.filter((item) => {
          let name =
            item.First_name + " " + item.Last_name + " (" + item.Role + ")";

          return name
            .toLocaleLowerCase()
            .includes(newValue.toLocaleLowerCase());
        })
        .map((item) => {
          return {
            label:
              item.First_name + " " + item.Last_name + " (" + item.Role + ")",
            value: item._id,
            id: item._id,
          };
        });
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [visible]);
  return (
    <>
      <Modal
        title="Add new members"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={800}
        className="add-member-body"
        bodyStyle={{ paddingTop: "20px", paddingBottom: "0px !important" }}
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={14} className="m-left">
              <Form.Item
                label="Project Member:"
                name="projectMember"
                initialValue={
                  projectData.Project_member_id &&
                  projectData.Project_member_id !== "undefined"
                    ? projectData?.Project_member_id.split(",")
                    : undefined
                }
              >
                <Select
                  mode="multiple"
                  onChange={(e) => {
                    setSelectedMemberId(e);
                  }}
                  filterOption={false}
                  onSearch={handleSearch}
                  placeholder="Please Select Project Members"
                  // options={options}
                  options={options.map((d) => ({
                    value: d.value,
                    label: d.label,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={6} className="m-left ml">
              <Form.Item>
                <Button
                  type="primary"
                  className="margin-left-5"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  Add
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
const Resource = () => {
  const params = useParams();
  const history = useHistory();
  const PID = params.id;
  const [projectDetail, setProjectDetail] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [experienceLevel, setExperienceLevel] = useState("ME");
  const [departmentLevel, setDepartmentLevel] = useState("");
  const [flage, setFlage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [toDate, setToDate] = useState("");
  const [timeHours, setTimeHours] = useState("");
  const [list, setList] = useState([]);
  const [listHtml, setListHtml] = useState([]);
  const [activeKey, setActiveKey] = React.useState("1");
  const [form] = Form.useForm();
  const [hours, setHours] = useState("");
  const [refresh, setRefresh] = useState(false);
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userdata");
    history.push("/login");
    window.location.reload();
  };

  useEffect(() => {
    setRolepermissions(permssionsState);
  }, [permssionsState]);

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select time!",
      },
    ],
  };
  const onChange = (date, dateString) => {
    let dateformat = moment(date).format("YYYY-MM-DD");
    setFromDate(dateformat);
  };

  const onChange2 = (date, dateString) => {
    console.log(date, "dateString");
    let dateformat = moment(date).format("YYYY-MM-DD");
    setToDate(dateformat);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = (errorInfo) => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { TabPane } = Tabs;
  function callback(key) {
    console.log(key);
  }
  const onKeyChange = (key) => setActiveKey(key);
  const onFinish = (values) => {
    console.log(values, 295);
    let fromDate = moment(values.Select_From_Date).format("YYYY-MM-DD");
    let ToDate = moment(values.Select_To_Date).format("YYYY-MM-DD");
    const formData = new URLSearchParams();
    formData.append(
      "Hours",
      values.No_of_Hours == undefined ? 0 : values.No_of_Hours
    );
    formData.append("Exp_level", values.Experience_Level);
    formData.append("Department_id", values.Department_Name);
    formData.append("Project_id", PID);
    formData.append("from", fromDate);
    formData.append("to", ToDate);
    AxiosInstance.post("auth/resource/add", formData).then(async (res) => {
      if (res.data.status == 1) {
        setProjectDetail(res.data.data);
        setIsModalOpen(false);
        setRefresh(true);
        form.resetFields();
      }
    });
  };
  const getDepartmentList = () => {
    AxiosInstance.get("auth/department/list", {}).then(async (res) => {
      setList(res.data.data);
      console.log(res.data.data, 123);
    });
  };
  useEffect(() => {
    getDepartmentList();
  }, []);

  useEffect(() => {
    setListHtml(
      list.map((data) => {
        return {
          key: data.Department_name,
          value: data._id,
          label: data.Department_name,
        };
      })
    );
  }, [list]);
  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("id", PID);
    AxiosInstance.post("auth/projects/detail", formData).then(async (res) => {
      if (res.data.status == 1) {
        setProjectDetail(res.data.data);
      }
    });
  }, [PID]);

  console.log(departmentLevel, 95);
  return (
    <div className="event-main">
      <ProjectBar projectId={params.id} />
      <div className="project-main-details">
        <MDBRow className="project-title-div" style={{ marginBottom: 20 }}>
          <MDBCol size="md" className="col-example project-title">
            <h3 className="tablHeader">
              {projectDetail?.Project_name} / Members
            </h3>
            <div className="resource-top-btns">
              {rolePermissions?.Project?.Resource_Request_button == "1" ? (
                <Button
                  onClick={() => {
                    showModal();
                    setActiveKey("3");
                  }}
                  className="pms-same-btn"
                  style={{ marginRight: "10px " }}
                >
                  Request Resource
                </Button>
              ) : (
                ""
              )}

              <Button
                onClick={() => {
                  setVisible(true);
                  setActiveKey("1");
                }}
                type="primary"
                className="pms-same-btn"
              >
                Update Member
              </Button>
              <Button
                onClick={() => history.goBack()}
                style={{ marginLeft: "10px" }}
              >
                <MDBIcon
                  style={{ paddingRight: "5px" }}
                  className="pr-5"
                  fas
                  icon="long-arrow-alt-left"
                />
                Back
              </Button>
            </div>
          </MDBCol>
          <MDBCard>
            <MDBCardBody className="pro-view-tab">
              <Tabs
                className="main-tab"
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={onKeyChange}
              >
                <Tabs.TabPane
                  className="sub-tab "
                  tab="Members"
                  key="1"
                  placeholder="abcd"
                  onClick={() => onKeyChange("1")}
                >
                  <Members />
                </Tabs.TabPane>
                {rolePermissions?.Project?.Allocated_Resource == "1" ? (
                  <Tabs.TabPane
                    className=""
                    tab="Allocated Resource"
                    key="2"
                    onClick={() => onKeyChange("2")}
                  >
                    <AllocatedResource />
                  </Tabs.TabPane>
                ) : (
                  ""
                )}

                {rolePermissions?.Project?.Requested_Resource == "1" ? (
                  <Tabs.TabPane
                    className=""
                    tab="Requested Resource"
                    key="3"
                    onClick={() => onKeyChange("3")}
                  >
                    <RequestedResource
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  </Tabs.TabPane>
                ) : (
                  ""
                )}
              </Tabs>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </div>
      <Modal
        title="Request Resource"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="tl-kra-modal"
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <div style={{ margin: "10px 0" }}>
            <p style={{ margin: "0" }}>Project Name</p>
            <Input
              placeholder="Enter Project Name"
              defaultValue={projectDetail?.Project_name}
              disabled
            />
          </div>
          <div style={{ margin: "10px 0" }}>
            <Form.Item
              label="Department Name"
              name="Department_Name"
              rules={[{ required: true, message: "Please select department" }]}
            >
              <Select
                showSearch
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                onChange={(e) => setDepartmentLevel(e)}
                placeholder="Please Select Role Name"
                options={listHtml}
              />
            </Form.Item>
          </div>
          <div style={{ margin: "10px 0" }}>
            <Form.Item
              label="Experience Level"
              name="Experience_Level"
              rules={[
                {
                  required: true,
                  message: "Please select experience level",
                },
              ]}
            >
              <Select
                onChange={(e) => setExperienceLevel(e)}
                placeholder="Experience Level"
                options={[
                  { label: "SDE-1", value: "SDE-1" },
                  { label: "SDE-2", value: "SDE-2" },
                  { label: "SDE-3", value: "SDE-3" },
                ]}
              />
            </Form.Item>
          </div>

          <div style={{ margin: "10px 0", display: "flex" }}>
            <Form.Item
              style={{ width: "100%", margin: "0 5px" }}
              label="Select From Date"
              name="Select_From_Date"
              rules={[
                {
                  required: true,
                  message: "Please select date",
                },
              ]}
            >
              <DatePicker placeholder="From" format={"YYYY-MM-DD"} />
            </Form.Item>
            <Form.Item
              style={{ width: "100%", margin: "0 5px" }}
              label="Select To Date"
              name="Select_To_Date"
              rules={[
                {
                  required: true,
                  message: "Please select date",
                },
              ]}
            >
              <DatePicker placeholder="To" name="To" format={"YYYY-MM-DD"} />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="No Of Hours" name="No_of_Hours">
              <input type="number" className="hours_input" />

              {/* <Form name="time-picker" label="TimePicker" {...config}>
                <TimePicker
                  placeholder="Select Time"
                  onChange={(e) => {
                    var m = moment(e);
                    var ms =
                      m.milliseconds() +
                      1000 * (m.seconds() + 60 * m.minutes());

                    setTimeHours(ms);
                  }}
                  format={"hh:mm"}
                />
              </Form> */}
            </Form.Item>
          </div>
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button
              className="pms-same-btn mb-0"
              htmlType="submit"
              style={{ marginRight: "10px" }}
            >
              Submit
            </Button>
            <Button onClick={handleCancel} className="pms-same-btn-cancel">
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
      <AddMemberModel
        id={PID}
        flage={flage}
        setFlage={setFlage}
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  );
};

export default Resource;
