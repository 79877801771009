import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { MDBIcon, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import AxiosInstance from "../../service/AxiosInstance";
import { Button, Form, Modal } from "antd";
import Details from "../../container/pages/projects/Detail";
// const { SubMenu } = Menu
import { NavLink, useLocation } from "react-router-dom";
import logo3 from "../../assets/images/logo3.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AllPermissions } from "../../redux/actions/PermissionActions";
// Delete button model
export const DeleteModel = ({ id }) => {
  const [form] = Form.useForm();
  const [list, setList] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);

  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
    setVisible1(false);
  };
  const deleteData = async () => {
    const formdata = new URLSearchParams();
    formdata.append("id", id);
    await AxiosInstance.post("auth/projects/delete", formdata).then((res) => {
      // console.log(res, 'data2');
      if (res.data.status == 1) {
        // setList((pre) => pre + 1);
        setVisible(false);
      }
    });
  };
  return (
    <>
      <Button onClick={() => setVisible1(true)} style={{ marginRight: "10%" }}>
        <MDBIcon fas icon="eye" />
      </Button>
      <Button className="btn-tresh" onClick={() => setVisible(true)}>
        <MDBIcon style={{ color: "red" }} fas icon="trash" />
      </Button>
      {/* Delete model */}
      <Modal
        title="Are you sure to delete this project ?"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={380}
        bodyStyle={{ padding: "30px", paddingLeft: "55px" }}
        footer={null}
      >
        <Button
          onClick={deleteData}
          // style={{ marginRight: '10%', marginLeft: '5%' }}
          className="delete-btn pms-same-btn"
        >
          Delete
        </Button>
        <Button onClick={handleCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>

      {/* Detail model */}
      <Modal
        className="project-detail"
        title="h"
        centered
        visible={visible1}
        onOk={() => setVisible1(false)}
        onCancel={handleCancel}
        width={1200}
        footer={null}
      >
        <Details id={id} setList={setList} />
      </Modal>
    </>
  );
};
// Action button model
export const ViewModel = ({ id }) => {
  const [form] = Form.useForm();
  const [list, setList] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
    setVisible1(false);
  };
  // const deleteData = async () => {
  //   const formdata = new URLSearchParams();
  //   formdata.append('id', id);
  //   await AxiosInstance.post('auth/projects/delete', formdata).then((res) => {
  //     // console.log(res, 'data2');
  //     if (res.data.status == 1) {
  //       // setList((pre) => pre + 1);
  //       setVisible(false);
  //     }

  //   });
  // };
  return (
    <>
      <Button onClick={() => setVisible1(true)} style={{ marginRight: "10%" }}>
        {/* <MDBIcon fas icon="eye" /> */}
        View
      </Button>
      {/* <Button className='btn-tresh' onClick={() => setVisible(true)}>
        <MDBIcon style={{color:'red'}} fas icon="trash" />
      </Button> */}
      {/* Delete model */}
      {/* <Modal
        title="Sure to delete this project?"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={300}
        bodyStyle={{ padding: '30px', paddingLeft: '55px' }}
        footer={null}
      >
        <Button
          type="danger"
          onClick={deleteData}
          style={{ marginRight: '10%', marginLeft: '5%' }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Modal> */}

      {/* Detail model */}
      <Modal
        className="project-detail"
        title="h"
        centered
        visible={visible1}
        onOk={() => setVisible1(false)}
        onCancel={handleCancel}
        width={1200}
        footer={null}
      >
        <Details id={id} setList={setList} />
      </Modal>
    </>
  );
};

//main component
const Sidebar = ({ projectId }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  // const [rolePermissions, setRolepermissions] = useState(permssionsState);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);

  const dispatch = useDispatch();
  let user_data = JSON.parse(localStorage.getItem("userdata"));
  let Code = user_data?.[0]?.Employee_code;

  const getRolePermissionList = async () => {
    const data = new URLSearchParams();
    data.append("id", userData[0]?.Role_permission_id);
    await AxiosInstance.post("auth/permission/details?fromheader", data).then(
      (res) => {
        if (res.data.status == 1) {
          setRolepermissions(res.data.data[0]);
          dispatch(AllPermissions(res.data.data[0]));
        }
      }
    );
  };
  useEffect(() => {
    getRolePermissionList();
    // data()
  }, []);
  let userData = JSON.parse(localStorage.getItem("userdata"));

  useEffect(() => {
    // getRolePermissionList();
  }, []);
  const handleClick2 = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const [list, setList] = useState(0);
  const handleClick = (e) => {
    console.log("click ", e);
  };
  const getDaysArray = function (year, month) {
    let monthIndex = month - 1;
    let names = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let date = new Date(year, monthIndex, 1);
    let result = [];

    while (date.getMonth() == monthIndex) {
      if (
        names[date.getDay()] !== "Saturday" &&
        names[date.getDay()] !== "Sunday"
      ) {
        let dateModify =
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        result.push(dateModify + "-" + names[date.getDay()]);
      }
      date.setDate(date.getDate() + 1);
    }
    return result;
  };
  let curMonth = new Date().getMonth();
  let curYear = new Date().getFullYear();
  let res = getDaysArray(curYear, curMonth + 1);
  let currDay = `${moment().format("DD")}-${moment().format("dddd")}`;
  return (
    <div className="sidebar-fixed position-fixed mt-60px">
      <a href="#!" className="logo-wrapper waves-effect logo-vh-set">
        <img alt="MDB React Logo" className="img-fluid" src={logo3} />
      </a>

      <MDBListGroup className="list-group-flush list-group-item-1">
        <NavLink exact={true} to="/" activeClassName="activeClass">
          <MDBListGroupItem
            className={location.pathname == "/dashboard" ? "active" : ""}
            onClick={handleClick2}
          >
            <MDBIcon icon="home" className="mr-3 menu-icon" />
            Dashboard
          </MDBListGroupItem>
        </NavLink>
        <NavLink to="/projects" activeClassName="activeClass">
          <MDBListGroupItem
            className={location.pathname == "/projects" ? "active" : ""}
          >
            <MDBIcon icon="briefcase" className="mr-3 menu-icon " />
            Project
          </MDBListGroupItem>
        </NavLink>
        {/* <NavLink to="/taskList" activeClassName="activeClass">
          <MDBListGroupItem>
            <MDBIcon icon="tasks" className="mr-3 menu-icon " />
            Task
          </MDBListGroupItem>
        </NavLink> */}
        <NavLink to="/me" activeClassName="activeClass">
          <MDBListGroupItem
            className={location.pathname == "/me" ? "active" : ""}
          >
            <MDBIcon icon="user" className="mr-3 menu-icon " />
            Me
          </MDBListGroupItem>
        </NavLink>
        <NavLink to="/directory" activeClassName="activeClass">
          <MDBListGroupItem
            className={location.pathname == "/directory" ? "active" : ""}
          >
            <MDBIcon icon="address-book" className="mr-3 menu-icon " />
            Directory
          </MDBListGroupItem>
        </NavLink>
        {/* <NavLink to="/leaves" activeClassName="activeClass">
          <MDBListGroupItem>
            <MDBIcon icon="user" className="mr-3 menu-icon " />
            Leave
          </MDBListGroupItem>
        </NavLink>{" "} */}
        <NavLink to="/tickets" activeClassName="activeClass">
          <MDBListGroupItem
            className={location.pathname == "/tickets" ? "active" : ""}
          >
            <MDBIcon icon="ticket-alt" className="mr-3 menu-icon " />
            Tickets
          </MDBListGroupItem>
        </NavLink>
        <NavLink to="/vh-policy" activeClassName="activeClass">
          <MDBListGroupItem
            className={location.pathname == "/vh-policy" ? "active" : ""}
          >
            <MDBIcon icon="address-book" className="mr-3 menu-icon " />
          VH Policy
          </MDBListGroupItem>
        </NavLink>
        {/* <NavLink to="/interviewform" activeClassName="activeClass">
          <MDBListGroupItem>
            <FormOutlined className="mr-3 menu-icon " />
            Interviews
          </MDBListGroupItem>
        </NavLink> */}
        {rolePermissions?.KRA?.Employee_KRA == "1" ? (
          <>
            {res.slice(0, 3).includes(currDay) ? (
              // {/* // (moment().format("dddd") !== "Saturday" || moment().format("dddd") !== "Sunday") &&
              //   // (moment().format("DD") == "" && moment().format("dddd") !== "Friday") ? (
              //   // moment().startOf("week") !== 0 &&
              //   // moment().endOf("week") !== 6 */}
              <>
                <NavLink to="/KRA" activeClassName="activeClass">
                  <MDBListGroupItem
                    className={location.pathname == "/KRA" ? "active" : ""}
                  >
                    <MDBIcon icon="file-alt" className="mr-3 menu-icon " />
                    KRA
                  </MDBListGroupItem>
                </NavLink>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}{" "}
        {rolePermissions?.KRA?.TL_HOD_KRA == "1" ? (
          <>
            {res.slice(0, 5).includes(currDay) ? (
              <NavLink to="/TLKRA" activeClassName="activeClass">
                <MDBListGroupItem>
                  <MDBIcon icon="file-alt" className="mr-3 menu-icon " />
                  TL/HOD KRA
                </MDBListGroupItem>
              </NavLink>
            ) : (
              ""
            )}{" "}
          </>
        ) : (
          ""
        )}
    
      </MDBListGroup>
    </div>
  );
};

export default Sidebar;
