import React from "react";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../service/AxiosInstance";
import { MDBDataTableV5 } from "mdbreact";
import ReactLoader from "../../../components/common/ReactLoader";

const VacantEmployee = () => {
  const [vacantData, setVacantData] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [loading, setLoading] = useState(false);

  const getAllVacantEmployeeList = () => {
    setLoading(true);
    AxiosInstance.get("/auth/resource/listEmployeeWithNoTask").then((res) => {
      if (res.data.status === 1) {
        setLoading(false);
        setVacantData(res.data.data);
      }
    });
  };

  useEffect(() => {
    getAllVacantEmployeeList();
  }, []);

  useEffect(() => {
    let rows = vacantData.map((item, index) => {
      return {
        No: index + 1,
        Department: item?.Role,
        Name:
          item?.First_name + " " + item?.Middle_name + " " + item?.Last_name,
        Email: item?.Email,
      };
    });
    setDatatable({
      columns: [
        {
          label: "No",
          field: "No",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Department",
          field: "Department",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Name",
          field: "Name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Email",
          field: "Email",
          width: 150,
          sort: "disabled",
        },
      ],
      rows: rows,
    });
  }, [vacantData]);
  return (
    <>
      <div className="left-head-title">Vacant Employee</div>

      {loading ? (
        <ReactLoader loading={loading} />
      ) : (
        <div className="tasklisttbl">
          <MDBDataTableV5
            hover
            entriesOptions={[10, 20, 25]}
            entries={10}
            pagesAmount={4}
            data={datatable}
            searchTop
            searchBottom={false}
          />
        </div>
      )}
    </>
  );
};

export default VacantEmployee;
