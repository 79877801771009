import React from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { Loading } from './Loader'

const ReactLoader = (props) => {
  return (
    <LoadingOverlay
    active={props.loading}
    spinner={<Loading />}
    styles={{
      overlay: (base) => ({
        ...base,
        background: '#fff',
        width: '100%',
        
      })
    }}
    >

  </LoadingOverlay>
  )
}

export default ReactLoader