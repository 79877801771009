import moment from "moment";
import { useSelector } from "react-redux";
import { element } from "prop-types";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AxiosInstance from "../../../../service/AxiosInstance";
import { Select } from "antd";
import { Spin } from "antd";
const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";
const ProTaskUpdate = ({ loading, projectIds, setLoading ,sortForDues}) => {
  const { Option } = Select;
  const statusss = useSelector((state) => state.StatusReducer);
  let PrevstatusBg;
  let CurrentstatusBg;
  const Currentcolorstatus = (color) => {
    CurrentstatusBg = "shadow-0";
    if (color == "IN PROGRESS") {
      CurrentstatusBg = "danger-info";
    } else if (color == "DEVELOPMENT DONE") {
      CurrentstatusBg = "danger-info";
    } else if (color == "REJECTED") {
      CurrentstatusBg = "shadow-0";
    } else if (color == "ON HOLD") {
      CurrentstatusBg = "shadow-0";
    } else if (color == "READY FOR QA") {
      CurrentstatusBg = "danger-info";
    } else if (color == "REOPEN") {
      CurrentstatusBg = "shadow-0";
    } else if (color == "READY FOR UAT") {
      CurrentstatusBg = "success-info";
    } else if (color == "CLOSE") {
      CurrentstatusBg = "success-info";
    }
  };

  const Prevcolorstatus = (color) => {
    PrevstatusBg = "shadow-0";
    if (color == "IN PROGRESS") {
      PrevstatusBg = "danger-info";
    } else if (color == "DEVELOPMENT DONE") {
      PrevstatusBg = "danger-info";
    } else if (color == "REJECTED") {
      PrevstatusBg = "shadow-0";
    } else if (color == "ON HOLD") {
      PrevstatusBg = "shadow-0";
    } else if (color == "READY FOR QA") {
      PrevstatusBg = "danger-info";
    } else if (color == "REOPEN") {
      PrevstatusBg = "shadow-0";
    } else if (color == "READY FOR UAT") {
      PrevstatusBg = "success-info";
    } else if (color == "CLOSE") {
      PrevstatusBg = "success-info";
    }
  };

  const [history, setHistory] = useState([]);
  // const [loading, setLoading] = useState(false)
  const [projectName, setProjectName] = useState([]);
  const [statusSort, setStatusSort] = useState("");

  const GetAllTaskUpdate = () => {
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("id", JSON.stringify(projectIds));
    formData.append("type", sortForDues);
    AxiosInstance.post("/auth/history/project-activites", formData).then(
      (res) => {
        setLoading(false);
        let sortedList = res.data.data.sort(
          (a, b) => moment(a.Time) - moment(b.Time)
        );

        let index = -1;
        let list1 = sortedList.map((item) => {
          return item.History.map((item2) => {
            index++;
            return {
              project_id: item?.Project_id?._id,
              icon: index,
              name: item?.Name,
              Project_name: item?.Project_id?.Project_name,
              Task_id: item.Task_id?._id,
              Task_name: item?.Task_id?.Task_key,
              Current_Status: item2?.Current_Status,
              Previous_Status: (item2?.Privious_Status||item2?.Previous_Status),
              Current_Assignees: item2?.Current_Assignees,
              Previous_Assignees: (item2?.Privious_Assignees || item2?.Previous_Assignees),
              Comments: item2?.Comments,
              Task_create: item2?.Task_create,
              Created_by: item2?.Created_by,
              Updated_by: item2?.Updated_by,
              Time: item2?.Time,
              MilestoneName: item?.Milestone_id?.Name,
              User_id: item2?.User_id,
            };
          });
        });
        let unique = sortedList.map((item) => item?.Project_id);
        // unique.map((item) => console.log([item["_id"], item],"item"))
        // let uniqueArray = [
        //   ...new Map(unique.map((item) => [item["_id"], item])).values(),
        // ];
        let uniqueArray = [
            ...new Map(
              unique
                .filter(item => item && item["_id"] !== null && item["_id"] !== undefined)
                .map(item => [item["_id"] || "fallbackKey", item])
            ).values(),
          ];
        setProjectName(uniqueArray);
        setHistory(list1.flat());
      }
    );
  };

  useEffect(() => {
    GetAllTaskUpdate();
    // getId()
  }, [statusss, projectIds,sortForDues]);

  return (
    <>
      <div className="dash-col-milestone">
        <div className="main-milestone pm-pro-task-update">
          <div className="milstone-flex pro-task-head">
            <div className="milestone-card-head ">
              <p>Project Activity</p>
              <Spin spinning={loading} style={{ marginTop: 100 }}>
                {" "}
              </Spin>
            </div>
            <div className="flex-project-activity">
              <div className="project-title-name ">
                <h6>Select Project :</h6>
              </div>
              <div className="milestone-dropdown ">
                <Select
                  defaultValue={""}
                  className="select-dropdown leave-4"
                  onChange={(e) => {
                    setStatusSort(e);
                  }}
                >
                  <Option value="">All</Option>
                  {projectName.length > 0 &&
                    projectName
                      .sort((a, b) =>
                        a.Project_name.toLowerCase() <
                        b.Project_name.toLowerCase()
                          ? -1
                          : 1
                      )
                      .map((item) => (
                        <Option value={`${item?._id}`}>
                          {item?.Project_name}
                        </Option>
                      ))}
                  {/* <Option value="OPEN">OPEN</Option>
                  <Option value="IN PROGRESS">IN PROGRESS</Option>
                  <Option value="DEVELOPMENT DONE">DEVELOPMENT DONE</Option>
                  <Option value="READY FOR QA">READY FOR QA</Option>
                  <Option value="ON HOLD">ON HOLD</Option> */}
                </Select>
              </div>
            </div>
          </div>

          <div className="pm-pro-task-card-body">
            {history &&
              (statusSort.length > 0
                ? history.filter((item) => item.project_id === statusSort)
                : history
              )
                .sort((a, b) => moment(b.Time) - moment(a.Time))
                .map((item) => {
                  Currentcolorstatus(item.Current_Status);
                  Prevcolorstatus(item.Previous_Status);
                  return (
                    <Link
                      className="pm-pro-task-body-content"
                      to={`/project/view/${item?.project_id}`}
                      target="_blank"
                    >
                      <div className="add-cmt">
                        <img
                          src={
                            item?.User_id && item?.User_id?.Profile_pic
                              ? imageUrl + item?.User_id?.Profile_pic
                              : "https://bootdey.com/img/Content/avatar/avatar1.png"
                          }
                          alt="profile"
                          style={{
                            width: "35px",
                            height: "35px",
                            objectFit: "contain",
                            borderRadius: "50px",
                            marginRight: "10px",
                          }}
                        />

                        <div style={{ flex: "1" }}>
                          <div>
                            <div className="user-name-detils history-name project-direction">
                              <p>
                                <b>
                                  {!item.Created_by? item?.User_id?.First_name +" "+ item?.User_id?.Last_name : item?.Created_by
                                    ? item.Created_by?.First_name +" "+ item.Created_by?.Last_name
                                    : item.Updated_by}
                                </b>
                              </p>
                              <p>
                                {" "}
                                {item?.Task_create
                                  ? ` Created Task ${item?.Task_name} in ${
                                      item?.Project_name
                                    } On ${moment(item?.Task_create).format(
                                      "DD MMM YYYY"
                                    )}  At ${moment(item?.Task_create).format(
                                      "hh:mm A"
                                    )}`
                                  : item?.Current_Status
                                  ? `Changed the Status for ${
                                      item?.Project_name
                                    } in ${item?.MilestoneName} on ${moment(
                                      item.Time
                                    ).format("DD MMM YYYY")} at ${moment(
                                      item.Time
                                    ).format("hh:mm A")}`
                                  : item?.Current_Assignees
                                  ? `Changed the Assignee For ${
                                      item?.Project_name
                                    } On ${moment(item.Time).format(
                                      "DD MMM YYYY"
                                    )} At ${moment(item.Time).format(
                                      "hh:mm A"
                                    )}`
                                  : item.Comments
                                  ? `has commented in ${
                                      item?.Project_name
                                    } on ${moment(item.Time).format(
                                      "DD MMM YYYY"
                                    )} At ${moment(item.Time).format(
                                      "hh:mm A"
                                    )}`
                                  : item.MilestoneName
                                  ? `Created Milestone on ${moment(
                                      item.Time
                                    ).format("DD MMM YYYY")} at ${moment(
                                      item.Time
                                    ).format("hh:mm A")}`
                                  : ""}
                              </p>
                            </div>
                            <div className="status-data pm-pro-task-status-data">
                              {item?.Comments ? (
                                item?.Comments
                              ) : item?.Previous_Assignees ? (
                                <>
                                  {item?.Previous_Assignees} &nbsp;{" "}
                                  <i class="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                                  &nbsp; <div>{item?.Current_Assignees}</div>
                                </>
                              ) : item.Current_Status ? (
                                <>
                                  <div className={PrevstatusBg}>
                                    {item?.Previous_Status}
                                  </div>{" "}
                                  &nbsp;
                                  <i class="fas fa-long-arrow-alt-right taskarrowmini"></i>{" "}
                                  &nbsp;
                                  <div className={CurrentstatusBg}>
                                    {item?.Current_Status}
                                  </div>
                                </>
                              ) : item.MilestoneName ? (
                                item.MilestoneName
                              ) :(
                                <div className={PrevstatusBg}>
                                  {item?.Previous_Status
                                    ? item?.Previous_Status
                                    : "OPEN"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProTaskUpdate;
