import { combineReducers } from "redux";
import AttendanceReducer from "./AttendanceReducer";
import PermssionsReducer from "./PermssionsReducer";
import TaskReducer from "./TaskReducer";
import StatusReducer from "./ChangeTaskReducer";

export default combineReducers({
  Permissions: PermssionsReducer,
  Task: TaskReducer,
  Attendance: AttendanceReducer,
  StatusReducer:StatusReducer
});
