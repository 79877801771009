import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Image,
  DatePicker,
  Typography,
  message,
} from "antd";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";
import { MDBIcon } from "mdb-react-ui-kit";
import {
  UploadOutlined,
  DeleteOutlined,
  CodeSandboxCircleFilled,
  DeleteFilled,
  FileExcelTwoTone,
  FilePdfTwoTone,
  FileWordTwoTone,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AxiosInstance from "../../../service/AxiosInstance";
import { useSelector } from "react-redux";
import { Box, Button as Buttonss, IconButton } from "@mui/material";
const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";
const Edit = ({ taskData, setCount, setVisible, visible }) => {
  //getting perticular project tasks list

  console.log(taskData.Document, "taskData.Document");
  const params = useParams();
  const [tasksList, setTasksList] = useState([]);
  const [sprintdata, setSprintdata] = useState([]);
  const [assignDate, setAssignDate] = useState();

  const [form] = Form.useForm();
  // const [projectDetail, setProjectDetail] = useState();
  const [taskDatae, settaskDatae] = useState(taskData);
  const [textEditer, setTextEditer] = useState(taskData?.Description);
  const [projectDetail, setProjectDetail] = useState();
  const [count2, setCount2] = useState(1);
  const [file, setFile] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [textEditerCount, setTextEditerCount] = useState(0);
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const [reporterList, setReporterList] = useState([]);
  const [assigneesList, setAssigneesList] = useState([]);
  // const [selectedMemberID, setSelectedMemberID] = useState('');
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [url, setUrl] = useState([]);
  const fileHandler = (e) => {
    console.log(Object.values(e), 51);
    Object.values(e).map((file) => {
      setFile((prev) => [...prev, file]);
      setUrl((prev) => [...prev, file.name]);
    });
  };
  const [urlArray, setUrlArray] = useState([]);
  const [olddocument, Setolddocument] = useState([""]);
  const permssionsState = useSelector((state) => state.Permissions.Permissions);

  const [rolePermissions, setRolepermissions] = useState(permssionsState);

  let userdata = JSON.parse(localStorage.getItem("userdata"));

  let filterUrl;

  let config = {
    toolbarGroups: [
      // { name: "document", groups: ["mode", "document", "doctools"] },

      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      { name: "links", groups: ["link"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      { name: "styles", groups: ["Styles", "Format", "Font", "FontSize"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true,
    scayt_autoStartup: true,
  };
  useEffect(() => {
    if (taskDatae?.Project_id) {
      AxiosInstance.get(`auth/projecttasks/list/${taskDatae.Project_id}`).then(
        (res) => {
          setTasksList(res.data.data);
        }
      );
    }
  }, [taskDatae]);

  const getSprintData = () => {
    if (taskDatae?.Project_id) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("Project_id", taskDatae?.Project_id);
      AxiosInstance.post("sprint/getAllsprint", urlencoded).then((res) => {
        setSprintdata(res.data.data);
      });
    }
  };

  useEffect(() => {
    getSprintData();
  }, [params?.projectid, taskDatae]);
  // function chackDescription() {
  //   if (textEditer === '') setDescriptionAlert('Please Enter Description');
  //   if (textEditer !== '') setDescriptionAlert('');
  // }

  // useEffect(() => {
  //   if (visible === true) {
  //     if (taskData.Upload_file != '' && taskData.Upload_file != undefined) {
  //       setUrlArray(taskData.Upload_file);
  //     }
  //   }
  //   // setTextEditerCount(0);
  // }, [visible]);

  useEffect(() => {
    Setolddocument(taskData.Document);
  }, [taskData]);
  useEffect(() => {
    settaskDatae(taskData);
    setAssignDate(
      moment(
        taskData.Assigned_date2
          ? JSON.stringify(new Date(taskData.Assigned_date)).slice(1, 25)
          : ""
      )
    );
    form.resetFields();
    // setTextEditer("");
    setUrlArray([]);
    setTextEditerCount(0);
  }, [visible, taskData]);
  // useEffect(()=>{
  //   // setTextEditer('');
  //   form.resetFields();
  // },[taskDatae])
  useEffect(() => {
    if (taskDatae?.Project_id) {
      const formData = new URLSearchParams();
      if (taskDatae.Project_id && taskDatae.Project_id !== "") {
        formData.append("id", taskDatae.Project_id);
        AxiosInstance.post("auth/projects/detail", formData).then((res) => {
          if (res.data.data) {
            form.resetFields();
            setProjectDetail(res.data.data);
          }
        });
      }
    }
  }, [taskDatae, count2]);

  // project data List
  useEffect(() => {
    if (projectDetail && taskDatae?.Project_id) {
      // let projectMember = projectDetail?.Project_member
      //   ? projectDetail?.Project_member.split(',')
      //   : '';
      //Employee
      AxiosInstance.get(
        `auth/projectemployeelist/${taskDatae?.Project_id}`
      ).then(async (res) => {
        if (res.data.status == 1) {
          let EmList = res.data.data.map((member) => {
            // let EmID1 = res.data.data.filter((item) => item.First_name +' '+ item.Last_name == member);
            return {
              label: member.First_name + " " + member.Last_name,
              value: member.First_name + " " + member.Last_name,
              // value: EmID1[0]?._id,
            };
          });
          // setList(EmList);
          setAssigneesList(EmList);
        }
      });
      let projectMan = projectDetail.Project_manager.split(",");
      setReporterList(projectMan);
    }
  }, [projectDetail]);

  // employee list
  useEffect(() => {
    if (taskDatae?.Project_id) {
      AxiosInstance.get(
        `auth/projectemployeelist/${taskDatae?.Project_id}`
      ).then(async (res) => {
        if (res.data.status == 1) {
          let EmID = await res.data.data.filter(
            (item) => item.First_name + " " + item.Last_name == selectedMember
          );
          // setSelectedMemberID(EmID[0]?._id)
          setSelectedMemberId(EmID[0]?._id);
        }
      });
    }
  }, [selectedMember, visible]);
  //submit

  // function ImageDemo({ element, index }) {
  //   // let linkarray = element.split('.');
  //   let linkarray =
  //     element !== null && element !== undefined ? element?.split(".") : [];
  //   let exe = "jpg,jpeg,png";
  //   return (
  //     <>
  //       <div className="upload-pdf-main">
  //           <Image
  //             width={80}
  //             height={80}
  //             src={
  //               exe.includes(linkarray[linkarray.length - 1])
  //                 ? imageUrl+element
  //                 : "https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-76-512.png"
  //             }
  //           />
  //           <DeleteOutlined onClick={() => deleteimage(index)} />
  //       </div>
  //     </>
  //   );
  // }

  //New
  function ImageDemo({ element, index }) {
    // let linkarray = element.split('.');
    let linkarray =
      element !== null && element !== undefined ? element?.split(".") : [];
    let exe = "jpg,jpeg,png";
    let csvExtension = "xlsx,csv";
    let pdfExtension = "pdf";
    return (
      <>
        <a href={imageUrl + element}>
          <div className="upload-pdf-main">
            {exe.includes(linkarray[linkarray.length - 1]) ? (
              <Image width={80} height={80} src={imageUrl + element} />
            ) : csvExtension.includes(linkarray[linkarray.length - 1]) ? (
              <FileExcelTwoTone style={{ fontSize: "80px" }} />
            ) : pdfExtension.includes(linkarray[linkarray.length - 1]) ? (
              <FilePdfTwoTone style={{ fontSize: "80px" }} />
            ) : (
              <FileWordTwoTone style={{ fontSize: "80px" }} />
            )}
          </div>
        </a>
        <DeleteOutlined onClick={() => deleteimage(index)} />
      </>
    );
  }

  // const deleteimage = (index) => {
  //   let newarray = [...olddocument];
  //   newarray.splice(index, 1);
  // };

  //new
  const deleteimage = (rowIndex) => {
    Setolddocument((olddocument) =>
      olddocument.filter((item, index) => index != rowIndex)
    );
  };

  const deleteFilled = (rowIndex) => {
    setUrl((oldState) => oldState.filter((item, index) => index != rowIndex));
  };

  const checkPreviousAndCurrentValue = (prevVal, currVal) => {
    if (prevVal == currVal) {
      return true;
    } else {
      return false;
    }
  };

  const onFinish = (values) => {
    let previousValue = taskDatae;
    let currentValue = values;

    // alert('onfinish')
    let taskKey = `${values.projectShortName}`;
    let sptId;
    let sptName;
    if (values?.sprint == taskDatae?.Sprint_id?.Name) {
      sptName = taskDatae?.Sprint_id?.Name;
      sptId = taskDatae?.Sprint_id?._id;
    } else {
      sptName = JSON.parse(values.sprint)?.Name;
      sptId = JSON.parse(values.sprint)?._id;
    }
    if (values) {
      const formData = new FormData();
      formData.append("Issue_type", values.IssueType);
      if (sptId) formData.append("Sprint_id", sptId ? sptId : "-");
      formData.append("Short_summary", values.shortSummary);
      formData.append("Task_duration", values.taskDuration);
      formData.append(
        "Description",
        textEditer ? textEditer : taskDatae.Description
      );
      for (let i = 0; i < olddocument?.length; i++) {
        formData.append("olddocument", olddocument[i]);
      }
      formData.append("Reporter", values.reporter ? values.reporter : "-");

      let fileData = file ? [...file] : "";
      if (fileData.length) {
        fileData.map((ele) => {
          formData.append("image", ele);
        });
      }

      formData.append(
        "Assigned_date",
        values.assignedDate && values.assignedDate !== null
          ? values.assignedDate
          : "-"
      );
      //new
      formData.append(
        "Assigned_date2",
        values.assignedDate && values.assignedDate !== null
          ? values.assignedDate
          : "-"
      );
      formData.append(
        "Due_date",
        values.dueDate && values.dueDate !== null ? values.dueDate : "-"
      );
      formData.append("Assignees", values.assignees);
      // formData.append(
      //   'Assignees',
      //   selectedMemberId && selectedMemberId !== ''
      //     ? selectedMemberId
      //     : taskData.Assignees
      // );
      formData.append("Priority", values.priority);
      formData.append("Project_short_name", values.projectShortName);
      // formData.append('Status', values.status);
      // formData.append('Employee_id', values.assignees);
      formData.append(
        "Employee_id",
        selectedMemberId && selectedMemberId !== ""
          ? selectedMemberId
          : taskDatae.Employee_id
      );
      // formData.append('Task_duration', values.taskDuration);

      formData.append("Linkissue_Taskkey", values.linkissue);

      formData.append("id", taskDatae._id);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      AxiosInstance.post("auth/tasks/edit-details", formData, config)
        .then(async (res) => {
          if (res.data.status == 1) {
            toast.success("Task updated Successfully");
            //  await setProjectDetail(res.data.data);
            // setTextEditer("");
            setCount((pre) => pre + 1);
            setCount2((pre) => pre + 1);
            setVisible(false);
            // taskData=res.data.data
            settaskDatae(res.data.data);
            setTextEditerCount(0);
            form.resetFields();
            setVisible(false);
            form.resetFields();
            // create history
            const formData1 = new URLSearchParams();
            formData1.append("Task_id", taskDatae?._id);
            formData1.append("User_id", userData[0]._id);
            if (
              !checkPreviousAndCurrentValue(
                previousValue?.Assignees,
                currentValue?.assignees
              )
            ) {
              formData1.append("Previous_Assignees", previousValue?.Assignees);
              formData1.append("Current_Assignees", currentValue?.assignees);
            }
            if (
              !checkPreviousAndCurrentValue(
                previousValue?.Short_summary,
                currentValue?.shortSummary
              )
            ) {
              formData1.append(
                "Previous_Short_summary",
                previousValue?.Short_summary
              );
              formData1.append(
                "Current_Short_summary",
                currentValue?.shortSummary
              );
            }
            if (
              !checkPreviousAndCurrentValue(
                previousValue?.Description,
                textEditer
              )
            ) {
              formData1.append(
                "Previous_Description",
                previousValue?.Description
              );
              formData1.append("Current_Description", textEditer);
            }
            if (
              !checkPreviousAndCurrentValue(
                previousValue?.Priority,
                currentValue?.priority
              )
            ) {
              formData1.append("Previous_Priority", previousValue?.Priority);
              formData1.append("Current_Priority", currentValue?.priority);
            }
            if (
              !checkPreviousAndCurrentValue(
                moment(previousValue?.Assigned_date2).format("YYYY-MM-DD"),
                moment(currentValue?.assignedDate).format("YYYY-MM-DD")
              )
            ) {
              formData1.append(
                "Previous_Assign_date",
                previousValue?.Assigned_date2
              );
              formData1.append(
                "Current_Assign_date",
                currentValue?.assignedDate
              );
            }
            if (
              !checkPreviousAndCurrentValue(
                moment(previousValue?.Due_date).format("YYYY-MM-DD"),
                moment(currentValue?.dueDate).format("YYYY-MM-DD")
              )
            ) {
              formData1.append("Previous_Due_date", previousValue?.Due_date);
              formData1.append("Current_Due_date", currentValue?.dueDate);
            }
            if (
              !checkPreviousAndCurrentValue(
                previousValue?.Task_duration,
                currentValue?.taskDuration
              )
            ) {
              formData1.append(
                "Previous_Task_duration",
                previousValue?.Task_duration
              );
              formData1.append(
                "Current_Task_duration",
                currentValue?.taskDuration
              );
            }
            formData1.append(
              "Updated_by",
              userData[0]?.First_name + " " + userData[0]?.Last_name
            );
            AxiosInstance.post("auth/history/add", formData1).then((res) => {
              if (res.data.status == 1) {
              }
            });
          }
        })
        .catch((err) => console.log(err, "err"));
      // setTextEditer('');
      setTextEditerCount(0);
      setUrlArray("");
    }
  };

  const onFinishFailed = (errorInfo) => {
    // setTextEditer('');
  };

  const handleCancel = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // allFileDelete();
    form.resetFields();
    setVisible(false);
    setTextEditer("");
    setUrlArray([]);
    setTextEditerCount(0);
  };

  const onDescriptionChange = (evt) => {
    // setTextEditer(evt.editor.getData());
    setTextEditerCount(() => textEditerCount + 1);
  };
  console.log(textEditer, "textEditer");
  const content = () => {
    return textEditer
      ? textEditer
      : textEditerCount === 0
      ? taskDatae?.Description
      : "";
  };
  // upload file props
  const props = {
    className: "upload-list-inline",
    listType: "picture",
    maxCount: "50",
    multiple: "multiple",
    // openFileDialogOnClick: 'false',
    // defaultFileList: taskDatae?.Upload_file,

    action: "demo_image",
    // action: { url },

    //   onRemove(file) {
    //     if (file) {
    //       if (!file.url) {
    //         console.log(file, '=file');
    //         console.log(urlArray, 'urlArray onRemove');

    //         const myPromise = new Promise((resolve) => {
    //           resolve(urlArray.filter((item) => file.uid === item.uid));
    //         });

    //         myPromise.then((deleteFile) => {
    //           if (deleteFile[0].url != undefined)
    //             console.log(deleteFile[0].url, 'deleteFile[0].url res promiss');
    //           filterUrl = urlArray;
    //           setUrlArray(filterUrl.filter((item) => file.uid !== item.uid));
    //           const formData = new URLSearchParams();
    //           formData.append('image', deleteFile[0].url);

    //           AxiosInstance.post('auth/tasks/deleteImage', formData).then((result) => {
    //             console.log('delete result are', result.data);
    //           });
    //         });
    //       } else {
    //         // alert('delete');
    //         console.log(file, 'fileremove');
    //         const myPromise = new Promise((resolve) => {
    //           resolve(urlArray.filter((item) => file.uid === item.uid));
    //         });

    //         myPromise.then((deleteFile) => {
    //           // console.log(deleteFile[0].url, 'res promiss url');
    //           let removeUrl = urlArray;
    //           setUrlArray(removeUrl.filter((item) => file.uid !== item.uid));
    //           const formData = new URLSearchParams();
    //           formData.append('image', deleteFile[0].url);

    //           AxiosInstance.post('auth/tasks/deleteImage', formData).then((result) => {
    //             console.log('delete result are', result.data);
    //           });
    //         });
    //       }
    //     }
    //   },
    //   onChange({ file, fileList }) {
    //     // console.log(file, '=filedone');
    // console.log(taskData.Upload_file,'taskData.Upload_file in edite outonchange start ');

    // setCount((pre) => pre + 1);

    //     if (file.status == 'done') {
    //       console.log(fileList, '=fileList ');
    //       const formData = new FormData();
    //       formData.append('demo_image', file.originFileObj);
    //       const config = {
    //         headers: {
    //           'content-type': 'multipart/form-data',
    //         },
    //       };

    //       AxiosInstance.post('upload/uplodeimage', formData, config).then((result) => {
    //         console.log(' upload/uplodeimage result are', result.data);
    //         if (result.data.url && result.data.url !== '') {
    //           console.log(urlArray, 'urlArrayonChange');
    //           let data = urlArray;
    //           console.log(data,'data');
    //           data?.push({
    //             url: result.data.url,
    //             uid: file.uid,
    //             name: file.name,
    //           });
    //           setUrlArray(data);

    //           // alert('url')

    //           // console.log(url, 'Url');
    //           console.log(result.data.url, 'onChange result.data.url');
    // console.log(taskData.Upload_file,'taskData.Upload_file in edite outonchange end ');

    //           //       // fileList.thumbUrl = url;
    //         }
    //       });
    //     }
    //   },
  };
  // console.log(urlArray, 'urlArray');
  // console.log(taskData.Upload_file,'taskData.Upload_file in edite out');

  //#region all uploaded file delete fun
  // const allFileDelete = () => {
  //   console.log(urlArray.length >= 1, 'urlArray.length >= 1');
  //   console.log(urlArray.length, 'urlArray.length');

  //   const fileDeleteApi = (data) => {
  //     data.map((file) => {
  //       const formData = new URLSearchParams();
  //       console.log(file.url, 'url');
  //       formData.append('image', file.url);

  //       AxiosInstance.post('auth/tasks/deleteImage', formData).then((result) => {
  //         console.log( 'all file delete result are', result.data);
  //       });
  //     });
  //   };

  //   if (urlArray.length >= 1) {

  //     console.log(urlArray, 'urlArray allFileDelete');
  //     if (taskData.Upload_file.length >= 1) {
  //       console.log(taskData.Upload_file,'taskData.Upload_file in promess all file delete');

  //       // alert('taskData.Upload_file available');
  //       new Promise((resolve) => {
  //         resolve(
  //         //  urlArray.filter((item) => taskData.Upload_file.filter((file)=>item.uid==file.uid))
  //         urlArray.filter((item) => {
  //           return taskData.Upload_file.filter((file)=>{ console.log(file,item,'file,item'); return item.uid!==file.uid })

  //            })
  //           // urlArray.map((file) => {
  //           //   let allUrlRemoveFile = [];

  //           //     console.log(
  //           //       taskData.Upload_file,
  //           //       'taskData.Upload_file in resolve'
  //           //     );
  //           //   let url = taskData.Upload_file.filter((item, index) => {
  //           //       return item.url==
  //           //     });

  //           // })

  //         );
  //       }).then((newFileUrl) => {
  //         console.log(newFileUrl, 'newFileUrl');
  //         // if (newFileUrl.length >= 1) {
  //         //   console.log(true);
  //         // }
  //       });
  //     } else {
  //       fileDeleteApi(urlArray);
  //     }
  //   }

  //     //   new Promise (resolve=> { resolve(urlArray.filter((item,index)=>{  return !item.url}))}).then((newFileUrl)=>{console.log(newFileUrl,'newFileUrl');
  //     //       if(newFileUrl.length >= 1){console.log(true);}
  //     // })
  //     //   new Promise (resolve=> { resolve(urlArray.map((url)=>{
  //     //        let allUrlRemove = [];
  //     //       if (taskData.Upload_file.length>=1){
  //     //          console.log(taskData.Upload_file,'taskData.Upload_file in resolve');

  //     //         urlArray.filter((item,index)=>{  return !item.url})
  //     //       }else{

  //     //       }
  //     //   }))}).then((newFileUrl)=>{console.log(newFileUrl,'newFileUrl');
  //     //       if(newFileUrl.length >= 1){console.log(true);}
  //     // })
  //     // alert('allfiledelete');

  //   //     new Promise((resolve) => {
  //   //       if(taskData.Upload_file=''){
  //   //         alert('g')
  //   //       }
  //   //   resolve(taskData.Upload_file?.filter((item) => (!file.url&&file.uid === item.uid)));
  //   // }).then((i)=>{console.log(i,'iiiiii');})

  //   // let allUrlRemove =
  //   // if (urlArray[0]) {
  //   //   urlArray?.map((item) => {
  //   //     console.log(item.url, 'item.urlitem.url');
  //   //     const formData = new URLSearchParams();
  //   //     formData.append('image', item.url);

  //   //     AxiosInstance.post('auth/tasks/deleteImage', formData).then((result) => {
  //   //       console.log('delete result are', result.data);
  //   //       //   // changeflag.setflag(!changeflag.flag);
  //   //     });
  //   //   });
  //   // }
  // };
  // #endregion

  return (
    <>
      {/* <Button type="primary" onClick={() => setVisible(true)}>
        <MDBIcon fas icon="edit" />
      </Button> */}

      <Modal
        title="Edit Task"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        maskClosable={false}
        width={800}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
        className="edit-task-modal"
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            remember: true,
            assignedDate:
              taskDatae?.Assigned_date !== "-"
                ? moment(
                    taskDatae?.Assigned_date
                      ? JSON.stringify(
                          new Date(taskDatae?.Assigned_date)
                        ).slice(1, 25)
                      : ""
                  )
                : "",
            dueDate:
              taskDatae.Due_date !== "-"
                ? moment(
                    taskDatae?.Due_date
                      ? JSON.stringify(new Date(taskDatae?.Due_date)).slice(
                          1,
                          25
                        )
                      : ""
                  )
                : "",
          }}
        >
          <Row>
            <Col span={24} style={{ paddingRight: "10px" }}>
              <Form.Item
                label="Project Name"
                name="projectName"
                // value= {taskData.Project_name}
                initialValue={taskDatae.Project_name}
                rules={[
                  {
                    required: true,
                    message: "Please Select Short_summary",
                    whitespace: true,
                  },
                ]}
              >
                <Input disabled="true" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: "10px" }}>
              <Form.Item
                label="Issue Type"
                name="IssueType"
                initialValue={taskDatae.Issue_type}
              >
                <Select>
                  <Select.Option value="Bug">Bug</Select.Option>
                  <Select.Option value="Task">Task</Select.Option>
                  <Select.Option value="Story">Story</Select.Option>
                  <Select.Option value="Suggestion">Suggestion</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Short Summary"
                name="shortSummary"
                initialValue={taskDatae.Short_summary}
                rules={[
                  {
                    required: true,
                    message: "Please Select ",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: "10px" }}>
              <div style={{ marginBottom: "10px" }}>
                Description<span style={{ color: "red" }}>*</span>
              </div>
              {console.log(taskDatae.Description, "drgdfgdf", taskDatae)}
              <div style={{ marginBottom: "25px" }}>
                <CKEditor
                  className="cknew-editor"
                  editor={ClassicEditor}
                  // activeClass="p10"
                  data={taskDatae ? taskDatae?.Description : "-------"}
                  content={content()}
                  config={config}
                  events={{
                    change: onDescriptionChange,
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTextEditer(data);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col span={10} style={{display:'none' }} > */}
            {/* {console.log(taskDatae?.Upload_file,'taskDatae?.Upload_file')}
    {console.log(taskDatae?.Upload_file!='','taskDatae?.Upload_file')} */}

            <Col span={10}>
              <Form.Item
                label="Priority"
                name="priority"
                initialValue={taskDatae.Priority}
                rules={[
                  {
                    required: true,
                    message: "Please Select Priority!",
                    whitespace: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="HIGH">HIGH</Select.Option>
                  <Select.Option value="MEDIUM">MEDIUM</Select.Option>
                  <Select.Option value="LOW">LOW</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Assignees"
                name="assignees"
                initialValue={taskDatae.Assignees}
                rules={[
                  {
                    required: true,
                    message: "Please Select Assignees!",
                    whitespace: true,
                  },
                ]}
              >
                {/* <Select
                  onChange={(e) => setSelectedMember(e)}
                  options={assigneesList}
                /> */}

                <Select
                  onChange={(e) => setSelectedMember(e)}
                  showSearch="true"
                  optionFilterProp="children"
                >
                  {assigneesList?.map((item, index) => {
                    return (
                      <Select.Option value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                label="Reporter"
                name="reporter"
                initialValue={taskDatae.Reporter}
                rules={[
                  {
                    required: true,
                    message: "Please Select Reporter!",
                    whitespace: true,
                  },
                ]}
              >
                {/* <Select>
                  {reporterList?.map((item, index) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select> */}

                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                style={{ marginTop: "-5px" }}
                label="Assigned Date"
                name="assignedDate"

                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Assigned Date!",
                //   },
                // ]}
                // initialValue={taskData.Assigned_date}
              >
                <DatePicker
                  format={"DD/MM/YYYY"}
                  onChange={(e) => {
                    setAssignDate(e);
                  }}
                  defaultValue={moment(taskDatae.Assigned_date)}
                  // disabled ="false"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* {taskDatae.Issue_type !== 'Bug' ? ( */}
            <>
              <Col span={10}>
                <Form.Item label="Due Date" name="dueDate">
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    // disabledDate={(d) => !d || d.isSameOrBefore()}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    defaultValue={moment(taskDatae.Due_date)}
                    // disabledDate={(current) => {
                    //   return (
                    //     current && current < moment(assignDate, "YYYY-MM-DD")
                    //   );
                    // }}
                  />
                </Form.Item>
              </Col>
            </>
            {/* ) : ( */}
            <>
              <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Task Duration ( in hour, 30min = 0.5 )"
                  name="taskDuration"
                  initialValue={taskDatae?.Task_duration}
                  defaultValue={moment(taskDatae?.Assigned_date)}

                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please Select Assigned Date!',
                  //   },
                  // ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Link issue"
                  name="linkissue"
                  initialValue={
                    taskDatae.Linkissue_Taskkey == "undefined"
                      ? ""
                      : taskDatae.Linkissue_Taskkey
                  }
                  rules={[
                    {
                      // required: true,
                      message: "Please Select Link issue",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    // disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                    // onChange={(e) => setSelectedMember(e)}
                    showSearch="true"
                    // optionFilterProp="children"
                  >
                    {tasksList?.map((item, index) => {
                      return (
                        <Select.Option value={item.Task_key}>
                          {`${item.Task_key} - ${item.Short_summary}`}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Sprint"
                  name="sprint"
                  initialValue={taskDatae?.Sprint_id?.Name}
                  rules={[
                    {
                      // required: true,
                      message: "Please Select Sprint",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select showSearch="true">
                    {sprintdata?.map((item, index) => {
                      return (
                        <Select.Option
                          value={JSON.stringify({
                            _id: item?._id,
                            Name: item?.Name,
                          })}
                        >
                          {item?.Name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className="colemwidth leave-rows">
                <Form.Item name="profile">
                  <Form.Item
                    style={{ marginTop: "5px" }}
                    label="Upload File"
                    name="profile"
                    rules={[
                      {
                        required: false,
                        message: "Please upload a file.",
                      },
                      {
                        validator: async (rule, value) => {
                          if (Array.isArray(value)) {
                            const allowedFormats = [
                              ".jpg",
                              ".jpeg",
                              ".png",
                              ".pdf",
                              ".doc",
                              ".docx",
                              ".xlsx",
                              ".csv",
                            ];
                            for (let file of value) {
                              const fileExtension = file.name.substr(
                                file.name.lastIndexOf(".")
                              );
                              if (!allowedFormats.includes(fileExtension)) {
                                return Promise.reject(
                                  `Only ${allowedFormats.join(
                                    ", "
                                  )} files are allowed.`
                                );
                              }
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Buttonss
                      variant="contained"
                      component="label"
                      className="bttn-clr pms-same-btn pms-same-btn-upload-new"
                    >
                      <input
                        accept="files/*"
                        multiple
                        hidden
                        type="file"
                        onChange={(e) => {
                          // setFile(e.target.files);
                          fileHandler(e.target.files);
                        }}
                      />
                      Upload File
                    </Buttonss>
                  </Form.Item>
                  {/* <Buttonss
                  variant="contained"
                  component="label"
                  className="bttn-clr pms-same-btn pms-same-btn-upload-new"
                >
                  <input
                    accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlsx, .csv, "
                    multiple
                    hidden
                    type="file"
                    id="image"
                    name="image"
                    onChange={(e) => {
                      const files = e.target.files;
                      const allowedFormats = ['.jpg', '.png', '.jpeg', '.pdf', '.doc', '.docx', '.xlsx', '.csv'];

                      for (let file of files) {
                        const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
                        if (!allowedFormats.includes(fileExtension)) {
                          message.error(`Only ${allowedFormats.join(', ')} files are allowed.`);
                          return;
                        }
                      }

                      fileHandler(files);
                    }}
                  />
                  Upload File
                </Buttonss> */}
                  <p> (e.g. jpg, jpeg, png, pdf, doc, docx, xlsx, csv) </p>
                </Form.Item>
                {url.map((row, index) => {
                  // console.log(row, "row");
                  return (
                    <>
                      <Box className="upload-file-show">
                        <Typography variant="p">{row}</Typography>
                        <DeleteFilled onClick={() => deleteFilled(index)} />
                      </Box>
                    </>
                  );
                })}
              </Col>
              {/* <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Task Duration ( in hour, 30min = 0.5 )"
                  name="taskDuration"
                  initialValue={taskDatae?.Task_duration}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please Select Assigned Date!',
                  //   },
                  // ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col> */}
            </>
            {/* )} */}
          </Row>

          <div className="all-upload-pdf">
            {/* {olddocument !== undefined &&
                <ImageDemo element={imageUrl+olddocument} />
            } */}
            {olddocument !== undefined &&
              olddocument.map((element, index) => (
                <ImageDemo element={element} index={index} />
              ))}
          </div>

          {
            //  taskData.Issue_type == "Bug"? <></>:
            // <Row>
            //   <Col span={10}>
            //     <Form.Item
            //       label="Status"
            //       name="status"
            //       initialValue={taskData.Status}
            //       rules={[
            //         {
            //           required: true,
            //           message: 'Please Select Status!',
            //         },
            //       ]}
            //     >
            //       <Select>
            //         <Select.Option value="Pending">Pending</Select.Option>
            //         <Select.Option value="InProgress">In-Progress</Select.Option>
            //         <Select.Option value="Done">Done</Select.Option>
            //       </Select>
            //     </Form.Item>
            //   </Col>
            // </Row>
          }

          {/* {taskDatae.Issue_type == "Bug" ? (
            <></>
          ) : (
          )} */}

          <Form.Item>
            <div style={{ textAlign: "right" }}>
              <Button
                className="pms-same-btn"
                type="primary"
                htmlType="submit"
                // onClick={chackDescription}
                style={{ marginRight: "10px" }}
              >
                Update
              </Button>
              <Button className="pms-same-btn-cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;
