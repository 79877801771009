import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { Modal, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import AxiosInstance from "../../../service/AxiosInstance";
import AddVertical from "./AddVertical";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ReactLoader from "../../../components/common/ReactLoader";

export const Vertical = () => {
  const [verticalList, setVerticalList] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verticalId, setVerticalId] = useState();

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Vertical Name",
          field: "Vertical_name",
          width: 150,
          sort: "disabled",
        },

        {
          label: "Status",
          field: "Status",
          width: 200,
          sort: "disabled",
        },
        {
          label: "Action",
          field: "Action",
          sort: "disabled",
          width: 30,
        },
      ],

      rows: verticalList,
    });
  }, [verticalList]);

  const getAllVertical = () => {
    setLoading(true);
    AxiosInstance.get("/vertical/getAllVerticals").then((res) => {
      setLoading(false);
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          Vertical_name: item?.Vertical_name,
          Status: item?.Status,

          Action: (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                type="primary"
                onClick={() => {
                  setVerticalId(item?._id);
                  setIsOpen(true);
                }}
              >
                <EditOutlined />{" "}
              </Button>

              <Button
                danger
                className="dlt-btn"
                type="primary"
                onClick={() => {
                  setVerticalId(item?._id);
                  setIsOpenDeleteModal(true);
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          ),
        };
      });
      setVerticalList(list1);
    });
  };

  const deleteVertical = (id) => {
    const urlencoded = new URLSearchParams();

    urlencoded.append("id", id);
    AxiosInstance.post("/vertical/deleteVertical", urlencoded).then((res) => {
      toast.success("Vertical deleted Successfully");
      setIsOpenDeleteModal(false);
      getAllVertical();
    });
  };

  const handleCancel = (errorInfo) => {
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    getAllVertical();
  }, []);

  const reloadList = () => {
    setIsOpen(false);
    getAllVertical();
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {/* Delete Modal */}

      <Modal
        title="Are you sure to delete this record?"
        centered
        visible={isOpenDeleteModal}
        onOk={() => setIsOpenDeleteModal(false)}
        onCancel={handleCancel}
        width={380}
        footer={null}
        className="delete-modal"
      >
        <Button
          className="pms-same-btn"
          type="danger"
          onClick={() => deleteVertical(verticalId)}
          style={{
            marginRight: "7%",
          }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>

      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example">
              <h3 className="tablHeader">
                Vertical Master
                <Button
                  type="primary"
                  onClick={() => {
                    setIsOpen(true);
                    setVerticalId("");
                  }}
                  style={{ float: "right" }}
                  className="pms-same-btn"
                >
                  Add
                </Button>
                {isOpen ? (
                  <AddVertical
                    verticalId={verticalId}
                    inSidebar={true}
                    btnStyl={true}
                    reloadList={reloadList}
                    setIsOpen={(a) => {
                      setIsOpen(a);
                    }}
                  />
                ) : null}
              </h3>
            </MDBCol>
          </div>

          {loading ? (
            <ReactLoader loading={loading} />
          ) : (
            <div className="scrool-set">
              <MDBDataTableV5
                hover
                entriesOptions={[10, 20, 25]}
                entries={10}
                pagesAmount={4}
                data={datatable}
                searchTop
                searchBottom={false}
              />
            </div>
          )}
        </MDBCard>
      </div>
    </>
  );
};
