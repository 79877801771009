import { MYDAILYREPORTTASK, MYTASK } from "../actions/ActionsType";

const INITIAL_STATE = {
  MyTask: [],
  MyDailyTaskReport: [],
};

const TaskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MYTASK:
      return {
        ...state, 
        MyTask: action.payload,
      };
    case MYDAILYREPORTTASK:
      return {
        ...state,
        MyDailyTaskReport: action.payload,
      };
    default:
      return state;
  }
};

export default TaskReducer;
