import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import MeBar from "../me/MeBar";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
  Input,
} from "antd";
import Link from "antd/lib/typography/Link";
import { object } from "prop-types";
//new

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
//end
const EmployeeWorkingHours = () => {
  const [form] = Form.useForm();
  const [employeeWorkingHours, setEmployeeWorkingHours] = useState([]);
  const [columns, setColumns] = useState([]);
  //new
  const [filteredData, setFilteredData] = useState([]);
  const onFinish = (values) => {
    AxiosInstance.get("auth/present-hours-report").then(async (res) => {
      let list1 = [];
      let SoratedArr = res.data.data.sort((a, b) =>
        a.Employee_id.First_name > b.Employee_id.First_name ? 1 : -1
      );
      list1 = SoratedArr.map((item, index) => {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item?.Check_In))
        );
        var seconds = duration.asSeconds();
        let minutes = Math.floor(seconds) % 3600;
        let durationTime =
          Math.floor(seconds / 3600) +
          " hours " +
          Math.floor(minutes / 60) +
          " mins " +
          Math.floor(seconds % 60) +
          " seconds ";
        console.log(duration, 78);
        return {
          ...item,
          Name: `${item.Employee_id.First_name} ${item.Employee_id.Last_name}`,
        };
      });
      setEmployeeWorkingHours(list1);
      console.log(list1, 44);
    });
  };
  // new
  const onSearch = (event) => {
    const searchText = event?.target?.value.toLowerCase();

    const result = employeeWorkingHours.filter((item) => {
      const fullName = `${item?.Employee_id?.First_name} ${item?.Employee_id?.Last_name}`.toLowerCase();

      return fullName.includes(searchText);
    });

    const noDataFound = [{ key: "no-data-found", Name: "No Data Found" }];
    setFilteredData(result.length > 0 ? result : noDataFound);
  };

  //new
  const exportAll = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(blob, fileName + fileExtension);
  };
  useEffect(() => {
    let dynamicColumn = [
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        width: 150,
      },
    ];
    const get_all_dates = (year, month) => {
      let date = new Date(year, month, 1);
      let dates = [];
      let i = 0;
      console.log(date.getMonth() === month)
      while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
        let dateTitle =
          dates[i].getMonth()+1 +
          "/" +
          dates[i].getDate() +
          "/" +
          dates[i].getFullYear();
        dynamicColumn.push({
          title: dateTitle,
          dataIndex: dateTitle,
          key: dateTitle,
          render: (data) => {
            console.log(data, 65);
            return (
              <>
                {data === "NaN:NaN:NaN.NaN" || data === undefined ? (
                  <div className="employee-working-hours-data">-</div>
                ) : (
                  <div className="employee-working-hours-data">{data}</div>
                )}
              </>
            );
          },
        });
        i++;
      }
    };
    setColumns(dynamicColumn);
    let curr_date = new Date();
    get_all_dates(curr_date.getFullYear(), curr_date.getMonth());
  }, []);

  useEffect(() => {
    onFinish();
  }, []);
  return (
    <div className="event-main event-main2 Employee-working-hours">
      <div className="page-content">
        <div className="attendance-head daily-log-head">
          <p className="tablHeader">Employee Working Hours</p>
        
        {/* new */}
        <div style={{ display: "flex", "justifyContent": "flex-end" }}>
          <Input
            type="search"
            placeholder="Search"
            onChange={onSearch}
            style={{ maxWidth: "150px", marginRight: "9px" }}
          />
           <Button className="ant-btn ant-btn-default pms-same-btn"
            onClick={() =>
              exportAll(employeeWorkingHours, "Employee_Working_Hours")
            }
          >
            Export
          </Button>
         </div>
        </div>
        </div>
        <div>
          
      </div>
      <div className="tasklisttbl">
        <Table
          dataSource={
            filteredData.length > 0 ? filteredData : employeeWorkingHours
          }
          columns={columns}
          pagination={{ pageSize: 30 }}
        />
        {/* </div> */}
        {/* end */}
        {/* <div className="tasklisttbl">
          <Table
            dataSource={employeeWorkingHours}
            columns={columns}
            pagination={{ pageSize: 30 }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default EmployeeWorkingHours;
