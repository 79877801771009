import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment";
import {
  Dropdown,
  Menu,
  Form,
  Modal,
  Input,
  Button,
  Radio,
  TimePicker,
  Checkbox,
  Row,
  Col,
  Select,
} from "antd";
import confirm from "antd/lib/modal/confirm";
import PmsDatePicker from "../me/leaveModule/PmsDatePicker";
import ManualAttendanceLogs from "./ManualAttendanceLogs";
import { findOne } from "domutils";
import ReactLoader from "../../../components/common/ReactLoader";
import { useRef } from "react";
import axios from "axios";
import ManualAttendanceBar from "./manualAttendanceTabs/ManualAttendanceBar";

const ManualAttendanceReport = () => {
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [rejectComment, setRejectComment] = useState("");
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [isRejectOpenbulk, setIsRejectOpenbulk] = useState(false);
  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [isApproveOpenbulk, setIsApproveOpenbulk] = useState(false);
  const [empId, setEmpId] = useState("");
  const [employee, setEmployee] = useState({});
  const [row, setRow] = useState({});
  const [rejectId, setRejectId] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [checkInValueBulk, setCheckInValueBulk] = useState(moment());
  const [checkOutValueBulk, setCheckOutValueBulk] = useState(moment());
  const [checkInValue, setCheckInValue] = useState(moment());
  const [checkOutValue, setCheckOutValue] = useState(moment());

  const [presentFirstHalf, setPresentFirstHalf] = useState(false);
  const [presentSecondHalf, setPresentSecondHalf] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDate, setFilteredDate] = useState();
  const [status, setStatus] = useState("All");
  const [result, setResult] = useState([]);
  const [logs, setLogs] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [leave, setLeave] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [Checked, setChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [form] = Form.useForm();
  //add
  const [allattendancdeId, setAllattendancdeId] = useState([]);
  const [selectedChange, setSelectedChange] = useState("");
  const [currentPageActiveRecords, setCurrentPageActiveRecords] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesAmount, setPagesAmount] = useState(1);
  const itemsPerPage = 10;
  const [requestedDate, setRequestedDate] = useState("");
  const [secondHalfReqOpen, setSecondHalfReqOpen] = useState(false);
  //
  const userDetails = localStorage.getItem("userdata");
  const UserData = userDetails ? JSON.parse(userDetails) : ""
  //add
  console.log(attendanceReport, "attendanceReportdata");
  const handleOpenModal = () => {
    setIsApproveOpenbulk(true);
  };
  const handleOpenRejectl = () => {
    setIsRejectOpenbulk(true);
  };
  console.log(activePage, "maulik80", currentPageActiveRecords);

  const setActiveRecords = (currentActivePage) => {
    const start = (currentActivePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    let filteredResult = [];
    if (status !== "All") {
      filteredResult = result.filter((item) => item.status === status);
    } else {
      filteredResult = result;
    }

    const activeRecords = filteredResult.slice(start, end).map((item) => item);

    setCurrentPageActiveRecords(activeRecords);
  };
  useEffect(() => {
    setActiveRecords(activePage);
  }, [result]);
  //add

  const handleCheckAll = (e) => {
    setCurrentPageActiveRecords((d) => {
      console.log(d, "maulik111-in", activePage);
      if (e.target.checked) {
        setIsChecked(true);
        const checkboxesOnPage = d?.map((item) => item._id);
        setAllattendancdeId(checkboxesOnPage);
      } else {
        setIsChecked(false);
        setAllattendancdeId([]);
      }
      return d;
    });
  };

  const handleCheckboxChange = (item, e) => {
    console.log(item, "122222");
    if (e.target.checked) {
      console.log("condition true");

      setAllattendancdeId((prevallattendancdeId) => [
        ...prevallattendancdeId,
        item._id,
      ]);
    } else {
      setAllattendancdeId((prevallattendancdeId) =>
        prevallattendancdeId.filter((checkboxId) => checkboxId !== item._id)
      );
    }
  };

  const onDateChangebulk = (time) => {
    setCheckInValueBulk(time);
  };

  const onChangeCheckOutbulk = (time) => {
    setCheckOutValueBulk(time);
  };
 

  {/* Addedd  handleApprovesingle api call function */}
  const handleApprovesingle = async(item, e) => {
   

    const urlencoded = new URLSearchParams();
    urlencoded.append("attendance_id", item._id);
   

    await AxiosInstance.post("/auth/attendanceRequestStatusUpdateApproved", urlencoded)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message);
       
          setAttendanceReport((prevAttendanceReport) =>
            prevAttendanceReport.filter((row) => row.id !== item.id)
          );
          getAllRequest();
        }
      })
      .catch((error) => {
        console.error("Error approving single item:", error);
      });
  };
     {/* end */}
 
  useEffect(() => {
    setDatatable({
      columns: [
        // {
        //   //add lable Input only
        //   label: (
        //     <input
        //       type="checkbox"
        //       checked={isChecked}
        //       onChange={(e) => {
        //         handleCheckAll(e);
        //       }}
        //     />
        //   ),
        //   field: "checkBox",
        //   sort: "disabled",
        //   width: 100,
        // },
        {
          label: "Employee Name",
          field: "name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Date",
          field: "date",
          width: 200,
          sort: "disabled",
        },
        {
          label: "Type",
          field: "type",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Requested Time",
          field: "time",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Reason",
          field: "reason",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Status",
          field: "status",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Comment",
          field: "comment",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Action",
          field: "Action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: filteredData,
    });
  }, [attendanceReport, filteredData, isChecked]);

  const generateRestult = () => {
    let filteredResult = result;

    if (status != "All") {
      filteredResult = result.filter((item) => item.status == status);
    }

    let list1 = filteredResult.map((item, index) => {
      return {
        //add check boxes
        checkBox: (
          <input
            type="checkbox"
            key={index}
            value={item?._id}
            checked={allattendancdeId?.includes(item?._id)}
            onChange={(e) => {
              handleCheckboxChange(item, e);
            }}
          />
        ),

        name:
        item?.employee_id ? 
          item?.employee_id?.First_name +
          " " +
          item?.employee_id?.Middle_name +
          " " +
          item?.employee_id?.Last_name : "-",
        date: item?.date ? moment(item?.date).format("DD/MM/YYYY") : "",
        type: item?.type,
        time: item?.time ? moment(item?.time).format("hh:mm A") : "",
        reason: item?.reason,
        comment: item?.status_comment ? item?.status_comment : "-",
        status: item?.status,


       
        Action: (
          <>
          {
            (UserData?.[0]?._id == "637b62187c22a305936a917f" || UserData?.[0]?._id == "61b727ca37a0b8573dde2d97"  ) ? <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              value={item?._id}
              onChange={(e) => {
                handleApprovesingle(item, e);
              }}
            />
            {actions(item)}
          </div> : ""
          }
          </>
        ),
        

      };
     
    });

    if (filteredDate === undefined) {
      setAttendanceReport(list1);
      setFilteredData(list1);
    }
  
  };

  useEffect(() => {
    generateRestult();
    //add on allattendancdeId
  }, [result, status, allattendancdeId ]);

  const getAllRequest = () => {
    setLoading(true);
    AxiosInstance.get("auth/getAllMaunualAttendanceRequest").then((res) => {
      console.log(res.data.data, "alldata324");
      setLoading(false);
      let modified = res.data.data?.filter((item) => item?.status !== "Approved")
      setResult(modified);
         
    });
  };

  // const getAttendanceRequest = (requestId) => {
  //   let urlencoded = new URLSearchParams();
  //   urlencoded.append("id", empId);

  //   AxiosInstance.post("auth/getManualAttendanceByEmpCode", urlencoded).then(
  //     (res) => {
  //       console.log(res?.data.data, "336");
  //       setEmployee(res.data.data);
  //       form.setFieldsValue({
  //         presentcount: res.data.data.present_count,
  //         presentFirstHalf: res.data.data.present_first_half,
  //         presentSecondHalf: res.data.data.present_second_half,
  //       });
  //       setPresentSecondHalf(res.data.data.present_second_half);
  //       setPresentFirstHalf(res.data.data.present_first_half);
  //       setCheckInValue(moment(res.data.data.check_in_time));
  //       setCheckOutValue(moment(res.data.data.check_out_time));
  //     }
  //   );
  // };
  // useEffect(() => {
  //   getAttendanceRequest();
  // }, [empId]);

  useEffect(() => {
    getAllRequest();
  }, []);
  console.log();
  function getAttendanceLogs(id) {
    AxiosInstance.get(`auth/attendance/logs?id=${id}`).then(async (res) => {
      // res.data.data

      setLogs(res.data.data);
      console.log(res.data.data, "attendance");
      showDrawer();
      console.log(res.data, "4545");
    });
  }

  const approveStatus = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("added_first_half", Boolean(presentFirstHalf));
    urlencoded.append("added_second_half", Boolean(presentSecondHalf));
    urlencoded.append("manual_id", employee._id);
    urlencoded.append("status", "Approved");
    urlencoded.append("employee_id", employee.Employee_id);
    urlencoded.append("attendance_id", row._id);
    urlencoded.append("attendance_type", row.type);
    urlencoded.append("manual_check_in", checkInValue);
    urlencoded.append("manual_check_out", checkOutValue);
    //   const jsonObject = {};
    // urlencoded.forEach((value, key) => {
    //   jsonObject[key] = value;
    // });

    // const jsonString = JSON.stringify(jsonObject);
    // console.log(jsonString ,"jsonString");

    AxiosInstance.post("/auth/attendanceRequestStatusUpdate", urlencoded).then(
      (res) => {
        if (res.data.status == 1) {
          getAllRequest();
          setIsApproveOpen(false);
        }
      }
    );
  };


  const ApprovedSecondHalfRequest = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("added_second_half", Boolean(presentSecondHalf));
    urlencoded.append("manual_id", employee._id);
    urlencoded.append("status", "Approved");
    urlencoded.append("employee_id", employee.Employee_id);
    urlencoded.append("attendance_id", row._id);
    urlencoded.append("attendance_type", row.type);
    urlencoded.append("manual_check_out", requestedDate);
    //   const jsonObject = {};
    // urlencoded.forEach((value, key) => {
    //   jsonObject[key] = value;
    // });

    // const jsonString = JSON.stringify(jsonObject);
    // console.log(jsonString ,"jsonString");

    AxiosInstance.post("/auth/attendanceRequestStatusUpdate", urlencoded).then(
      (res) => {
        if (res.data.status == 1) {
          getAllRequest();
          setSecondHalfReqOpen(false);
        }
      }
    );
  };






















  //add  approveStatusbulk function and api call
  const approveStatusbulk = () => {
    //  const payload = {
    //   added_first_half:Boolean(presentFirstHalf),
    //   added_second_half:Boolean(presentSecondHalf),
    //   status: "approved",
    //   attendance_ids :allattendancdeId,
    //   manual_check_in : checkInValueBulk._d,
    //   manual_check_out : checkOutValueBulk._d
    // }
    // console.log(payload,'payload')
    const payload = new URLSearchParams();

    payload.append("added_first_half", Boolean(presentFirstHalf));
    payload.append("added_second_half", Boolean(presentSecondHalf));
    payload.append("status", "Approved");
    payload.append("attendance_ids", JSON.stringify(allattendancdeId));
    payload.append("manual_check_in", checkInValueBulk);
    payload.append("manual_check_out", checkOutValueBulk);
    AxiosInstance.post("/auth/bulkAttendanceRequestStatusUpdate", payload).then(
      (res) => {
        if (res.data.status == 1) {
          getAllRequest();
          setAllattendancdeId([]);
          setIsApproveOpenbulk(false);
        }
        toast.success(res?.data?.message);
      }
    );
  };

  const rejectStatus = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("reject_id", rejectId);
    urlencoded.append("status_comment", rejectComment);
    urlencoded.append("status", "rejected");

    AxiosInstance.post("/auth/attendanceRequestStatusUpdate", urlencoded).then(
      (res) => {
        if (res.data.status == 1) {
          setRejectComment("");
          form.resetFields();
          setIsRejectOpenbulk(false);
          getAllRequest();
        }
      }
    );
  };

  //add  rejectStatusbulk function and api call
  const rejectStatusbulk = () => {
    const payload = new URLSearchParams();

    payload.append("attendance_ids", JSON.stringify(allattendancdeId));
    payload.append("status_comment", rejectComment);
    payload.append("status", "rejected");

    // const jsonObject = {};
    // urlencoded.forEach((value, key) => {
    //   jsonObject[key] = value;
    // });

    // const jsonString = JSON.stringify(jsonObject);
    // console.log(jsonString);
    AxiosInstance.post("auth/bulkAttendanceRequestStatusUpdate", payload).then(
      (res) => {
        if (res.data.status == 1) {
          form.resetFields();
          setIsRejectOpenbulk(false);
          getAllRequest();
          setAllattendancdeId([]);
        }
        toast.error(res?.data?.message);
      }
    );
  };
  const handleCancel = (errorInfo) => {
    if (!isApproveOpen) {
      setIsRejectOpen(false);
    }
    setIsApproveOpen(false);
  };
  const handleCancelbulk = () => {
    if (!isApproveOpenbulk) {
      setIsRejectOpenbulk(false);
    }
    setIsApproveOpenbulk(false);
  };

  const handleClick = (items, key, checkRequest) => {
    setLeave(items);
    setCheckOutValue(moment(items.check_out_time))
    if (key == "approve") {
      if(checkRequest){
        setSecondHalfReqOpen(true);
        setEmpId(items._id);
  
        setRow(items);
      }else{
        setIsApproveOpen(true);
      setEmpId(items._id);

      setRow(items);
      }
      console.log(items, "items");
      
    } else if (key == "reject") {
      setIsRejectOpen(true);
      setRejectId(items?._id);
    } else if (key == "logs") {
      showDrawer();
      getAttendanceLogs(items._id);
    }
  };

  const actionMenu = (items, listAction) => {
    let reasons = ["technical issue", "Health Issue" , "Vehicle Issue" , "Cricket Tournament" ,  "Client Meeting" , "Joining Formalities" , "Early IN/OUT" , "Device error"  ]
    let checkRequest = false ;

    // console.log(reasons?.includes(items?.reason) , ':reasons?.match(items?.reason)' , items)
    if(items?.type == "checkOut" && !reasons?.includes(items?.reason)){
      checkRequest = true
      setRequestedDate(moment(items?.time))
    }
    return(
    <Menu items={listAction} onClick={(e) => handleClick(items, e.key , checkRequest)} />
  )};

  const actions = (items) => {
    let listAction = [
      {
        key: "approve",
        label: "Approve",
        items: items,
      },
      {
        key: "reject",
        label: "Reject",
        items: items,
      },
      {
        key: "logs",
        label: "Logs",
        items: items,
      },
    ];

    return (
      <>
        <Dropdown.Button
          overlay={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    );
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const onDateChange = (time) => {
    console.log("date changed");
    const selectedDate = moment(time._d).format("DD/MM/YYYY");
    setFilteredDate(selectedDate);
    const filteredReport = attendanceReport.filter((val) => {
      if (val.date === moment(time._d).format("DD/MM/YYYY")) {
        return val;
      }
    });
    setFilteredData(filteredReport);
    console.log(time, "check time");
    setCheckInValue(time);
  };
  const onChangeCheckOut = (time) => {
    setCheckOutValue(time);
  };

  return (
    <div className="demo">
      <ManualAttendanceBar />
      <ManualAttendanceLogs
        logs={logs}
        leave={leave}
        visible={drawerVisible}
        onClose={(value) => closeDrawer(value)}
      />
      {/* <Modal
        title="Approve request modal"
        centered
        visible={isApproveOpen}
        onOk={() => {
          handleClick(false);
        }}
        onCancel={handleCancel}
        width={410}
        footer={null}
      >
        {isApproveOpen && (
          <Form form={form} >
            <Form.Item
              label="Check In Time"
              name="checkInTime"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select time",
              //   },
              // ]}
            >
              {" "}
              {checkInValue.format("HH:mm")}
              <TimePicker
                onChange={onDateChange}
                // defaultValue={moment(
                //   moment(employee?.check_in_time).format("HH:mm"),
                //   "HH:mm"
                // )}
                defaultValue={checkInValue}
                value={checkInValue}
              />
            </Form.Item>
            <Form.Item
              label="Check Out Time"
              name="checkOutTime"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select time",
              //   },
              // ]}
            >
              {" "}
              {checkOutValue.format("HH:mm")}
              <TimePicker
                onChange={onChangeCheckOut}
                defaultValue={checkOutValue}
                value={checkOutValue}
              />
            </Form.Item>
            <Form.Item label="Present count" name="presentcount">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Present First Half" name="presentFirstHalf">
              <Checkbox
                checked={presentFirstHalf}
                onChange={(e) => setPresentFirstHalf(e.target.checked)}
              />
            </Form.Item>
            <Form.Item label="Present Second Half" name="presentSecondHalf">
              <Checkbox
                checked={presentSecondHalf}
                onChange={(e) => setPresentSecondHalf(e.target.checked)}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              style={{ marginRight: "10px" }}
              onClick={() => approveStatus()}
              className="pms-same-btn"
            >
              Submit
            </Button>
          </Form>
        )}
      </Modal> */}


<Modal
        title="Check-Out request modal"
        centered
        visible={isApproveOpen}
        onOk={() => {
          handleClick(false);
        }}
        onCancel={handleCancel}
        width={410}
        footer={null}
      >
        {isApproveOpen && (
          <Form form={form} >
         
            <Form.Item
              label="Check Out Time"
              name="checkOutTime"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select time",
              //   },
              // ]}
            >
              <TimePicker
                onChange={onChangeCheckOut}
                defaultValue={checkOutValue ? checkOutValue : null}
                value={checkOutValue}
              />
            </Form.Item>
      
            <Form.Item label="Present Second Half" name="presentSecondHalf">
              <Checkbox
                checked={presentSecondHalf}
                onChange={(e) => setPresentSecondHalf(e.target.checked)}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              style={{ marginRight: "10px" }}
              onClick={() => approveStatus()}
              className="pms-same-btn"
            >
              Submit
            </Button>
          </Form>
        )}
      </Modal>

      {/* Request for Second Half */}
      <Modal
        title="Second Half request modal"
        centered
        visible={secondHalfReqOpen}
        onOk={() => {
          setSecondHalfReqOpen(false);
        }}
        onCancel={() => setSecondHalfReqOpen(false)}
        width={410}
        footer={null}
      >
        {secondHalfReqOpen && (
          <Form form={form} >
            <Form.Item
              label="Actual Check Out Time"
            >
              <TimePicker
                onChange={onDateChange}
                defaultValue={moment(
                  moment(requestedDate).format("HH:mm"),
                  "HH:mm"
                )}
                // defaultValue={moment(requestedDate).format("hh:mm a")}
                value={requestedDate}
                disabled
              />
            </Form.Item>
           
           
            <Form.Item label="Present Second Half" name="presentSecondHalf">
              <Checkbox
                checked={presentSecondHalf}
                onChange={(e) => setPresentSecondHalf(e.target.checked)}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              style={{ marginRight: "10px" }}
              onClick={() => ApprovedSecondHalfRequest()}
              className="pms-same-btn"
            >
              Submit
            </Button>
          </Form>
        )}
      </Modal>


      <Modal
        title="Approve request modal"
        centered
        visible={isApproveOpenbulk}
        onOk={() => {
          handleClick(false);
        }}
        onCancel={handleCancelbulk}
        width={410}
        footer={null}
      >
        {isApproveOpenbulk && (
          <Form form={form}>
            <Form.Item
              label="Check In Time"
              name="checkInTime"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select time",
              //   },
              // ]}
            >
              {" "}
              {checkInValueBulk.format("HH:mm")}
              <TimePicker
                onChange={onDateChangebulk}
                // defaultValue={moment(
                //   moment(employee?.check_in_time).format("HH:mm"),
                //   "HH:mm"
                // )}
                defaultValue={checkInValueBulk}
                value={checkInValueBulk}
              />
            </Form.Item>
            <Form.Item
              label="Check Out Time"
              name="checkOutTime"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select time",
              //   },
              // ]}
            >
              {" "}
              {checkOutValueBulk.format("HH:mm")}
              <TimePicker
                onChange={onChangeCheckOutbulk}
                defaultValue={checkOutValueBulk}
                value={checkOutValueBulk}
              />
            </Form.Item>
            <Form.Item label="Present count" name="presentcount">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Present First Half" name="presentFirstHalf">
              <Checkbox
                checked={presentFirstHalf}
                onChange={(e) => setPresentFirstHalf(e.target.checked)}
              />
              {/* <Radio.Group>
              <Radio>True</Radio>
              <Radio>False</Radio>
            </Radio.Group> */}
            </Form.Item>
            <Form.Item label="Present Second Half" name="presentSecondHalf">
              <Checkbox
                checked={presentSecondHalf}
                onChange={(e) => setPresentSecondHalf(e.target.checked)}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              style={{ marginRight: "10px" }}
              onClick={() => approveStatusbulk()}
              className="pms-same-btn"
            >
              Submit
            </Button>
          </Form>
        )}
      </Modal>
      <Modal
        title="Reject request modal"
        centered
        visible={isRejectOpenbulk}
        onOk={() => setIsRejectOpen(false)}
        onCancel={handleCancelbulk}
        width={410}
        footer={null}
      >
        <Form form={form}>
          <div style={{ marginBottom: "10px" }}>
            Comment<span style={{ color: "red" }}>*</span>
          </div>
          <Form.Item
            name="status_comment"
            rules={[
              {
                required: true,
                message: "Please Enter Description",
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              maxLength={250}
              showCount
              onChange={(e) => setRejectComment(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "end" }}>
              <Button
                onClick={() => rejectStatusbulk()}
                className="pms-same-btn-cancel"
              >
                Reject
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Reject request modal"
        centered
        visible={isRejectOpen}
        onOk={() => setIsRejectOpen(false)}
        onCancel={handleCancel}
        width={410}
        footer={null}
      >
        <Form form={form}>
          <div style={{ marginBottom: "10px" }}>
            Comment<span style={{ color: "red" }}>*</span>
          </div>
          <Form.Item
            name="status_comment"
            rules={[
              {
                required: true,
                message: "Please Enter Description",
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              maxLength={250}
              showCount
              onChange={(e) => setRejectComment(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "end" }}>
              <Button
                onClick={() => rejectStatus()}
                className="pms-same-btn-cancel"
              >
                Reject
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <MDBCard className="page-content">
        {/* <div>
          <MDBCol
            // style={{ marginLeft: '7px' }}
            size="md"
            className="col-example"
          >
            {" "}
            <>
              <div className="leave-set-btn2">
                <h3 className="tablHeader">Manual Attendance Request Report </h3>
              </div>
            </>
          </MDBCol>
        </div>
        <hr /> */}
        <Row className="create-project">
          <Col span={6}>
            <Form.Item label="Date" name="date">
              <PmsDatePicker format={"DD/MM/YYYY"} onChange={onDateChange} />
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Select
              showSearch="true"
              placeholder="Select Status"
              value={status}
              onChange={(e) => setStatus(e)}
            >
              <Select.Option value="Pending">Pending</Select.Option>
              <Select.Option value="Approved">Approved</Select.Option>
              <Select.Option value="Rejected">Rejected</Select.Option>
            </Select>
          </Col>
        </Row>

        <div className="search-select-changes">
          {allattendancdeId.length > 0 ? (
            <div className="update-task-again">
              <Select
                placeholder="Select To Change"
                style={{ width: "163px", marginLeft: "180px" }}
                onChange={(e) => setSelectedChange(e)}
              >
                <Select.Option value="Approve">Approve</Select.Option>
                <Select.Option value="Reject">Reject</Select.Option>
              </Select>
              {selectedChange == "Approve" ? (
                <Button onClick={handleOpenModal}> Approve </Button>
              ) : (
                ""
              )}
              {selectedChange == "Reject" ? (
                <Button onClick={handleOpenRejectl}> Reject</Button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}{" "}
        </div>
        {loading ? (
          <ReactLoader loading={loading} />
        ) : (
          <div className="scrool-set">
            <MDBDataTableV5
              hover
              entriesOptions={[10, 20, 25]}
              entries={10}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
              //add disable page re render
              disableRetreatAfterSorting={true}
              // add onPageChange
              onPageChange={(data) => {
                console.log(data, "dataflowchek");
                console.log(data.activePage, "currentpage");
                setActivePage(data.activePage);
                setPagesAmount(data.pagesAmount);
                setActiveRecords(data.activePage);
                setCurrentPage(data.activePage);
              }}
            />
          </div>
        )}
      </MDBCard>
    </div>
  );
};

export default ManualAttendanceReport;
