import { CHANGETASKSTATUS } from "../actions/ActionsType";


const StatusReducer = (state = 0, action) => {
  switch (action.type) {
    case CHANGETASKSTATUS:
      return  state + 1
    default:
      return state;
  }
};

export default StatusReducer;