import React, { useState } from "react";
import {
  Table,
  Modal,
  Select,
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Tabs,
} from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment";
import ManualRequestAttendance from "./ManualAttendanceRequest";
import { useEffect } from "react";
import ReactLoader from "../../../components/common/ReactLoader";
import { ToastContainer, toast } from "react-toastify";
const LeaveAttendancePresent = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: "green" }}></span>
        <p style={{ margin: "0px" }}>DP</p>
      </div>
    </div>
  );
};

const LeaveAttendanceAbsent = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: "red" }}></span>
        <p style={{ margin: "0px" }}>ABS</p>
      </div>
    </div>
  );
};

const LeaveAttendanceWO = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: "gray" }}></span>
        <p style={{ margin: "0px" }}>WO</p>
      </div>
    </div>
  );
};

const HolidayAttendance = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: "blue" }}></span>
        <p style={{ margin: "0px" }}>HD</p>
      </div>
    </div>
  );
};
const PaidLeave = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: "yellow" }}></span>
        <p style={{ margin: "0px" }}>PL</p>
      </div>
    </div>
  );
};

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Day",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "Shift Code",
    dataIndex: "shiftcode",
    key: "shiftcode",
  },
  {
    title: "Shift inTime",
    dataIndex: "shiftintime",
    key: "shiftintime",
  },
  {
    title: "Shift outTime",
    dataIndex: "shiftouttime",
    key: "shiftouttime",
  },
  {
    title: "InTime",
    dataIndex: "intime",
    key: "intime",
  },
  {
    title: "OutTime",
    dataIndex: "outtime",
    key: "outtime",
  },
  {
    title: "First Half",
    dataIndex: "firsthalf",
    key: "firsthalf",
  },
  {
    title: "Second Half",
    dataIndex: "secondhalf",
    key: "secondhalf",
  },
  {
    title: "Portion",
    dataIndex: "portion",
    key: "portion",
  },
  {
    title: "Tot. Hrs",
    dataIndex: "tothrs",
    key: "tothr",
  },
  // {
  //   title: 'InlateMark',
  //   dataIndex: 'inlatemark',
  //   key: 'inlatemark',
  // },
  // {
  //   title: 'OutlateMark',
  //   dataIndex: 'outlatemark',
  //   key: 'outlatemark',
  // },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const AttendanceDetails = (props) => {
  const {
    year,
    month,
    employee_id,
    setattendanceLogsEXP,
    setEmployeeName,
    setEmployeeCode,
    setShiftType,
  } = props;
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [workingDays, setWorkingDays] = useState(0);
  const [totalPresent, setTotalPresent] = useState(0);
  const [holiday, setHoliday] = useState(0);
  const [paidLeave, setPaidLeave] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lateMark, setLateMark] = useState(0);
  const [payableDays, setPayableDays] = useState(0);
  const [unApprovedLeave, setUnApprovedLeave] = useState(0);
  const [approvedLeave, setApprovedLeave] = useState(0);
  const [sickLeave, setSickLeave] = useState(0);
  const [casualLeave, setCasualLeave] = useState(0);
  const [totalPaidLeave, setTotalPaidLeave] = useState(0);
  // new
  const [visibleAttendance, setAttendanaceVisible] = useState(false);
  // new
  const [form] = Form.useForm();
  // const [EmpName, setEmpName] = useState([]);
  // const [employeeName, setEmployeeName] = useState('');
  // const [employeecode, setEmployeeCode] = useState('');

  const [totalWO, setTotalWO] = useState(0);
  // new
  const handleApprove = async () => {
    try {
      const urlencoded = new URLSearchParams();
      urlencoded.append("status", "Approved");
      const response = await AxiosInstance.post(
        "auth/action-present-report",
        urlencoded
      );
      const message = response?.data?.message;
      if (message) {
        toast.success(message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // new
  const toDayDate = new Date();
  // new
  const firstDayOfMonth = new Date(
    toDayDate.getFullYear(),
    toDayDate.getMonth(),
    1
  );
  // new
  const isWeekend =
    firstDayOfMonth.getDay() === 0 || firstDayOfMonth.getDay() === 6;
  // new
  if (isWeekend) {
    firstDayOfMonth.setDate(
      firstDayOfMonth.getDate() +
        (firstDayOfMonth.getDay() === 0
          ? 1
          : firstDayOfMonth.getDay() === 6
          ? 2
          : firstDayOfMonth.getDay())
    );
  }
  // new
  const isSameDateAsFirstDayOfMonth =
    toDayDate.getDate() === firstDayOfMonth.getDate();
  // new
  const onRejectAttendance = async (values) => {
    try {
      let urlencoded = new URLSearchParams();
      urlencoded.append("status", "Issue");
      urlencoded.append("comment", values?.comment);
      const response = await AxiosInstance.post(
        "auth/action-present-report",
        urlencoded
      );
      if (response?.data?.message) {
        toast.success(response.data.message);
      }
      form.resetFields();
      setAttendanaceVisible(false);
    } catch (error) {
      console.error("Error from Axios:", error);
    }
  };
  // neww
  const handleReject = () => {
    setAttendanaceVisible(isSameDateAsFirstDayOfMonth);
  };

  const findTotalHours = (checkin, checkout) => {
    var checkinTimeFormat = moment(checkin).format("HH:mm:ss");
    var checkoutTimeFormat = moment(checkout).format("HH:mm:ss");

    var startTime = moment(checkinTimeFormat, "HH:mm:ss");
    var endTime = moment(checkoutTimeFormat, "HH:mm:ss");

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    // duration in second
    var second = parseInt(duration.asSeconds()) % 60;

    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (second < 10) {
      second = "0" + second;
    }
    return hours + ":" + minutes + ":" + second;
  };

  const checkFirstHalf = (item) => {
    if (item.present_first_half) {
      return <LeaveAttendancePresent />;
    } else if (item.weekOff == true) {
      return <LeaveAttendanceWO />;
    } else if (item.holiday == true) {
      return <HolidayAttendance />;
    } else if (item.leave == true && item.present_first_half == false) {
      return <PaidLeave />;
    } else {
      return <LeaveAttendanceAbsent />;
    }
  };
  const checkFirstHalfLate = (item) => {
    if (item.present_first_half) {
      return false;
    } else if (item.weekOff == true) {
      return false;
    } else if (item.holiday == true) {
      return false;
    } else if (item.leave == true && item.present_first_half == false) {
      return false;
    } else {
      var hh = moment(item.checkIn).format("HH");

      if (hh <= 11 && item.checkOut) {
        return true;
      }
      return false;
    }
  };

  const checkSecondHalf = (item) => {
    if (item.present_second_half) {
      return <LeaveAttendancePresent />;
    } else if (item.weekOff == true) {
      return <LeaveAttendanceWO />;
    } else if (item.holiday == true) {
      return <HolidayAttendance />;
    } else if (item.leave == true && item.present_second_half == false) {
      return <PaidLeave />;
    } else {
      return <LeaveAttendanceAbsent />;
    }
  };
  const checkHoliday = (item) => {
    if (item.holiday == true) {
      return <HolidayAttendance />;
    }
  };

  const getDetails = (values) => {
    setTotalAbsent(0);
    setTotalPresent(0);
    setPayableDays(0);

    if (props.month == "" || props.year == "") return false;
    const urlencoded = new URLSearchParams();
    urlencoded.append("month", props.month);
    urlencoded.append("year", props.year);
    urlencoded.append("employee_id", props.employee_id);
    setLoading(true);
    AxiosInstance.post("auth/faceLoginList", urlencoded).then(async (res) => {
      setLoading(false);
      let list1 = [];
      const attendance = res.data.attendance;
      setTotalAbsent(attendance.absent);
      setTotalPresent(attendance.present);
      setHoliday(attendance.holiday);
      setPaidLeave(attendance.paidLeave);
      if (props?.setEmployeeName != undefined) {
        setEmployeeName(res?.data?.attendance?.name);
      }
      if (props?.setEmployeeCode != undefined) {
        setEmployeeCode(res?.data?.attendance?.employeeCode);
      }
      if (props?.setShiftType != undefined) {
        setShiftType(res?.data?.attendance?.name);
      }
      // setEmployeeName(res?.data?.attendance?.name)
      // setEmployeeCode(res.data.attendance.employeeCode)
      // setShiftType(res.data.attendance.Shift_type)

      let templateMark = 0;
      list1 = res.data.data.map((item, index) => {
        templateMark += checkFirstHalfLate(item) ? 1 : 0;

        return {
          ...item,
          no: "#",
          date: moment(item.date).format("DD-MM-YYYY"),
          day: moment(item.date).format("dddd").substring(0, 3),
          shiftintime: "10:00 AM",
          shiftouttime: "07:15 PM",
          intime: item.checkIn ? moment(item.checkIn).format("hh:mm A") : " - ",
          outtime: item.checkOut
            ? moment(item.checkOut).format("hh:mm A")
            : " - ",
          tothrs: item.checkOut
            ? findTotalHours(item.checkIn, item.checkOut)
            : "-",
          firsthalf: checkHoliday(item)
            ? checkHoliday(item)
            : checkFirstHalf(item),
          secondhalf: checkHoliday(item)
            ? checkHoliday(item)
            : checkSecondHalf(item),
          portion: item.present_count,
          action: (
            <>
              <ManualRequestAttendance
                items={item}
                attendanceList={res.data.data}
                date={item.date}
                employee_id={employee_id}
              />
            </>
          ),
        };
      });
      setLateMark(templateMark);
      let result = [];

      // let approvedLeave = attendance.paidLeave
      // let approvedLeaveForDeduction = attendance.paidLeave - 1
      // approvedLeaveForDeduction =
      //   approvedLeaveForDeduction > 0 ? approvedLeaveForDeduction : 0

      // let lateAbsent = templateMark == 0 ? 0 : templateMark / 2

      // let approvedAbsent = attendance.absent - approvedLeave
      // approvedAbsent = approvedAbsent < 0 ? attendance.absent : approvedLeave
      // console.log(attendance.absent , approvedLeave , lateAbsent)

      // let unapprovedLeave = attendance.absent-lateAbsent ;
      // setUnApprovedLeave(unapprovedLeave);

      // let payableDays =
      //   attendance.workingDays -
      //   lateAbsent -
      //   approvedLeaveForDeduction -
      //   unapprovedLeave * 2

      // console.log(attendance.workingDays,lateAbsent,approvedLeaveForDeduction,unapprovedLeave)

      let item = res?.data;
      let approvedLeave = item?.leave[0] ? item?.leave[0] : 0;
      let casualLeave = item?.count?.[`${props.month}`].cl
      let sickLeave = item?.count?.[`${props.month}`].sl
      let paidLeave = casualLeave + sickLeave
      let lateMark = item.attendanceSummaryMonth.lateMark;

      let lateAbsent = lateMark == 0 ? 0 : lateMark / 2;

      let approvedAbsent = item.attendanceSummaryMonth.absent - approvedLeave;
      approvedAbsent =
        approvedAbsent < 0 ? item.attendanceSummaryMonth.absent : approvedLeave;

      let unapprovedLeave =
        item.attendanceSummaryMonth.absent - approvedLeave - lateAbsent;

      unapprovedLeave = unapprovedLeave > 0 ? unapprovedLeave : 0;
      let approvedLeaveForDeduction = approvedAbsent - (year >= 2024 ? paidLeave : 1);
      approvedLeaveForDeduction =
        approvedLeaveForDeduction > 0 ? approvedLeaveForDeduction : 0;

      let payableDays =
        item.attendanceSummaryMonth.workingDays -
        lateAbsent -
        approvedLeaveForDeduction -
        unapprovedLeave * 2;

      setWorkingDays(item.attendanceSummaryMonth.workingDays);

      setUnApprovedLeave(unapprovedLeave);
      setApprovedLeave(approvedAbsent);
      setSickLeave(sickLeave);
      setCasualLeave(casualLeave);
      setTotalPaidLeave(paidLeave);
      for (
        let m = moment(`${year}-${month}-01`);
        m.isSameOrBefore(moment()) &&
        m.isSameOrBefore(moment(`${year}-${month}-1`).endOf("month"));
        m.add(1, "days")
      ) {
        if (m.format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
          let obj = {
            no: "#",
            date: m.format("DD-MM-YYYY"),
            day: m.format("dddd").substring(0, 3),
            shiftintime: "10:00 AM",
            shiftouttime: "07:15 PM",
            intime: " - ",
            outtime: " - ",
            tothrs: "-",
            firsthalf:
              m.format("dddd").substring(0, 3) == "Sat" ||
              m.format("dddd").substring(0, 3) == "Sun" ? (
                <LeaveAttendanceWO />
              ) : (
                <LeaveAttendanceAbsent />
              ),
            secondhalf: "-",
            portion: "",
          };

          let findFromList = list1.filter((o) => {
            return o.date == m.format("DD-MM-YYYY");
          });
          if (findFromList.length > 0) {
            obj = findFromList[0];
          }

          result.push(obj);
        }
      }

      console.log(result, ":result_455");
      setAttendanceLogs(result);
      setPayableDays(payableDays);
      if (props?.setattendanceLogsEXP !== undefined) {
        setattendanceLogsEXP(result);
      }
    });
  };

  useEffect(() => {
    getDetails();
  }, [props.month, props.year]);

  useEffect(() => {
    if (props.hasOwnProperty("Setemployees")) {
      props.Setemployees(attendanceLogs);
    }
  }, [attendanceLogs]);

  return (
    <>
      {loading ? (
        <ReactLoader loading={loading}></ReactLoader>
      ) : (
        <div className="attendance-table-sec">
          <div className="color-round boxes">
            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "#092E20" }}></span>
                <p style={{ margin: "0px" }}>TWD</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {workingDays}
              </p>
            </div>
            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "blue" }}></span>
                <p style={{ margin: "0px" }}>HD</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {holiday}
              </p>
            </div>
            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "green" }}></span>
                <p style={{ margin: "0px" }}>DP</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {totalPresent}
              </p>
            </div>
            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: 'yellow' }}></span>
                <p style={{ margin: '0px' }}>SL</p>
              </div>
              <p style={{ margin: '0px' }} className="atten-point">
                {sickLeave}
              </p>
            </div>
             <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: 'yellow' }}></span>
                <p style={{ margin: '0px' }}>CL</p>
              </div>
              <p style={{ margin: '0px' }} className="atten-point">
                {casualLeave}
              </p>
            </div>

            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "yellow" }}></span>
                <p style={{ margin: "0px" }}> LEAVE APPROVED</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {approvedLeave}
              </p>
            </div>
            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "red" }}></span>
                <p style={{ margin: "0px" }}>UnApproved</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {unApprovedLeave}
              </p>
            </div>

            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "yellow" }}></span>
                <p style={{ margin: "0px" }}>Late Mark</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {lateMark} Entrie <br />
                {lateMark / 2} Days
              </p>
            </div>
            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "green" }}></span>
                <p style={{ margin: "0px" }}>Paid Leave</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {totalPaidLeave}
              </p>
            </div>
            <div className="box1">
              <div className="upper">
                <span style={{ backgroundColor: "green" }}></span>
                <p style={{ margin: "0px" }}>Payable Days</p>
              </div>
              <p style={{ margin: "0px" }} className="atten-point">
                {payableDays}
              </p>
            </div>
            {isSameDateAsFirstDayOfMonth ? (
              <>
                <div style={{ margin: "auto" }}>
                  <Button type="primary" onClick={() => handleApprove()}>
                    Approve
                  </Button>
                </div>

                <div style={{ margin: "auto", paddingRight: "611px" }}>
                  <Button type="primary" onClick={() => handleReject()}>
                    Reject
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="main-attendance-table">
            <Table
              dataSource={attendanceLogs}
              columns={columns}
              pagination={{ pageSize: 35 }}
            />
          </div>
        </div>
      )}
      <Modal
        open={visibleAttendance}
        title={
          <span style={{ fontSize: "16px", marginTop: "10px" }}>
            {`Add Your Attendace Issue.`}
          </span>
        }
        onOk={() => setAttendanaceVisible(false)}
        onCancel={() => setAttendanaceVisible(false)}
        footer={null}
      >
        {/* Start of the form of Reject */}
        <Form form={form} onFinish={onRejectAttendance} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              {/* Form Item for entering a remark */}

              <Form.Item
                label="Remark"
                name="comment"
                rules={[
                  {
                    required: true,
                    message: "Please enter your remark.",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter Your Remark."
                  autoSize={{ minRows: 6, maxRows: 6 }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Button to submit the form */}
          <div style={{ display: "flex", justifyContent: "left" }}>
            <Form.Item style={{ margin: "10px 0px 0px 0px" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
          {/* End of the form */}
        </Form>

        {/* //new */}
      </Modal>
    </>
  );
};

export default AttendanceDetails;
