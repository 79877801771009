// export default Detail;
import React, { useState, useEffect } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBTypography,
  MDBCol,
  MDBRow,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { useHistory, useParams } from "react-router";
import AxiosInstance from "../../../service/AxiosInstance";
import { toast } from "react-toastify";
import "./project.css";
// import "./sidebar.css";
import parse from "html-react-parser";
import Edit from "./Edit";
import ProjectSidebar from "./ProjectSidebar";
import { Button, Image, Spin, Tabs } from "antd";
import Permission from "../Permission";
import Comments from "../comment/Comments";
import ProjectBar from "./ProjectBar";
import EmployeeChart from "../employeeChart/EmployeeChart";
import TaskComments from "../comment/TaskComments";
import { useRef } from "react";
import WriteComment from "../comment/WriteComment";
// import { Link } from "react-router-dom";
import Link from "antd/lib/typography/Link";
import ProjectTotalHours from "./ProjectTotalHours/ProjectTotalHours";
import ProjectTeamLeave from "./ProjectTeamLeave";
import { useSelector } from "react-redux";
import CurrentWorkingTasks from "../employeeReport/CurrentWorkingTasks";
import { Svg } from "../../../assets/svg";
import ProjectHoursByWeek from "./ProjectTotalHours/ProjectHoursByWeek";
const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";

// main component
const Detail = ({ id, list }) => {
  const [List, setList] = useState(0);
  const [count, setCount] = useState(0);
  const params = useParams();
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const User = JSON.parse(localStorage.getItem("userdata"));
  localStorage.setItem("PID", params.id);
  let history = useHistory();
  const [statusUpdate, setStatusUpdate] = useState("");
  const [projectData, setProjectData] = useState({});
  const [projectMembers, setProjectMembers] = useState([]);
  const [myValue, setMyValue] = useState("");
  const previousValue = useRef();
  const [lastStatusDb, setLastStatusDb] = useState("");
  const [taskData, setTaskData] = useState({});
  const permssionsState = useSelector((state) => state.Permissions.Permissions);
  const [rolePermissions, setRolepermissions] = useState(permssionsState);
  let user_data = JSON.parse(localStorage.getItem("userdata"));
  const [isChecked, setChecked] = useState(false);
  useEffect(() => {
    setRolepermissions(permssionsState);
  }, [permssionsState])
  useEffect(() => {
    previousValue.current = lastStatusDb;
  }, [lastStatusDb]);

  const changeStatus = (value) => {
    previousValue.current = myValue;
    setMyValue(value);
    // create history
    const formData1 = new URLSearchParams();
    formData1.append("Task_id", taskData?._id);
    formData1.append(
      "Updated_by",
      userData[0]?.First_name + " " + userData[0]?.Last_name
    );
    formData1.append("Current_Status", `${value}`);
    formData1.append("Previous_Status", `${previousValue.current}`);
    AxiosInstance.post("auth/history/add", formData1).then((res) => {
      if (res.data.status == 1) {
      }
    });
  };

  // const [olddocument, Setolddocument] = useState(['']);
  // console.log(historyList, 'historyList:::::::::445::::');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //new
  useEffect(() => {
    getProjectData();
  }, []);
  const handleArchive = async () => {
    const newCheckedState = !isChecked;
    setChecked(newCheckedState);

    try {
      const formData = new FormData();
      formData.append("Archive", newCheckedState);
      formData.append("id", projectData?._id);

      const response = await AxiosInstance.post(
        "auth/projects/edit-details",
        formData
      );

      if (response.data.status === 1) {
        const successMessage = newCheckedState
          ? "Archive successfully"
          : "Unarchive successfully";
        toast.success(successMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };
  //end
  useEffect(() => {
    setList(count);
    const formData = new URLSearchParams();
    formData.append("id", params.id);
    getProjectData();
  }, [count]);

  const getProjectData = () => {
    const formData = new URLSearchParams();
    formData.append("id", params.id);

    //API Details
    AxiosInstance.post("auth/projects/detail", formData).then(async (res) => {
      if (res.data.status == 1) {
        setProjectData(res.data.data);
      }
    });
  };
  const statusChange = (st) => {
    let formData = new URLSearchParams();
    formData.append("id", projectData?._id);
    formData.append("Status", st);

    AxiosInstance.post("auth/projects/edit", formData)
      .then((res) => {
        if (res.data.status == 1) {
          console.log(res.data.data, "res.data.data:::::::::::::::");
          //setProjectData(res.data.data);
          setStatusUpdate(st);
          getProjectData();

          // create history
          const formData1 = new URLSearchParams();
          formData1.append("project_id", projectData?._id);
          formData1.append(
            "Updated_by",
            userData[0]?.First_name + " " + userData[0]?.Last_name
          );
          formData1.append("Action", `Status to ${res.data.data[0].Status}`);
          AxiosInstance.post("auth/history/add", formData1).then((res) => {
            if (res.data.status == 1) {
              console.log(res.data, "history data:::::");
            }
          });
        }
      })
      .catch((err) => console.log(err, "err"));
    // setStatusUpdate(st);
  };

  // function ImageDemo({ element, index }) {
  //   console.log(element, "eeeee");
  //   let linkarray =
  //     element !== null && element !== undefined && element
  //       ? element.split(".")
  //       : [];
  //   let exe = "jpg,jpeg,png";
  //   return (
  //     <>
  //       <div className="image">
  //         <div>
  //           <a href={element}>
  //             <Image
  //               width={80}
  //               height={80}
  //               src={
  //                 exe.includes(linkarray[linkarray.length - 1])
  //                   ? element
  //                   : "https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-76-512.png"
  //               }
  //             // preview={{
  //             //   onVisibleChange:(element)=>{<a href={element}>}
  //             // }}
  //             />
  //           </a>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
  function ImageDemo({ element, index }) {
    // let linkarray = element.split('.');
    let linkarray =
      element !== null && element !== undefined ? element?.split(".") : [];
    let exe = "jpg,jpeg,png";
    return (
      <>
        {/* <div className="image"> */}
        <div>
          <Image
            width={80}
            height={80}
            src={
              exe.includes(linkarray[linkarray.length - 1])
                ? imageUrl + element
                : "https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-76-512.png"
            }
          />
          {/* {element && <a href="#">{element}</a>} */}
        </div>
        <div>{/* <DeleteOutlined onClick={() => deleteimage(index)} /> */}</div>
        {/* </div> */}
      </>
    );
  }
  // employee list
  useEffect(() => {
    // AxiosInstance.get("auth/employees").then(async (res) => {
    //   if (res.data.status == 1) {
    //     let EmID =
    //       projectData?.Project_member &&
    //       projectData.Project_member.split(",").map((member) => {
    //         let EmID1 = res.data.data.filter((item) => item._id == member);
    //         return EmID1[0]?.First_name;
    //       });
    //     setProjectMembers(EmID);
    //   }
    // });
  }, [count, projectData]);

  return (
    <div>
      <div className="event-main">
        <ProjectBar projectId={params.id} />
        <div className="page-content">
          <MDBRow className="project-sidebar mx-0 ">
            <MDBCol size="md-12" className="col-example open-menu">
              <div>
                <div className="project-body">
                  <div className="cardbackground">
                    <div className="cardmargine">
                      <h4 className="tablHeader qa-btn create-project ">
                        {" "}
                        {projectData.Project_name
                          ? projectData.Project_name
                          : "Project"}
                        <div className="qa-btn-set">
                       {rolePermissions?.Project?.Archive === "1" ? (
                            <Button
                              className="pms-same-btn archive-btn"
                              onClick={handleArchive}
                            >
                              {isChecked ? (
                                <img src={Svg.archive} alt="Archive" />
                              ) : (
                                <img src={Svg.unarchive} alt="Unarchive" />
                              )}
                            </Button>
                       ):""}
                          {/* ) : (
                            "" */}
                          {/* )} */}
                          <MDBDropdown className="-2">
                            <MDBDropdownToggle className="status-box">
                              {projectData?.Status ? (
                                projectData?.Status
                              ) : (
                                <Spin size="large" />
                              )}
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => statusChange("Open")}
                                >
                                  Open
                                </MDBDropdownLink>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => statusChange("InProgress")}
                                >
                                  InProgress
                                </MDBDropdownLink>
                              </MDBDropdownItem>

                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => statusChange("Hold")}
                                >
                                  Hold
                                </MDBDropdownLink>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => statusChange("InClientReview")}
                                >
                                  InClientReview
                                </MDBDropdownLink>
                              </MDBDropdownItem>
                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => statusChange("Completed")}
                                >
                                  Completed
                                </MDBDropdownLink>
                              </MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>

                          <Edit
                            className="pms-same-btn"
                            projectData={projectData}
                            id={projectData._id}
                            setCount={setCount}
                            count={count}
                          />

                          <Button
                            className="ml-10 pms-same-btn-cancel"
                            onClick={() => history.goBack()}
                          >
                            <MDBIcon
                              className="pr-5"
                              fas
                              icon="long-arrow-alt-left"
                            />
                            Back
                          </Button>
                        </div>
                      </h4>

                      <MDBCard>
                        <MDBCardBody className="pro-view-tab">
                          <Tabs className="main-tab" defaultActiveKey="1">
                            <Tabs.TabPane
                              className="sub-tab"
                              tab="Project Ratio"
                              key="1"
                              placeholder="abcd"
                            >
                              <EmployeeChart />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                              className=""
                              tab="Project Details"
                              key="2"
                            >
                              {
                                <MDBTypography
                                  blockquote
                                  className="mb-0 bg-light-text-color "
                                >
                                  <MDBRow className="mx-0">
                                    <MDBCol size="3">
                                      <span className="TitleStyle">
                                        Client Name
                                      </span>
                                      <p>
                                        {projectData.Client_name
                                          ? projectData.Client_name
                                          : ""}
                                      </p>
                                    </MDBCol>

                                    <MDBCol size="3">
                                      <span className="TitleStyle">
                                        Project Manager
                                      </span>
                                      <p>
                                        {projectData.Project_manager
                                          ? projectData.Project_manager
                                          : ""}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3">
                                      <span className="TitleStyle">
                                        Technical Project Manager
                                      </span>
                                      <p>
                                        {projectData.Technical_project_manager
                                          ? projectData.Technical_project_manager
                                          : ""}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3">
                                      <span className="TitleStyle">
                                        Sales
                                      </span>
                                      <p>
                                        {projectData.Sales
                                          ? projectData.Sales
                                          : "-"}
                                      </p>
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow className="mx-0">
                                    <MDBCol size="3">
                                      <span className="TitleStyle">
                                        Requirement
                                      </span>
                                      <p>
                                        {projectData.Client_requirement
                                          ? projectData.Client_requirement.split(
                                            ","
                                          ).join(", ")
                                          : ""}
                                      </p>
                                      <p>
                                        {projectData.Tech_stack
                                          ? projectData.Tech_stack.split(
                                            ","
                                          ).join(", ")
                                          : ""}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3" className="mt-20">
                                      <span className="TitleStyle">
                                        Assigned Date
                                      </span>
                                      <p>
                                        {projectData.Project_awarded_date !==
                                          "-"
                                          ? projectData.Project_awarded_date
                                            ? JSON.stringify(
                                              new Date(
                                                projectData.Project_awarded_date
                                              )
                                            )
                                              .slice(1, 11)
                                              .split("-")
                                              .reverse()
                                              .join("-")
                                            : "-"
                                          : "-"}
                                      </p>
                                    </MDBCol>

                                    <MDBCol size="3" className="mt-20">
                                      <span className="TitleStyle">
                                        Start Date
                                      </span>
                                      <p>
                                        {projectData.Project_start_date !== "-"
                                          ? projectData.Project_start_date
                                            ? JSON.stringify(
                                              new Date(
                                                projectData.Project_start_date
                                              )
                                            )
                                              .slice(1, 11)
                                              .split("-")
                                              .reverse()
                                              .join("-")
                                            : "-"
                                          : "-"}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3" className="mt-20">
                                      <span className="TitleStyle"></span>
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow className="mx-0">
                                    <MDBCol size="12" className="mt-20">
                                      <span className="TitleStyle mb-5">
                                        Remark
                                      </span>
                                      <div
                                        className="d-flex align-items-start bg-light bg-light-box-color"
                                        style={{ fontWeight: "BoldOutlined" }}
                                      >
                                        <h6 style={{ backgroundColor: "" }}>
                                          {projectData.Remark
                                            ? projectData.Remark
                                            : ""}
                                        </h6>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow className="mx-0">
                                    <MDBCol size="12" className="mt-20">
                                      <div className="TitleStyle">
                                        Description
                                      </div>
                                      <div
                                        className="d-flex align-items-start bg-light scrollbar description bg-light-box-color "
                                        id="style-7"
                                      >
                                        <div class="force-overflow">
                                          <p className="m-0">
                                            {projectData.Project_description
                                              ? parse(
                                                projectData.Project_description
                                              )
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>

                                  <MDBRow className="mx-0">
                                    <MDBCol size="12" className="mt-20">
                                      <div className="TitleStyle">
                                        Documents
                                      </div>
                                      <div
                                        className="d-flex align-items-start bg-light scrollbar description bg-light-box-color "
                                        id="style-7"
                                      >
                                        <div className="preview-image">
                                        {projectData.Document !== undefined &&
                                          projectData.Document.map(
                                            (element, index) => (
                                              <ImageDemo
                                                element={element}
                                                index={index}
                                              />
                                            )
                                          )}
                                          </div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBTypography>
                              }
                            </Tabs.TabPane>
                            {rolePermissions?.PM_Report?.Project_Working_Hours == "1" ? (
                              <Tabs.TabPane
                                className="sub-tab"
                                tab="Project-Working-Hours"
                                key="3"
                                placeholder="abcd"
                              >
                                <ProjectTotalHours />
                              </Tabs.TabPane>
                            ) : ("")}
                            {/* New */}
                            {rolePermissions?.PM_Report?.Current_Working_Tasks == "1" ? (
                              <Tabs.TabPane
                                className="sub-tab"
                                tab="Current Working Tasks"
                                key="4"
                                placeholder="curent-working-hours"
                              >
                                <CurrentWorkingTasks />
                              </Tabs.TabPane>
                            ) : ("")}
                            {rolePermissions?.PM_Report?.Project_Working_Hours == "1" ? (
                              <Tabs.TabPane
                                className="sub-tab"
                                tab="Weekly-Project-Working-Hours"
                                key="5"
                                placeholder="project-working-hours-week"
                              >
                                <ProjectHoursByWeek />
                              </Tabs.TabPane>
                            ) : ("")}
            
                            <Tabs.TabPane
                              className="sub-tab"
                              tab="Team Leave"
                              key="6"
                              placeholder="abcd"
                            >
                              <ProjectTeamLeave />
                            </Tabs.TabPane>
                          </Tabs>

                        </MDBCardBody>
                      </MDBCard>

                    </div>
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBCard className="details-cards">
        <div className="page-container">
          <WriteComment
            User={User}
            current={myValue}
            previous={previousValue.current}
            ProjectId={params.id}
          />
        </div></MDBCard>
        </div>
      </div>
     
    </div>

  );
};

export default Detail;
