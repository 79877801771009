import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { Link,useParams } from "react-router-dom";
const EmployeeTab = (props) => {
    const params = useParams();
    return (
    <>
      <MDBNavbarNav className="page-menu-nav">
        <MDBNavbarItem>
          <Link to={`/employeeDetail/${params?.id}`} active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Basic Details
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to={`/employeeOtherDetail/${params?.id}`} active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Other Details
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </>
  );
};

export default EmployeeTab;
