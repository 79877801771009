import React, { useState, useEffect } from "react";
import { Table } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actionMyDailyReport } from "../../../redux/actions/TaskActions";
import AxiosInstance from "../../../service/AxiosInstance";
import { useParams } from "react-router";

const RequestedResource = (props) => {
  const {refresh, setRefresh} = props
  const MyDailyTaskReport = useSelector(
    (state) => state.Task?.MyDailyTaskReport
  );
  const params = useParams();
  const PID = params?.id;
  const [myReport, setMyReport] = useState(MyDailyTaskReport);
  const [myTask, setMyTask] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "No",
      dataIndex: "no",
    },
    {
      title: "Department name",
      dataIndex: "Department_Name",
    },
    {
      title: "Exp. level",
      dataIndex: "Exp_level",
    },
    {
      title: "From Date",
      dataIndex: "from_Date",
      render: (data) => {
        return <>{moment(data).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "To Date",
      dataIndex: "to_Date",
      render: (data) => {
        return <>{moment(data).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "Hours",
      dataIndex: "Hours",
      // render: (data) => {
      //   return <>{moment(data).format("h:mm")}</>;
      // },
    },
    {
      title: "Requested By",
      dataIndex: "Requested_by",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  const getDefaultReport = () => {
    const formData = new URLSearchParams()
    formData.append("Project_id", PID)
    AxiosInstance.post(`auth/resource/list`,formData).then((res) => {
      dispatch(actionMyDailyReport(res.data.data));
      setRefresh(false);
      const taskdata = [];
      var i = 1;
      res.data.data.map((data, index) => {
        var dataObj = {
          no: res?.data?.data?.length - index,
          Department_Name: data?.Department_id?.Department_name,
          Exp_level: data?.Exp_level,
          from_Date: data?.Date?.from,
          to_Date: data?.Date?.to,
          Hours: data?.Hours === 0 ? "-" : data.Hours,
          status: data.Assign.length? data.Assign[data?.Assign?.length-1]?.Status:"Pending",
          Requested_by: `${data?.Requested_by?.First_name} ${data?.Requested_by?.Middle_name} ${data?.Requested_by?.Last_name}`,
        };
        taskdata.push(dataObj);
        i++;
      });
      setMyTask(taskdata?.reverse());
    });
  };

  useEffect(() => {
    getDefaultReport();
  }, []);
  useEffect(() => {
    getDefaultReport();
  }, [refresh]);

  return (
    <>
      {" "}
      <div className="my-task-dropdown"></div>
      <Table className="team-table" columns={columns} dataSource={myTask} />
    </>
  );
};

export default RequestedResource;
