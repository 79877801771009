import React, { useEffect, useState } from "react";
import { Button, Form, Radio, Modal } from "antd";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import moment from "moment";
import { Link } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
const values = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];


const TLViewKra = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [TLname, setTLname] = useState("");
    const [q2, setQ2] = useState(null);
    const [q3, setQ3] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [datatable, setDatatable] = useState({});
    const [flag, Setflag] = useState(false);
    const [id, setId] = useState("");
    const [employees, Setemployees] = useState([]);
    const [select, setSelect] = useState({});
    const showModal = (item) => {
        setId(item?._id);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = () => {
        const formdata = new URLSearchParams();
        // formdata.append("Q1", TLname);
        formdata.append("Q1", q2);
        formdata.append("Q2", q3);
        formdata.append("id", id);
        AxiosInstance.post("/auth/kra/team-leader", formdata).then((res) => {
            setIsModalOpen(false);
        });
    };

    useEffect(() => {
        AxiosInstance.get("auth/kra/form-list").then(async (result) => {
            if (result.data.data) {
                let employeesdeta = await result.data.data.map((item) => {
                    return {
                        name: `${item.Employee_id.First_name} ${item.Employee_id.Middle_name} ${item.Employee_id.Last_name}`,
                        // name: (
                        //   <Link style={{fontWeight:'500'}}
                        //     to={{ pathname: `/employeeDetail/${item._id}` }}
                        //   >{`${item.First_name} ${item.Middle_name} ${item.Last_name}`}</Link>
                        // ),

                        // email: item.Email,
                        // dateOfBirth: moment(item.Date_of_birth).format("DD/MM/YYYY"),
                        // dateOfjoining: moment(item.Date_of_joining).format("DD/MM/YYYY"),
                        department: item.Employee_id.Role,
                        Position: item.Employee_id?.Position,
                        // mobileNo: item.Mobile,
                        // status: item.Status,
                        Employee_code: item.Employee_id.Employee_code,
                        action: (
                            <Link style={{ fontWeight: "500" }}>
                                View
                            </Link>
                        ),
                    };
                });
                Setemployees(employeesdeta);
            }
        });
    }, [flag]);
    return (
        <div className="event-main">
            <div className="page-content">
                <div
                    title="TL/HOD KRA Form:"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="tl-kra-modal"
                >
                    <div>
                        <div className="questions">
                            <h5 >Employee name : <span style={{ fontSize: "15px", marginLeft: "5px" }}>Meet Chotaliya</span></h5>
                            <hr style={{ margin: "0", marginBottom: '5px' }} />
                            <MDBCard>
                                <Form
                                    form={form}
                                    name="control-hooks"
                                    layout="vertical"
                                    //   onFinish={onFinish}
                                    //   onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    style={{ padding: "0 10px" }}
                                >
                                    <div className="kra-col">
                                        <div className="kra-field">
                                            <h6 className="kra-modal-space">
                                                {" "}
                                                1.How well you performed your task in{" "}
                                                {moment().subtract(1, "month").format("MMM")}{" "}
                                                {moment().format("YYYY")} ?
                                            </h6>
                                            <Form.Item>
                                                <Radio.Group
                                                    value={
                                                        Object.keys(select).length > 0
                                                            ? `${select?.Q1}`
                                                            : ""
                                                    }
                                                >
                                                    {values.map((item, index) => {
                                                        return <Radio value={item}>{item}</Radio>;
                                                    })}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="kra-field">
                                            <h6 className="kra-modal-space">
                                                5.Rate yourself on overall performance from 1 to 10?
                                            </h6>
                                            <Form.Item >
                                                <Radio.Group
                                                    value={
                                                        Object.keys(select).length > 0
                                                            ? `${select?.Q5}`
                                                            : ""
                                                    }
                                                >
                                                    {values.map((item, index) => {
                                                        return <Radio value={item}>{item}</Radio>;
                                                    })}
                                                </Radio.Group>

                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div className="kra-col">
                                        <div className="kra-field">
                                            <h6 className="kra-modal-space">2.How capable were you to meet your deadlines?</h6>
                                            <Form.Item >
                                                <Radio.Group
                                                    value={
                                                        Object.keys(select).length > 0
                                                            ? `${select?.Q2}`
                                                            : ""
                                                    }
                                                >
                                                    {values.map((item, index) => {
                                                        return <Radio value={item}>{item}</Radio>;
                                                    })}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="kra-field">
                                            <h6 className="kra-modal-space">6.Rate his or her overall performance?</h6>
                                            <Form.Item>
                                                <Radio.Group>
                                                    {values.map((item, index) => {
                                                        return (
                                                            <Radio
                                                                value={values[index]}
                                                                onChange={(e) => setQ2(e.target.value)}
                                                            >
                                                                {values[index]}
                                                            </Radio>
                                                        );
                                                    })}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div className="kra-col">
                                        <div className="kra-field">
                                            <h6 className="kra-modal-space">3.Rate yourself as a team player from 1 to 10</h6>
                                            <Form.Item >
                                                <Radio.Group
                                                    value={
                                                        Object.keys(select).length > 0
                                                            ? `${select?.Q3}`
                                                            : ""
                                                    }
                                                >
                                                    {values.map((item, index) => {
                                                        return <Radio value={item}>{item}</Radio>;
                                                    })}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                        <div className="kra-field">
                                            <h6 className="kra-modal-space">7.Rate his or her behavior?</h6>
                                            <Form.Item>
                                                <Radio.Group>
                                                    {values.map((item, index) => {
                                                        return (
                                                            <Radio
                                                                value={values[index]}
                                                                onChange={(e) => setQ3(e.target.value)}
                                                            >
                                                                {values[index]}
                                                            </Radio>
                                                        );
                                                    })}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>

                                    </div>
                                    <div className="kra-col">
                                        <div className="kra-field">
                                            <h6 className="kra-modal-space">4.Rate yourself in punctuality from 1 to 10?</h6>
                                            <Form.Item>
                                                <Radio.Group
                                                    value={
                                                        Object.keys(select).length > 0
                                                            ? `${select?.Q4}`
                                                            : ""
                                                    }
                                                >
                                                    {values.map((item, index) => {
                                                        return <Radio value={item}>{item}</Radio>;
                                                    })}
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>

                                    </div>

                                </Form>
                                <Form.Item>
                                    <div style={{ textAlign: "right", margin: "10px 15px" }}>
                                        <Button
                                            className="pms-same-btn"
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                            onClick={onFinish}
                                            style={{ marginRight: "10px" }}
                                        >
                                            Submit
                                        </Button>
                                        <Button onClick={handleCancel} className="pms-same-btn-cancel">Cancel</Button>
                                    </div>
                                </Form.Item>
                            </MDBCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TLViewKra