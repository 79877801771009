import React, { useEffect, useState } from "react";
import { Button, Form, Radio, Modal } from "antd";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import moment from "moment";
import { Link } from "react-router-dom";
import AxiosInstance from "../../../service/AxiosInstance";
const TLKraForm = () => {

const values = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [q4, setQ4] = useState(null);
  const [q5, setQ5] = useState(null);

  const [error, setError] = useState(false);
  const [errorq1, setErrorQ1] = useState(false);
  const [errorq2, setErrorQ2] = useState(false);
  const [errorq3, setErrorQ3] = useState(false);
  const [errorq4, setErrorQ4] = useState(false);
  const [errorq5, setErrorQ5] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datatable, setDatatable] = useState({});
  const [flag, Setflag] = useState(false);
  const [id, setId] = useState("");
  const [employees, Setemployees] = useState([]);

  const showModal = (item) => {
    setId(item?._id);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = () => {
    setLoading(true);
    if (
      q1 !== null &&
      q2 !== null &&
      q3 !== null &&
      q4 !== null &&
      q5 !== null
    ) {
      setLoading(true);
      setError("");
      const formdata = new URLSearchParams();
      // formdata.append("Q1", TLname);
      formdata.append("Q1", q1);
      formdata.append("Q2", q2);
      formdata.append("Q3", q3);
      formdata.append("Q4", q4);
      formdata.append("Q5", q5);
      formdata.append("id", id);
      AxiosInstance.post("/auth/kra/team-leader", formdata).then((res) => {
        setLoading(false);
        setIsModalOpen(false);
        Setflag(true)
        setQ1(null)
        setQ2(null)
        setQ3(null)
        setQ4(null)
        setQ5(null)
      });
    } else {
      if (q1 === null) {
        setErrorQ1("Please add rating for Q1*");
      }
      if (q2 === null) {
        setErrorQ2("Please add rating for Q2*");
      }
      if (q3 === null) {
        setErrorQ3("Please add rating for Q3*");
      }
      if (q4 === null) {
        setErrorQ4("Please add rating for Q4*");
      }
      if (q5 === null) {
        setErrorQ5("Please add rating for Q5*");
      }
    }

    getKRAList();
  };
  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Code",
          field: "Employee_code",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Department",
          field: "department",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "Position",
          field: "Position",
          // sort: 'asc',
          width: 200,
        },

        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: employees,
    });
  }, [flag, employees]);

  const [select, setSelect] = useState({});
  const onFinishFailed = (errorInfo) => {
    if (q1 === null) {
      setErrorQ1("Please add rating for Q1*");
    }
    if (q2 === null) {
      setErrorQ2("Please add rating for Q2*");
    }
    if (q3 === null) {
      setErrorQ3("Please add rating for Q3*");
    }

    if (q4 === null) {
      setErrorQ4("Please add rating for Q4*");
    }
    if (q5 === null) {
      setErrorQ5("Please add rating for Q5*");
    }
  };

  const getKRAList = () => {
    AxiosInstance.get("auth/kra/form-list").then(async (result) => {
      if (result.data.data) {
        let employeesdata = await result.data.data.map((item) => {
          const currentMonth = new Date().getMonth()+1;
          let previousMonth = moment(item?.Date).format("M")
          if(currentMonth==previousMonth){
            return {
              name: `${item.Employee_id.First_name} ${item.Employee_id.Middle_name} ${item.Employee_id.Last_name}`,
              department: item.Employee_id.Role,
              Position: item.Employee_id?.Position,
              Employee_code: item.Employee_id.Employee_code,
              action: (
                <>
                  {!item.Rating_status ? (
                    <>
                      <Link
                        style={{ fontWeight: "500" }}
                        onClick={() => {
                          showModal(item);
                        }}
                      >
                        Rate Employee KRA
                      </Link>
                    </>
                  ) : (
                    <Link style={{ fontWeight: "500" }}>Submitted</Link>
                  )}
                </>
              ),
            };
          }
        });
        Setemployees(employeesdata);
      }
    });
  }
  useEffect(() => {
    getKRAList()
  }, [flag]);



  return (
    <>
      <div className="">
        <div className="demo">
          <div>
            {/* <KraBAr /> */}
            <div className="page-container">
              <div className="mx-0 create-project">
                <MDBCol size="md" className="col-example">
                  <h3 className="tablHeader">TL/HOD KRA</h3>
                </MDBCol>

                <MDBCol size="md" className="col-example float-right">
                  <span className="btn-right right-emp-btn">
                    <Modal
                      title="TL/HOD KRA Form:"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      className="tl-kra-modal"
                    >
                      <div>
                        <div className="questions">
                          <Form
                            form={form}
                            name="control-hooks"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                          >
                            <h6 className="kra-modal-space">
                              {" "}
                              1.How well was His/her performance for all tasks
                              in {moment()
                                .subtract(1, "month")
                                .format("MMM")}{" "}
                              {moment().format("YYYY")} ?
                            </h6>
                            <Form.Item>
                              <Radio.Group
                                onChange={(e) => {
                                  setErrorQ1(false);
                                  setQ1(e.target.value);
                                }}
                                value={q1}
                              >
                                {values.map((item, index) => {

                                  return (
                                    <Radio
                                      value={values[index]}
                                    >
                                      {values[index]}
                                    </Radio>
                                  );
                                })}
                              </Radio.Group>
                              <span style={{ color: "red" }}>{errorq1}</span>
                            </Form.Item>

                            <h6 className="kra-modal-space">
                              2.How much was He/She capable to meet the
                              deadlines?
                            </h6>
                            <Form.Item>
                              <Radio.Group 
                               onChange={(e) => {
                                setErrorQ2(false);
                                setQ2(e.target.value);
                              }}
                              value={q2}
                              >
                                {values.map((item, index) => {
                                  return (
                                    <Radio
                                      value={values[index]}
                                     
                                    >
                                      {values[index]}
                                    </Radio>
                                  );
                                })}
                              </Radio.Group>
                              <span style={{ color: "red" }}>{errorq2}</span>
                            </Form.Item>

                            <h6 className="kra-modal-space">
                              3.Rate Him/her as a team player from scale of 1 to
                              10 based on your observation.
                            </h6>
                            <Form.Item>
                              <Radio.Group 
                              onChange={(e) => {
                                        setErrorQ3(false);
                                        setQ3(e.target.value);
                                      }}
                                      value={q3}
                              >
                                {values.map((item, index) => {
                                  return (
                                    <Radio
                                      value={values[index]}
                                     
                                    >
                                      {values[index]}
                                    </Radio>
                                  );
                                })}
                              </Radio.Group>
                              <span style={{ color: "red" }}>{errorq3}</span>
                            </Form.Item>

                            <h6 className="kra-modal-space">
                              4.Rate Him/Her on overall performance on a scale
                              of 1 to 10.
                            </h6>
                            <Form.Item>
                              <Radio.Group 
                               onChange={(e) => {
                                setErrorQ4(false);
                                setQ4(e.target.value);
                              }}
                              value={q4}>
                                {values.map((item, index) => {
                                  return (
                                    <Radio
                                      value={values[index]}
                                      
                                    >
                                      {values[index]}
                                    </Radio>
                                  );
                                })}
                              </Radio.Group>
                              <span style={{ color: "red" }}>{errorq4}</span>
                            </Form.Item>

                            <h6 className="kra-modal-space">
                              5.Rate His/Her behavior on a scale of 1 to 10.
                            </h6>
                            <Form.Item>
                              <Radio.Group
                               onChange={(e) => {
                                setErrorQ5(false);
                                setQ5(e.target.value);
                              }}
                               value={q5}>
                                {values.map((item, index) => {
                                  return (
                                    <Radio
                                      value={values[index]}
                                     
                                    >
                                      {values[index]}
                                    </Radio>
                                  );
                                })}
                              </Radio.Group>
                              <span style={{ color: "red" }}>{errorq5}</span>
                            </Form.Item>
                          </Form>

                          <Form.Item>

                            <div
                              style={{
                                textAlign: "right",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                className="pms-same-btn"
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                onClick={onFinish}
                                style={{ marginRight: "10px" }}
                              >
                                Submit
                              </Button>
                              <Button
                                onClick={handleCancel}
                                className="pms-same-btn-cancel"
                              >
                                Cancel
                              </Button>
                            </div>

                          </Form.Item>
                        </div>
                      </div>
                    </Modal>
                  </span>
                </MDBCol>
              </div>
            </div>

            <div className="tasklisttbl page-container tlkra-form">
              <MDBDataTableV5
                hover
                entriesOptions={[20, 25, 30]}
                entries={20}
                pagesAmount={4}
                data={datatable}
                searchTop
                searchBottom={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TLKraForm;
