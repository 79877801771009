import React from "react";

import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { Link, useLocation } from "react-router-dom";

interface DataType {
  key: string;
  name: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "For any IT (System and Mobile) Software/Hardware",
    dataIndex: "For any IT (System and Mobile) Software/Hardware",
    key: "For any IT (System and Mobile) Software/Hardware",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
];

const data: DataType[] = [
  {
    key: "1",
    name: "For Operations  (Infrastructure / House Keeping /Facilities ) related Query ",
    age: "For Operations  (Infrastructure / House Keeping /Facilities ) related Query",
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
  },
];

function ContactBar() {
  const location = useLocation();
  return (
    <>
      <MDBNavbarNav className="navbar-nav w-100 page-menu-nav">
        <MDBNavbarItem>
          <Link to={`/vh-policy`}>
            {" "}
            <MDBNavbarLink
              className={location.pathname === `/vh-policy` && "active"}
              aria-current="page"
              href="#"
            >
              Contact Us
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to={`/Hr-handbook`}>
            {" "}
            <MDBNavbarLink
              aria-current="page"
              href="#"
              className={location.pathname === `/Hr-handbook` && "active"}
            >
             HR Handbook
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to={`/holidays-Calendar`}>
            {" "}
            <MDBNavbarLink
              aria-current="page"
              href="#"
              className={location.pathname === `/holidays-Calendar` && "active"}
            >
            Holidays Calendar
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
      </MDBNavbarNav>

      {/* <h1>Contact us component</h1>
        <Table columns={columns} dataSource={data} className="leave-tbl" /> */}
    </>
  );
}

export default ContactBar;
