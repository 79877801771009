import React, { useEffect, useState } from 'react'
import { MDBDataTableV5 } from 'mdbreact'
import { MDBNavbarNav } from 'mdb-react-ui-kit'
import Attendancebar from './Attendancebar'

import axiosInstance from '../../../service/AxiosInstance'
import { Button, Drawer, Select, Col, Row, Space, Form } from 'antd'
import moment from 'moment'
import AttendanceDetails from './AttendanceDetails'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { ExportOutlined, DownloadOutlined } from '@ant-design/icons'
import 'jspdf-autotable'
import jsPDF from 'jspdf'
import ReactLoader from '../../../components/common/ReactLoader'
import { useLocation } from 'react-router-dom'

export default function AttendanceSummary() {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'


  const location = useLocation();
  let currMonth = moment(new Date()).month() + 1
  let currYear = moment(new Date()).year()

  const [employees, Setemployees] = useState([])
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(moment())
  const [datatable, setDatatable] = useState({})
  const [attendanceOption, setAttendanceOption] = useState('ALL')
  const [visible, setVisible] = useState(false)
  const [attendanceLogsEXP, setattendanceLogsEXP] = useState([])
  const [year, setYear] = useState(currYear)
  const [months, setMonths] = useState(
    currMonth < 10 ? `0${currMonth}` : currMonth,
  )
  const [selectedEmployeeID, setSelectedEmployeeID] = useState(12)
  const [employeeName, setEmployeeName] = useState('')
  const [employeeCode, setEmployeeCode] = useState('')
  const [shiftType, setShiftType] = useState('')

  const [form] = Form.useForm()

  const monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const monthName = monthArray[parseInt(months, 10) - 1] // get the name of the month


  const fileName = employeeName + '_Attendance_Report'
  const onFinish = (values) => {
    setMonths(values?.month)
    setYear(values?.year)
    getDetails(values?.month, values?.year)
  }

  useEffect(() => {
    if(location?.state != undefined){
      setYear(location?.state?.selectedDateYear)
      setMonths(location?.state?.selectedDateMonth < 10 ? `0${location?.state?.selectedDateMonth}` : location?.state?.selectedDateMonth)
      showDetails(location?.state?.empId)
      setEmployeeName(location?.state?.empName)
      setEmployeeCode(location?.state?.empCode)
    }
  },[location?.state])

  const modifyData = (data) => {
    let text
    switch (data) {
      case 'EVERY_SAT_SUN_OFF':
        return (text = 'Every Sat/Sun Off')

      case '4_SAT_OFF':
        return (text = '4th Sat off')

      case '2_&_4_SAT_OFF':
        return (text = '2nd & 4th Sat Off')

      default:
        return
    }
    console.log(text, 'text')
  }

  const checkData = (data) => {
    return data ? data : '-'
  }

  const attendanceEXP = (data) => {
    setattendanceLogsEXP(data)
  }

  const setEmpName = (data) => {
    setEmployeeName(data)
  }

  const setEmpCode = (data) => {
    setEmployeeCode(data)
  }

  const setEmpShiftType = (data) => {
    setShiftType(data)
  }

  const exportPDF = () => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)
    const title = ` ${monthName}` + ' ' + `${year} Attendance Report `

    const headers = [
      [
        'Name',
        'Shift',
        'Location',
        'Total Days',
        'Holiday',
        'Absent',
        'Leave Approved',
        'Present',
      ],
    ]

    const data = employees
      .filter((e) => {
        if (attendanceOption == 'ABSENT') {
          return e.faceinOut == '' || e.faceinOut == undefined
        }
        if (attendanceOption == 'PRESENT') {
          return e.faceinOut !== undefined && e.faceinOut !== ''
        }
        return true
      })
      .map((elt) => {
        return [
          checkData(elt.name),
          modifyData(elt.shiftname),
          elt.Location,
          checkData(elt.workingDays),
          checkData(elt.holiday),
          checkData(elt?.absent),
          checkData(elt?.leave),
          checkData(elt.present),
        ]
      })

    let content = {
      startY: 50,
      head: headers,
      body: data,
    }

    doc.setFont('helvetica', 'bold')
    doc.setFontSize(16)

    doc.text(title, 300, 30, {
      align: 'center',
    })

    doc.autoTable(content)
    doc.save('EmployeeAttendance.pdf')
  }

  const exportPDF2 = () => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const marginLeft = 40

    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(10)
    const title = ` ${monthName}` + ' ' + `${year} Attendance Report `

    const Ename = `Employee Name : ${employeeName}`
    const Ecode = `Employee Code : ${employeeCode}`
    const employeeShiftType = `Shift Type : ${modifyData(shiftType)}`
    const headers = [
      [
        'Date',
        'Day',
        'Shift',
        'First Half',
        'Second Half',
        'Portion',
        'Tot.Hrs',
      ],
    ]
    const data = attendanceLogsEXP.map((elt) => {
      return [
        elt.date,
        elt.day,
        '10:00 AM - 7:15 PM',
        elt.checkIn ? moment(elt.checkIn).format('LT') : '-',
        elt?.checkOut ? moment(elt?.checkOut).format('LT') : '-',
        elt.portion,
        elt.tothrs,
      ]
    })

    let content = {
      startY: 100,
      head: headers,
      body: data,
    }

    // set Font style only for Title
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(16)

    doc.text(title, 300, 30, {
      align: 'center',
    })

    // set Font style only for doc text

    doc.setFont('helvetica', 'normal')
    doc.setFontSize(11)

    doc.text(Ename, marginLeft, 60)
    doc.text(Ecode, marginLeft, 75)
    doc.text(employeeShiftType, marginLeft, 90)
    doc.autoTable(content)
    doc.save(`${fileName}.pdf`)
  }

  useEffect(() => {
    let showResult = employees.filter((e) => {
      if (attendanceOption == 'ABSENT') {
        return e.faceinOut == '' || e.faceinOut == undefined
      }
      if (attendanceOption == 'PRESENT') {
        return e.faceinOut !== undefined && e.faceinOut !== ''
      }
      return true
    })

    setDatatable({
      columns: [
        {
          label: 'Name',
          field: 'name',
          width: 150,
          sort: 'disabled',
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
          },
        },

        {
          label: 'Shift Name',
          field: 'shiftname',
        },
        {
          label: 'Location',
          field: 'Location',
        },
        {
          label: 'Working Days',
          field: 'workingDays',
        },
        {
          label: 'Holiday',
          field: 'holiday',
        },
        {
          label: 'Present',
          field: 'present',
        },
        {
          label: 'Absent',
          field: 'absent',
        },
        {
          label: 'Leave Approved',
          field: 'leave',
        },

        {
          label: 'Leave UnApproved',
          field: 'unapprovedLeave',
        },
        {
          label: 'Casual Leave',
          field: 'casualLeave',
        },
        {
          label: 'Sick Leave',
          field: 'sickLeave',
        },
        {
          label: 'Paid leave',
          field: 'paidLeave',
        },
        {
          label: 'UnPaid leave',
          field: 'unpaidLeave',
        },     
        {
          label: 'Late Mark',
          field: 'late',
        },
        {
          label: 'Payable',
          field: 'payableDays',
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: showResult,
    })
  }, [employees, attendanceOption])

  const showDetails = (data) => {
    setSelectedEmployeeID(data)
    showDrawer()
  }

  useEffect(() => {
    getDetails(currMonth < 10 ? `0${currMonth}` : currMonth, currYear)
  }, [])

  const getDetails = (m, y) => {
    setLoading(true)

    const urlencoded = new URLSearchParams()
    urlencoded.append('month', m)
    urlencoded.append('year', y)

    axiosInstance
      .post(
        'auth/attendance/summarybymonth?date=' + date.format('YYYY-MM-DD'),
        urlencoded,
      )
      .then(async (result) => {
        setLoading(false)
        if (result.data.data) {
          let employeesdeta = await result.data.data.map((item) => {
            let approvedLeave = item?.leave[0] ? item?.leave[0] : 0
            let casualLeave = item?.count?.[`${m}`].cl
            let sickLeave = item?.count?.[`${m}`].sl
            let paidLeave = casualLeave + sickLeave
            let lateMark = item.attendance.lateMark
            let lateAbsent = lateMark == 0 ? 0 : lateMark / 2

            let approvedAbsent = item.attendance.absent - approvedLeave
            approvedAbsent =
              approvedAbsent < 0 ? item.attendance.absent : approvedLeave

            let unapprovedLeave =
              item.attendance.absent - approvedLeave - lateAbsent

            unapprovedLeave = unapprovedLeave > 0 ? unapprovedLeave : 0
            let approvedLeaveForDeduction = approvedAbsent - (year >=2024 ? paidLeave : 1)
            approvedLeaveForDeduction =
              approvedLeaveForDeduction > 0 ? approvedLeaveForDeduction : 0
            
            let payableDays =
              item.attendance.workingDays -
              lateAbsent -
              approvedLeaveForDeduction -
              unapprovedLeave * 2
            let unpaidLeave = approvedLeave - paidLeave

            return {
              name: `${item.employee.First_name} ${item.employee.Middle_name} ${item.employee.Last_name}  (${item.employee.Employee_code})`,

              workingdays: '',
              present: item.attendance.present,
              absent: item.attendance.absent,
              workingDays: item.attendance.workingDays,
              shiftname: item.employee.Shift_type,
              Location: item?.employee?.Location,
              leave: item?.leave[0] ? item?.leave[0] : 0,
              holiday: item?.holiday,
              late: item.attendance.lateMark,
              unapprovedLeave: unapprovedLeave,
              payableDays: payableDays,
              casualLeave: casualLeave,
              sickLeave: sickLeave,
              paidLeave: paidLeave,
              unpaidLeave: unpaidLeave,
              action: (
                <>
                  <Button
                    className="pms-same-btn"
                    style={{ fontWeight: '500' }}
                    onClick={() => {
                      showDetails(item?.employee?._id)
                      setEmployeeName(`${item?.employee?.First_name} ${item?.employee?.Middle_name} ${item?.employee?.Last_name}`);
                      setEmpCode(item?.employee?.Employee_code)
                    }}
                  >
                    View
                  </Button>
                </>
              ),
            }
          })
          Setemployees(employeesdeta)
          console.log(employeesdeta, 'itemKRISHNA')
        }
      })
  }

  const handleChanges = (e) => {
    setDate(e)
    console.log(e.format('YYYY-MM-DD'))
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
  const checkAction = () => {
    exportAll(employees, 'Attendance-Summary')
  }

  const checkAction2 = () => {
    exportAll(attendanceLogsEXP, fileName)
  }

  const exportAll = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    delete ws['H1']
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  const MonthArr = [
    { month: 'January', value: '01' },
    { month: 'February', value: '02' },
    { month: 'March', value: '03' },
    { month: 'April', value: '04' },
    { month: 'May', value: '05' },
    { month: 'June', value: '06' },
    { month: 'July', value: '07' },
    { month: 'August', value: '08' },
    { month: 'September', value: '09' },
    { month: 'October', value: '10' },
    { month: 'November', value: '11' },
    { month: 'December', value: '12' },
  ]

  return (
    <>
      <div className="demo">
        <MDBNavbarNav>
          <div>
            <Attendancebar />
          </div>

          <div className="tasklisttbl page-content">
            <div style={{ height: '82vh' }}>
              <div className="attendance-year create-project">
                <p>For The Period</p>
                <div className="export-btn-style">
                  <div>
                    <Space direction="horizontal">
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                      >
                        <Row>
                          <Col style={{ paddingRight: '12px' }}>
                            <Form.Item
                              // label="Select month"
                              name="month"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Select Month',
                                },
                              ]}
                              // initialValue={
                              //   currMonth < 10 ? `0${currMonth}` : currMonth
                              // }
                            //new
                            initialValue={
                              monthName
                            }
                            >
                              <Select
                                showSearch="true"
                                placeholder="Select month"
                              >
                                {MonthArr.map((row) => {
                                  return (
                                    <>
                                      <Select.Option value={row.value}>
                                        {row.month}
                                      </Select.Option>
                                    </>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col style={{ paddingRight: '12px' }}>
                            <Form.Item
                              // label="Select Year"
                              name="year"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Select Year',
                                },
                              ]}
                              initialValue={currYear ? currYear : ''}
                            >
                              <Select
                                showSearch="true"
                                placeholder="Select year"
                              >
                                <Select.Option value="2022">2022</Select.Option>
                                <Select.Option value="2023">2023</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col>
                            <Form.Item>
                              <Button
                                className="pms-same-btn"
                                htmlType="submit"
                              >
                                Refresh
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Space>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="ant-btn export-btn pms-same-btn pdf-btn-main "
                      onClick={() => checkAction()}
                    >
                      Download CSV Export
                      <DownloadOutlined className="download-pdf" />
                    </button>
                    <button
                      type="button"
                      className="ant-btn export-btn pms-same-btn pdf-btn-main "
                      onClick={() => exportPDF()}
                    >
                      Download PDF <DownloadOutlined className="download-pdf" />
                    </button>
                  </div>
                  <button
                    type="button"
                    className="ant-btn export-btn pms-same-btn pdf-btn-main"
                    onClick={() => exportPDF()}
                  >
                    PDF
                    <DownloadOutlined className="download-pdf" />
                  </button>
                </div>
              </div>
              {loading ? (
                <ReactLoader loading={loading} />
              ) : (
                <MDBDataTableV5
                  hover
                  entriesOptions={[20, 25, 30]}
                  entries={20}
                  pagesAmount={4}
                  data={datatable}
                  searchTop
                  searchBottom={false}
                />
              )}
            </div>
          </div>
        </MDBNavbarNav>
        <Drawer
          title={`Attendance logs : ${employeeName} (${employeeCode})`}
          placement="bottom"
          height={600}
          onClose={onClose}
          visible={visible}
        >
          <div>
            {/* <button
            type="button"
            className="ant-btn export-btn pms-same-btn pdf-btn-main "
            onClick={() => checkAction2()}
           >
             Download CSV Export<DownloadOutlined className="download-pdf" />
          </button> */}

            <button
              type="button"
              className="ant-btn export-btn pms-same-btn pdf-btn-main "
              onClick={() => exportPDF2()}
            >
              Download PDF
              <DownloadOutlined className="download-pdf" />
            </button>
          </div>
          {visible && (
            <AttendanceDetails
              year={year}
              month={months}
              employee_id={selectedEmployeeID}
              setattendanceLogsEXP={attendanceEXP}
              setEmployeeName={setEmpName}
              setEmployeeCode={setEmpCode}
              setShiftType={setEmpShiftType}
            />
          )}
        </Drawer>
      </div>
    </>
  )
}

