import { DatePicker } from 'antd'
import { MDBCard, MDBCol } from 'mdb-react-ui-kit'
import { MDBDataTableV5 } from 'mdbreact'
import moment, { duration } from 'moment'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AxiosInstance from '../../../service/AxiosInstance'
// import MeBar from '../../me/MeBar';

const ProjectWorkingHours = () => {
  let userData = JSON.parse(localStorage.getItem('userdata'))
  const [ProjectData, setProjectData] = useState([])
  const params = useParams()
  const [datatable, setDatatable] = useState({})
  const [departmentDatatable, setDepartmentDatatable] = useState({})
  
  const [empId, setEmpId] = useState([])
  const [ProjectWH, setProjectWH] = useState([])
  const [value, setValue] = useState('')
  const [projectId, setProjectId] = useState(params.id)
  const [EmpID, setEmpID] = useState()
  const [TotalHours, setTotalHours] = useState()
  const [sumMinutes, setsumMinutes] = useState()
  const [sumHoras, setsumHoras] = useState()
  const [Dateselctor, setDateselctor] = useState()
  useEffect(() => {
    ProjectDATA()
  }, [empId])

  const ProjectDATA = (e) => {
    const formData = new URLSearchParams()
    if (e) {
      formData.append('filterDate', e.format('YYYY-MM-DD'))
    }

    AxiosInstance.post(`auth/projects-current-timer`, formData).then(
      async (res) => {
        setProjectWH(res.data.data)
      },
    )
  }
  // const TotalWokingHours = () => {
  //   const employeename = [];
  //   ProjectWH.forEach(item => {
  //     const obj = employeename.find(o => o.name == item.Employee_id.First_name);
  //     if (item._id == item.Project_id) {
  //         var duration = moment.duration(moment(item?.Check_Out).diff(moment(item.Check_In)));
  //         console.log(duration, 'duration');
  //         var seconds = duration.asSeconds();

  //         let minutes = Math.floor(seconds) % 3600
  //         let durationTime = Math.floor(seconds / (3600)) + 'h ' + Math.floor(minutes / (60)) + 'm '
  //           + Math.floor((seconds) % (60)) + 's'

  //         setValue(durationTime)
  //       }
  //       var seconds = duration.asSeconds();

  //       let minutes = Math.floor(seconds) % 3600
  //       employeename.push({ name: item.Employee_id.First_name, h: Math.floor(seconds / (3600)), m: Math.floor(minutes / (60)), s: Math.floor((seconds) % (60)), duration: duration, TotalHours: (Math.floor(seconds / (3600)) + 'h ' + Math.floor(minutes / (60)) + 'm ' + Math.floor((seconds) % (60)) + 's ') })
  //       // setTotalHours{}
  //       console.log(item.Project_id,8888);
  //      }

  //       )
  // };

  const ProjectmodifiedDATA = () => {
    const employeename = []
    let totalHr = 0
    let totalhrss = 0

    ProjectWH.forEach((item) => {
      // if(item?.Check_Out && item?.Check_In ){
      const obj = employeename.find((o) => o.project_id == item.Project_id._id)
      if (obj) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In)),
        )
        var updatedduration = obj.duration.add(duration)
        var seconds = updatedduration.asSeconds()
        totalhrss += duration.asSeconds()
        obj.duration = updatedduration
        obj.durationinSec += duration.asSeconds()

        obj.TotalHours =
          Math.floor(obj.durationinSec / 3600) +
          'h ' +
          Math.floor((obj.durationinSec % 3600) / 60) +
          'm ' +
          Math.floor(obj.durationinSec / 3600) +
          's '
      } else {
        // if (projectId == item.Project_id) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In)),
        )
        var seconds = duration.asSeconds()
        totalhrss += duration.asSeconds()

        let dataObj = {
          project_id: item?.Project_id?._id,
          project_name: item?.Project_id?.Project_name,
          duration: duration,
          Date: moment(item?.Check_In).format('DD/MM/YYYY'),
          durationinSec: seconds,
          TotalHours:
            Math.floor(seconds / 3600) + 'h ' + Math.floor(seconds / 60) + 'm ',
        }
        if (totalHr) {
          totalHr = totalHr.add(duration)
        } else {
          totalHr = duration
        }
        employeename.push(dataObj)
      }
    })
    setTotalHours(Math.floor(totalhrss / 3600) + 'h')

    setDatatable({
      columns: [
        {
          label: 'Project Name',
          field: 'project_name',
          width: 50,
          sort: 'disabled',
        },
        {
          label: 'Date',
          field: 'Date',
          width: 50,
          sort: 'disabled',
        },
        {
          label: 'Total Hours',
          field: 'TotalHours',
          width: 50,
          sort: 'disabled',
        },
      ],
      rows: employeename,
    })
  }

  
  const DepartmentWiseDATA = () => {
    const departmentdata = []
    let totalHr = 0
    let totalhrss = 0

    ProjectWH.forEach((item) => {
      // if(item?.Check_Out && item?.Check_In ){
      const obj = departmentdata.find((o) => o.Role == item.Employee_id?.Role)
      if (obj) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In)),
        )
        var updatedduration = obj.duration.add(duration)
        var seconds = updatedduration.asSeconds()
        totalhrss += duration.asSeconds()
        obj.duration = updatedduration
        obj.durationinSec += duration.asSeconds()

        obj.TotalHours =
          Math.floor(obj.durationinSec / 3600) +
          'h ' +
          Math.floor((obj.durationinSec % 3600) / 60) +
          'm ' +
          Math.floor(obj.durationinSec / 3600) +
          's '
      } else {
        // if (projectId == item.Project_id) {
        var duration = moment.duration(
          moment(item?.Check_Out).diff(moment(item.Check_In)),
        )
        var seconds = duration.asSeconds()
        totalhrss += duration.asSeconds()

        let dataObj = {
          project_id: item?.Project_id?._id,
          project_name: item?.Project_id?.Project_name,
          Role: item?.Employee_id?.Role,
          
          duration: duration,
          Date: moment(item?.Check_In).format('DD/MM/YYYY'),
          durationinSec: seconds,
          TotalHours:
            Math.floor(seconds / 3600) + 'h ' + Math.floor(seconds / 60) + 'm ',
        }
        if (totalHr) {
          totalHr = totalHr.add(duration)
        } else {
          totalHr = duration
        }
        departmentdata.push(dataObj)
      }
    })
    setDepartmentDatatable({
      columns: [
        {
          label: 'Department',
          field: 'Role',
          width: 50,
          sort: 'disabled',
        },
        {
          label: 'Date',
          field: 'Date',
          width: 50,
          sort: 'disabled',
        },
        {
          label: 'Total Hours',
          field: 'TotalHours',
          width: 50,
          sort: 'disabled',
        },
      ],
      rows: departmentdata,
    })
  }

  useEffect(() => {
    ProjectmodifiedDATA();
    DepartmentWiseDATA();
  }, [ProjectWH])



  return (
    <>
      <div className="">
        <div className="demo">
          <MDBCard>
         
            <div className="page-container">
              <div className="mx-0 create-project">
                <MDBCol
                  // style={{ marginLeft: '7px' }}
                  size="md"
                  className="col-example"
                >
                  <h3 className="tablHeader">
                    {/* <h3 style={{ color: 'rgb(1, 63, 179)', marginTop: '-7px' }}> */}
                    Project Hours {TotalHours}
                  </h3>
                </MDBCol>
                <MDBCol size="md" className="col-example float-right"></MDBCol>
              </div>
            </div>

            <div className="tasklisttbl page-container all-project-list row">
              <DatePicker
                format={'DD/MM/YYYY'}
                className="date-pick-pro-hour"
                style={{ zIndex: '99' }}
                onChange={(e) => ProjectDATA(e)}
              />
              <MDBCol size="md-6">
                <MDBDataTableV5
                  // hover
                  entriesOptions={[20, 25, 30]}
                  entries={20}
                  pagesAmount={4}
                  data={datatable}
                  searchTop
                  searchBottom={false}
                  className="pro-work-hour-main-tbl directory-list-table"
                />
              </MDBCol>
              <MDBCol size="md-2"></MDBCol>
              <MDBCol size="md-4">
                <MDBDataTableV5
                  // hover
                  entriesOptions={[20, 25, 30]}
                  entries={20}
                  pagesAmount={4}
                  data={departmentDatatable}
                  searchTop
                  searchBottom={false}
                  className="pro-work-hour-main-tbl directory-list-table"
                />
              </MDBCol>
            </div>
          </MDBCard>
        </div>
      </div>
    </>
  )
}

export default ProjectWorkingHours
