import { MYDAILYREPORTTASK, MYTASK,OVERDUETASK  } from "./ActionsType";

// ALL_FILTER_RESET
export function actionMyTaskAction(data) {
  return (dispatch) => {
    dispatch({ type: MYTASK, payload: data });
  };
}

export function actionMyDailyReport(data) {
  return (dispatch) => {
    dispatch({ type: MYDAILYREPORTTASK, payload: data });
  };
}
export function actionOverDueTaskAction(data) {
  return (dispatch) => {
    dispatch({ type: OVERDUETASK, payload: data });
  };
}
