import React from 'react';
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Menu, Dropdown, Select, Typography, Modal } from "antd";
import LeaveForm from "../employee_master/LeaveForm";
import AddMember from "./AddMember";
import AxiosInstance from "../../../service/AxiosInstance";
import { MDBIcon } from "mdb-react-ui-kit";
import TeamBar from "./TeamBar";
import Logs from '../me/leaveModule/Logs';
import { Button } from '@mui/material';
import {toast , ToastContainer,  } from "react-toastify";
const { Option } = Select;

function AddTeamMember() {
  const [datatable, setDatatable] = React.useState({});
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [editTimeMemeberData, setEditTimeMemberData] = React.useState("");
  const [leave, setLeave] = React.useState("");
  const [employeeIds, setEmployeeIds] = React.useState([]);
  const [logs, setLogs] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [deleteID, setDeleteID] = React.useState('');

  function getLeavesLog(id) {
    AxiosInstance.get(
      `auth/leaves/leaveLogs?id=${id}&employee=${employeeIds.join()}`,
    ).then(async (res) => {
      setLogs(res?.data?.data)
      showDrawer()
    })
  }

  const handleClick = (items, status) => {
    setLeave(items);
    if (status == 'logs') {
      getLeavesLog(items._id)
      showDrawer()
    }
  }

  const showDrawer = () => setVisible(true)
  const onClose = () => setVisible(false)

  const actionMenu = (items, listAction) => (
    <Menu items={listAction} onClick={(e) => handleClick(items, e.key)} />
  );

  const actions = (items) => {
    let listAction = [
      {
        key: 'logs',
        label: 'Logs',
        items: items,
      },
    ];
    return (
      <>
        <Dropdown.Button
          className='team-leave-dropdown'
          overlay={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    );

  }

  const deleteData = async () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", deleteID);
    AxiosInstance.post("auth/delete-project-team-member", urlencoded)
      .then((result) => {
        toast.success(result?.data?.message);
        getTeamMembers();
        setDeleteVisible(false);
      })
  };

  const handleCancel = () => {
    setDeleteVisible(false);
  };

  // here through we have to get the all Teams....
  function getTeamMembers() {
    AxiosInstance("/auth/getTeamMembers")
      .then(async (res) => {
        let newMemebersArray = [];
        if (res.data.status === 200 || res.data.status === 1) {
          await res.data.data.map((el) => {
            let allMembers = [];
            el.members?.map((item) => {
              allMembers.push(
                item?.memberId?.First_name + " " + item?.memberId?.Last_name
              );
            });
            newMemebersArray.push({
              _id: el._id,
              name:
                el?.tpm_employee_id?.First_name +
                " " +
                el?.tpm_employee_id?.Last_name,
              members: allMembers?.join(" , "),
              action: (
                <div
                  className='team-leave-flex'>
                  <div className='icon-main'>
                    <MDBIcon
                      fas
                      icon="edit"
                      className='trash-icon'
                      onClick={(_id) => {
                        setEditTimeMemberData({ ...el, isEditTime: true });
                      }}
                    />
                  </div>{" "}
                  <div className='icon-main'>
                    <MDBIcon icon="trash-alt" className='trash-icon' onClick={() =>  {
                      setDeleteVisible(true) 
                      setDeleteID(el?._id)
                    }
                      } />
                  </div>
                  {actions(el)}
                </div>
              ),
            });
          });
          setTeamMembers(newMemebersArray);
        }
      })
      .catch((e) => {
        console.log("error", e.response.message);
      });
  }

  // when the page first time load then used...
  React.useEffect(() => {
    getTeamMembers();
  }, []);

  // here we have to set up Datatable
  React.useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          // sort: 'disabled',
          width: 50,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Members",
          field: "members",
          // sort: 'disabled',
          width: 200,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Action",
          field: "action",
          // sort: 'disabled',
          width: 200,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
      ],
      rows: teamMembers,
    });
  }, [teamMembers]);

  // when the user through add the team then used...
  const handleReRenderMembers = () => {
    getTeamMembers();
  };

  // when the user cancel Edit then used..
  const handleCacelEdit = () => {
    setEditTimeMemberData("");
  };
  return (
    <>
      <div className="demo">
        <TeamBar />
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example">
              {" "}
              <>
                <div className="leave-set-btn2">
                  {/* <h3 className="tablHeader">Team List</h3> */}
                  <AddMember
                    reRenderMembers={handleReRenderMembers}
                    isEdit={editTimeMemeberData}
                    cancelEdit={handleCacelEdit}
                    key={editTimeMemeberData?._id}
                  />
                </div>
              </>
            </MDBCol>
          </div>

          <hr />

          <div className="tasklisttbl all-emp-list">
            <MDBDataTableV5
              // hover
              searchTop
              entriesOptions={[10, 20, 30]}
              entries={10}
              pagesAmount={4}
              data={datatable}
            />
          </div>

          <Modal
            title="Are you sure to delete this team ?"
            centered
            visible={deleteVisible}
            onOk={() => setDeleteVisible(false)}
            onCancel={handleCancel}
            width={440}
            footer={null}
          >
            <div className='btn-flex'>
              <Button
                className="pms-same-btn"
                type="danger"
                onClick={deleteData}
              >
                Delete
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">Cancel</Button>
            </div>
          </Modal>

          {leave && (
            <Logs
              leave={leave}
              visible={visible}
              logs={logs}
              onClose={(value) => onClose(value)}
            />
          )}

        </MDBCard>
      </div>
    </>
  );
}

export default AddTeamMember;
