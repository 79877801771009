import { TODAYABSENT } from "../actions/ActionsType";

const INITIAL_STATE = {
  TodayAbsent: [],
};

const AttendanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TODAYABSENT:
      return {
        ...state,
        TodayAbsent: action.payload,
      };
    default:
      return state;
  }
};

export default AttendanceReducer;
