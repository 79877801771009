import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";

import axiosInstance from "../../../service/AxiosInstance";
import ReactLoader from "../../../components/common/ReactLoader";

export default function EmployeeTracker() {
  const [flag, Setflag] = useState(false);
  const [employees, Setemployees] = useState([]);
  const [employeesTimer, SetEmployeesTimer] = useState([]);
  const [employeesAbsent, SetEmployeesAbsent] = useState([]);
  const [datatable, setDatatable] = React.useState({});
  const [loading, setLoading] = useState(false);
  const UserData = localStorage.getItem("userdata")
    ? localStorage.getItem("userdata")
    : undefined;
  let Position_Id = UserData ? JSON.parse(UserData)[0].Position_id : undefined;
  // useEffect(() => {
  //   setDatatable({
  //     columns: [
  //       {
  //         label: 'Name',
  //         field: 'name',
  //         width: 150,
  //         sort: 'disabled',
  //         attributes: {
  //           'aria-controls': 'DataTable',
  //           'aria-label': 'Name',
  //         },
  //       },
  //       {
  //         label: 'Email',
  //         field: 'email',
  //         sort: 'disabled',
  //         width: 270,
  //       },

  //       {
  //         label: 'Mobile No',
  //         field: 'mobileNo',
  //         sort: 'disabled',
  //         width: 100,
  //       },
  //     ],
  //     rows: employees,
  //   })
  // }, [employees, flag])
  useEffect(() => {
    setLoading(true);
    axiosInstance.get("auth/employees").then(async (result) => {
      if (result.data.data) {
        setLoading(false);
        let employeesdeta = await result.data.data.map((item) => {
          return {
            name: `${item.First_name} ${item.Middle_name} ${item.Last_name}`,
            email: item.Email,
            position: item.Position,
            department: item.Role,
            mobileNo: item.Mobile,
            employeecode: item.Employee_code,
            _id: item._id,
          };
        });
        Setemployees(employeesdeta);
      }
    });

    axiosInstance
      .get("auth/task/get-running-task-timer")
      .then(async (result) => {
        if (result.data.data) {
          SetEmployeesTimer(result.data.data);
        }
      });

    axiosInstance.get("auth/leave/todays").then(async (result) => {
      if (result.data.data) {
        SetEmployeesAbsent(result.data.data);
      }
    });
  }, [flag]);

  useEffect(() => {
    var employeeList = employees.map((obj) => {
      let employeeTrack = employeesTimer.filter((emp) => {
        return emp.Employee_id == obj._id;
      });
      let absent = employeesAbsent.filter((ele) => {
        return ele.Employee_ID._id == obj._id;
      });
      //console.log(employeeTrack,'employeeTrackemployeeTrack');
      obj.trackerData = absent[0]
        ? "ABS"
        : employeeTrack[0]
        ? employeeTrack[0]?.Task_id?.Task_key +
          " - " +
          employeeTrack[0]?.Task_id?.Short_summary
        : "-";

      return obj;
    });

    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },

        {
          label: "Employee Code",
          field: "employeecode",

          width: 270,
        },
        {
          label: "Department",
          field: "department",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "Working On",
          field: "trackerData",
          // sort: 'asc',
          width: 200,
        },
      ],
      rows: employeeList,
    });
  }, [flag, employees, employeesTimer, employeesAbsent]);

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div>
            <div className="create-project">
              <MDBCol size="md" className="col-example">
                <h3 className="tablHeader">Employee Tracker</h3>
              </MDBCol>
            </div>
          </div>

          {loading ? (
            <ReactLoader loading={loading} />
          ) : (
            <div className="tasklisttbl emp-tracl-tbl">
              <MDBDataTableV5
                hover
                entriesOptions={[20, 25, 30, 50, 100]}
                entries={20}
                pagesAmount={4}
                data={datatable}
                searchTop
                searchBottom={false}
              />
            </div>
          )}
        </MDBCard>
      </div>
    </>
  );
}

//
