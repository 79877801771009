import { Progress, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import { Select, Spin, Table } from "antd";
import { projectStatusBadge } from "../../../../components/common/Coomon";
import { Link } from "react-router-dom";

const SprintDue = ({ loading, projectIds, setLoading ,sortForDues}) => {
  const [sprintData, setSprintData] = useState([]);
  const [total, setTotal] = useState(100);
  const [statusSort, setStatusSort] = useState("");
  const { Option } = Select;
  const GetAllSprintData = () => {
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("id", JSON.stringify(projectIds));
    formData.append("type", sortForDues);
    AxiosInstance.post("/sprint/seventhDaysDueSprint", formData).then((res) => {
      setLoading(false);
      let sortedList = res.data.data.sort(
        (a, b) => moment(a.DueDate) - moment(b.DueDate)
      );
      let data = [];
      sortedList.map((item, index) => {
        const objData = {
          icon: index + 1,
          name: item?.Name,
          project_id: item?.Project_id?._id,
          project_name: item?.Project_id?.Project_name,
          project_status: item?.Project_id?.Status,
          status: projectStatusBadge(item?.Status),
          filter_status: item?.Status,
          Milestone: item?.Milestone?.Name,
          Border_left: item?.DueDate ? item?.DueDate : "",
          Date: item?.StartDate
            ? `${moment(item?.StartDate).format("DD-MMM")} - ${moment(
                item?.DueDate
              ).format("DD-MMM")}`
            : "",
        };
        data.push(objData);
        // setSprintData((e) => [...e, objData]);
      });
      setSprintData(data);
    });
  };
  useEffect(() => {
    GetAllSprintData();
  }, [projectIds,sortForDues]);
  return (
    <>
      <div className="dash-col-milestone">
        <div className="main-milestone">
          <div className="milstone-flex">
            <div className="milestone-card-head">
              <p>Sprint Due</p>
              <Spin spinning={loading} style={{ marginTop: 100 }}>
                {" "}
              </Spin>
            </div>
            <div className="milestone-dropdown">
              <Select
                defaultValue={""}
                className="select-dropdown leave-4"
                onChange={(e) => {
                  setStatusSort(e);
                }}
              >
                <Option value="">All</Option>
                <Option value="Pending">Pending</Option>
                <Option value="In Progress">In Progress</Option>
                <Option value="In Client Review">In Client Review</Option>
                <Option value="On Hold">On Hold</Option>
                <Option value="Client Not Responding">
                  Client Not Responding
                </Option>
              </Select>
            </div>
          </div>
          <div className="milestone-card-body">
            {sprintData.length > 0 ? (
              sprintData &&
              (statusSort.length > 0
                ? sprintData.filter((item) => item.filter_status === statusSort)
                : sprintData
              ).map((item, number) => {
                return (
                  <Link
                    className="mile-body-content"
                    to={`/project/sprint/${item?.project_id}`}
                      target="_blank"
                    style={{
                      borderLeft: moment(item?.Border_left).isBefore(
                        moment(),
                        "day"
                      )
                        ? "4px solid red"
                        : moment(item?.Border_left).diff(moment(), "day") <= 15
                        ? "4px solid yellow"
                        : "4px solid grey",
                    }}
                  >
                    <div className="mile-progress">
                      <p>
                        <Progress type="circle" percent={30} />
                      </p>
                    </div>
                    <div className="mail-right-detail">
                      <p className="mile-name">{item?.name}</p>
                      <p className="mile-pro-name">{item?.project_name}  ({item?.project_status})</p>
                      <p className="mile-pro-name">{item?.Milestone}</p>
                      <div className="mile-status-date">
                        <p className="mile-status">{item?.status}</p>
                        <p className="">
                          <i class="far fa-calendar-alt"></i> {item?.Date}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })
            ) : (
              <p
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "90px",
                }}
              >
                No Sprint Due
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SprintDue;
