import { PERMISSIONS } from "../actions/ActionsType";

const INITIAL_STATE = {
  Permissions: {},
};

const PermssionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PERMISSIONS":
      return {
        ...state,
        Permissions: action.payload,
      };
    default:
      return state;
  }
};

export default PermssionsReducer;
