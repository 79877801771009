import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";
import ReactLoader from "../../../../components/common/ReactLoader";

const DueMilestone = () => {
  const [datatable, setDatatable] = useState({});
  const [milestoneData, setMilestoneData] = useState([]);
  const [loading, setLoading] = useState(false);
  const GetAllMileStone = () => {
    setLoading(true);
    AxiosInstance.get("/milestone/fifteenDaysDueMilestones").then((res) => {
      setLoading(false);
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          name: item?.Name,
          project_name: item?.Project_id?.Project_name,
          pmname: item?.Project_id?.Project_manager,
          tpmname: item?.Project_id?.Technical_project_manager,
          dueDate: item?.DueDate
            ? moment(item?.DueDate).format("DD/MM/YYYY")
            : "",
          status: item?.Status,
        };
      });

      setMilestoneData(list1);
    });
  };

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Name",
          field: "name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Project Name",
          field: "project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Status",
          field: "status",
          width: 200,
          sort: "disabled",
        },
        {
          label: "Due Date",
          field: "dueDate",
          width: 270,
          sort: "disabled",
        },

        {
          label: "PM Name",
          field: "pmname",
          width: 270,
          sort: "disabled",
        },
        {
          label: "TPM Name",
          field: "tpmname",
          width: 150,
          sort: "disabled",
        },
      ],
      rows: milestoneData,
    });
  }, [milestoneData]);

  useEffect(() => {
    GetAllMileStone();
  }, []);
  return (
    <>
      <div className="team-text">
        <p>Due Milestone</p>
      </div>

      {loading ? (
        <ReactLoader loading={loading} />
      ) : (
        <div className="team-table scrool-set milestone-tbl tasklisttbl due-table">
          <MDBDataTableV5
            hover
            entriesOptions={[10, 20, 25]}
            entries={10}
            pagesAmount={4}
            data={datatable}
            searchTop
            searchBottom={false}
          />
        </div>
      )}
    </>
  );
};

export default DueMilestone;
