import React from "react";
import EmployeeTab from "./EmployeeTab";
import OtherDetailsTab from "./OtherDetailsTab";
import List from "../me/leaveModule/List";
import { useParams } from "react-router";
const OtherDetails = () => {
  const params= useParams()
  return (

    <div className="demo">
      <div className="cardmargine">
        <EmployeeTab />
        <OtherDetailsTab />
        <List id={params.id}/>
      </div>
    </div>
  );
};

export default OtherDetails;
