import logo2 from "../assets/images/logo2.png"
// import logo3 from '../assets/images/logo3.png';
import SuggestionIcon from '../assets/images/suggestion.png'
import HolidayIcon from '../assets/images/HolidaysCalendar.png'
import HRExec from '../assets/images/HR_Handbook/HRExec.png'
import CTO from '../assets/images/HR_Handbook/CTO.png'
import MD from '../assets/images/HR_Handbook/MD.png'
import CPO from '../assets/images/HR_Handbook/CPO.png'



const Png = {
    logo2,
    SuggestionIcon,
    HolidayIcon,
    HRExec,
    CTO,
    MD,
    CPO
}

export { Png };