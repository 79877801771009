import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import AxiosInstance from "../../../service/AxiosInstance";

const AddVertical = ({ setIsOpen, reloadList, verticalId }) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();

  const [verticalname, setverticalname] = useState("");
  const [status, setStatus] = useState("");

  const [verticalDataById, setverticalDataById] = useState([]);

  const handleCancel = (errorInfo) => {
    console.log("Failed:", errorInfo);
    form.resetFields();
    setverticalname("");
    setVisible(false);
    setStatus("");
    setIsOpen(false);
  };

  const onFinish = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("Vertical_name", values?.vertical_name);
    urlencoded.append("Status", values?.status);
    if (verticalId) {
      urlencoded.append("id", verticalId);
    }
    AxiosInstance.post("/vertical/create", urlencoded).then((res) => {
      toast.success("Vertical Added / Updated Successfully");
      setIsOpen(false);
      reloadList();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setTextEditer('');
  };

  const getVerticalDataById = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", verticalId);
    AxiosInstance.post("/vertical/getVerticalById", urlencoded).then((res) => {
      setverticalDataById(res.data.data);
    });
  };

  useEffect(() => {
    getVerticalDataById();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      vertical_name: verticalDataById?.[0]?.Vertical_name,
      status: verticalDataById?.[0]?.Status,
    });
  }, [verticalDataById]);

  return (
    <>
      <Modal
        title="Add Vertical"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={800}
        height={640}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          //   name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={12}>
              <div>
                Vertical Name<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="vertical_name"
                rules={[
                  { required: true, message: "Please Enter Vertical Name" },
                  // {
                  //   pattern: new RegExp(/^[a-zA-Z ]+$/i),
                  //   message: "field does not accept special characters.",
                  // },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <div>
                Vertical Name<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Please Select Status",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    setStatus(e);
                  }}
                >
                  <Select.Option value="Active">Active</Select.Option>
                  <Select.Option value="DeActive">DeActive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div style={{ textAlign: "end", marginTop: "10px" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                className="pms-same-btn"
              >
                Submit
              </Button>
              <Button
                className="pms-same-btn-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddVertical;
