import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

function TeamBar() {
  const permssionsState = useSelector((state) => state.Permissions.Permissions);

  const [isKRAOpen, setIsKRAOpen] = useState(false);
  const checkIfDateInRange = () => {
    const currentDate = moment();
    // Check if the current date is the 1st of the month
    if (currentDate.date() === 1) {
      let nextWeekdayCounter = 0;
      let nextDate = currentDate.clone().add(1, 'day');

      while (nextWeekdayCounter < 5) {
        // Check if the next date is a weekday (Monday to Friday)
        if (nextDate.day() >= 1 && nextDate.day() <= 5) {
          nextWeekdayCounter++;
        }
        // Move to the next day
        nextDate.add(1, 'day');
      }

      if (nextWeekdayCounter === 5) {
        // Execute your function here
        setIsKRAOpen(true)
      } else {
        setIsKRAOpen(false)
      }
    } else {
      setIsKRAOpen(false)
    }
  };

  useEffect(() => {
    checkIfDateInRange()
  },[])
  return (
    <>
      <MDBNavbarNav className="page-menu-nav">
        <MDBNavbarItem>
          <Link to="/add-team" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Teams
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          {permssionsState?.Me?.Team_Leave == "1" ? (
            <Link to="/teams-leave" active>
              {" "}
              <MDBNavbarLink active aria-current="page" href="#">
                Team Leave
              </MDBNavbarLink>
            </Link>
          ) : (
            ""
          )}
        </MDBNavbarItem>
        <MDBNavbarItem>
          {(permssionsState?.Me?.Team_Leave == "1" && isKRAOpen) ? (
            <Link to="/teams-kra" active>
              {" "}
              <MDBNavbarLink active aria-current="page" href="#">
                Team Kra
              </MDBNavbarLink>
            </Link>
          ) : (
            ""
          )}
        </MDBNavbarItem>
      </MDBNavbarNav>
    </>
  );
}

export default TeamBar;
