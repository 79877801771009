import { useState } from "react";
import ContactBar from "./ContactBar";
import { Link } from "react-router-dom";
import { MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { Png } from "../../../assets/png";

function LeavePolicy() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="demo">
        <ContactBar />
        <MDBCard className="page-content">
          <MDBCol
            size="md"
            className="col-example"
          ></MDBCol>
          <div className="create-project"style={{display:"flex",justifyContent:"center"}}>
          <img src={Png.HolidayIcon} style={{width:'50%',height:"80%",objectFit:"contain",}} className="holiday-mobile-content"></img>
          </div>
        </MDBCard>
      
      </div>
    </>
  );
}
export default LeavePolicy;
