import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, Select } from "antd";
import PmsDatePicker from "../leaveModule/PmsDatePicker";
import moment from "moment";
import AxiosInstance from "../../../../service/AxiosInstance";
import SelectEmpployee from "../../../../components/common/SelectEmpployee";

const AddOvertime = ({ setIsOpen, reloadList, overtimeId }) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();

  const [overtimeDataById, setovertimeDataById] = useState([]);
  const [date, setdate] = useState();
  const [project_manager, setProjectManager] = useState();
  const [projectId, setProjectId] = useState();
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);

  let user_data = JSON.parse(localStorage.getItem("userdata"));

  const onFinish = (values) => {
    console.log(values)

    const urlencoded = new URLSearchParams();
    urlencoded.append("Overtime_date", values?.overtime_date);
    urlencoded.append("Description", values?.description);
    // urlencoded.append(
    //   "Reporting_manager_id",
    //   user_data[0].Reporting_manager_id
    // );

    // urlencoded.append("Project_manager_id", project_manager);
    urlencoded.append("Project_id", projectId);
    urlencoded.append(
      "Creater_id",
      user_data[0]._id
    );


    if (overtimeId) {
      urlencoded.append("id", overtimeId);
    }
    AxiosInstance.post("/overtime/create", urlencoded).then((res) => {
      setIsOpen(false);
      reloadList();
    });
  };
  console.log(projectId, 90)
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setTextEditer('');
  };

  const handleCancel = (errorInfo) => {
    console.log("Failed:", errorInfo);

    form.resetFields();
    setVisible(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (overtimeId !== "") {
      setProjectId(overtimeDataById?.[0]?.Project_id?._id)
      form.setFieldsValue({
        overtime_date:
          overtimeDataById?.[0]?.Overtime_date &&
          moment(
            moment(overtimeDataById?.[0]?.Overtime_date).format("DD/MM/YYYY"),
            "DD/MM/YYYY"
          ),
        description: overtimeDataById?.[0]?.Description,
        project:
          overtimeDataById?.[0]?.Project_id?.Project_name
      });
    }

  }, [overtimeDataById]);

  const getOvertimeDataById = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", overtimeId);
    AxiosInstance.post("/overtime/getOvertimeById", urlencoded).then((res) => {
      setovertimeDataById(res.data.data);
    });
  };

  // const getAllEmployee = () => {
  //   AxiosInstance.get("/overtime/getAllEmployees").then((res) => {
  //     setEmployees(res.data.data);
  //   });
  // };

  const getAllProjects = () => {
    AxiosInstance.get("/overtime/getAllProjects").then((res) => {

      setProjects(res.data.data);
    });
  };

  useEffect(() => {
    //getAllEmployee();
    getAllProjects()
  }, []);

  useEffect(() => {
    getOvertimeDataById();
  }, [overtimeId]);

  return (
    <>
      <Modal
        title="Add Overtime"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={800}
        height={640}
        bodyStyle={{ padding: "20px 0px" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row style={{ padding: "0px 39px" }}>
            <Col span={24}>
              <div style={{ marginBottom: "10px" }}>
                Date<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item name="overtime_date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <PmsDatePicker
                  onChange={(date) => {
                    setdate(date._d);
                  }}
                  format={"DD/MM/YYYY"}
                  disabledDate={(d) => d > moment(Date.now())}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ padding: "0px 39px" }}>
            <Col span={24}>
              <div style={{ marginBottom: "10px" }}>
                Description<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Description",
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea rows={5} maxLength={250} showCount />
              </Form.Item>
            </Col>
          </Row>


          <Row style={{ padding: "0px 39px" }}>
            <Form.Item
              label="Select Project"
              name="project"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select project",
                },
              ]}
            >
              <Select
                onChange={(e) => setProjectId(e)}
                showSearch="true"
                optionFilterProp="children"
              >
                {projects?.map((item, index) => {
                  return (
                    <Select.Option value={item._id}>
                      {item?.Project_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Row>


          <Form.Item>
            <div style={{ textAlign: "end", marginRight: "39px", marginTop: "15px" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "15px" }}
                className="pms-same-btn"
              >
                Submit
              </Button>
              <Button
                className="pms-same-btn-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddOvertime;
