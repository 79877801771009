import { Collapse, Spin } from "antd";
import React from "react";
import AxiosInstance from "../../../../service/AxiosInstance";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { MDBDataTableV5 } from "mdbreact";
import moment from "moment";
import ReactLoader from "../../../../components/common/ReactLoader";

function ProjectHoursByWeek() {
  const { Panel } = Collapse;
  const params = useParams();
  const [weekWiseData, setWeekWiseData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const initialDatatable = {
    columns: [
      {
        label: "Employee Name",
        field: "Employee_Name",
        width: 200,
        sort: "disabled",
      },
      {
        label: "Task Name",
        field: `Task_Name`,
        sort: "disabled",
        width: 100,
      },
      {
        label: "Date",
        field: `Task_Date`,
        sort: "disabled",
        width: 100,
      },
      {
        label: "Time",
        field: "timer",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: [],
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  React.useEffect(() => {
    getProjectHourByWeek();
  }, []);

  const getProjectHourByWeek = () => {
    setLoading(true);
    AxiosInstance.get(`auth/weekWiseReport/${params.id}`)
      .then((res) => {
        if (res?.data?.status === 1) {
          let weekWiseArray = [];
          if (res?.data?.data?.weeks?.length > 0) {
            res?.data?.data?.weeks?.map((el) => {
              let subTimerArray = [];
              if (el?.taskTimerData?.length > 0) {
                el?.taskTimerData?.map((item) => {
                  subTimerArray.push({
                    ...item,
                    Employee_Name:
                      item?.Employee_id?.First_name +
                      " " +
                      item?.Employee_id?.Last_name,
                    Task_Name: item?.Task_id?.Task_key,
                    Task_Date: moment(item?.Check_In).format("DD-MM-YYYY"),
                  });
                });
              }
              weekWiseArray.push({ ...el, taskTimerData: subTimerArray });
            });
          }
          // setWeekWiseData(res?.data?.data?.weeks);
          setWeekWiseData(weekWiseArray);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("hours error", err);
      });
  };

  return (
    <>
      <div className="demo mb-5">
        <Spin spinning={loading}>
          <Collapse accordion>
            {weekWiseData?.length > 0 &&
              weekWiseData?.map((el, index) => (
                <Panel
                  header={`week ${index + 1} (${el?.startDate} to ${
                    el.endDate
                  })`}
                  key={el.startDate}
                >
                  <MDBDataTableV5
                    hover
                    entriesOptions={[10, 20, 30]}
                    entries={10}
                    pagesAmount={4}
                    data={{ ...initialDatatable, rows: el?.taskTimerData }}
                    searchTop={false}
                    searchBottom={false}
                    key={el.startDate}
                  />
                </Panel>
              ))}
          </Collapse>
        </Spin>
      </div>
    </>
  );
}
export default ProjectHoursByWeek;
