import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import AxiosInstance from "../../../service/AxiosInstance";

const AddLocation = ({ setIsOpen, reloadList, locationid }) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();

  const [locationname, setLocationname] = useState("");
  const [status, setStatus] = useState("");

  const [locationDataById, setLocatioDataById] = useState([]);

  const handleCancel = (errorInfo) => {
    console.log("Failed:", errorInfo);
    form.resetFields();
    setLocationname("");
    setVisible(false);
    setStatus("");
    setIsOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // setTextEditer('');
  };

  const onFinish = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("Location_name", values?.location_name);
    urlencoded.append("Status", values?.status);
    if (locationid) {
      urlencoded.append("id", locationid);
    }
    AxiosInstance.post("/location/create", urlencoded).then((res) => {
      toast.success("Location added / updated Successfully");
      setIsOpen(false);
      reloadList();
    });
  };

  const getLocationDataById = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", locationid);
    AxiosInstance.post("/location/getLocationById", urlencoded).then((res) => {
      setLocatioDataById(res.data.data);
    });
  };

  useEffect(() => {
    getLocationDataById();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      location_name: locationDataById?.[0]?.Location_name,
      status: locationDataById?.[0]?.Status,
    });
  }, [locationDataById]);

  return (
    <>
      <Modal
        title="Add Location"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={800}
        height={640}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
        maskClosable={false}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          //   name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={12} style={{ paddingRight: "12px" }}>
              <div style={{ marginBottom: "10px" }}>
                Location Name<span style={{ color: "red" }}> *</span>
              </div>
              <Form.Item
                name="location_name"
                rules={[
                  { required: true, message: "Please Enter Location Name" },
                  {
                    pattern: new RegExp(/^[a-zA-Z ]+$/i),
                    message: "field does not accept special characters.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: "12px" }}>
              <div style={{ marginBottom: "10px" }}>
                status<span style={{ color: "red" }}> *</span>
              </div>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Please Select Status",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    setStatus(e);
                  }}
                >
                  <Select.Option value="Active">Active</Select.Option>
                  <Select.Option value="DeActive">DeActive</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div style={{ textAlign: "end", marginRight: "12px", marginTop: "15px" }}>
              <Button
                className="pms-same-btn"
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                className="pms-same-btn pms-same-btn-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddLocation;
