import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Select,
  Checkbox,
  Table,
  Calendar,
  Upload,
  DatePicker,
  Space,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AxiosInstance from "../../../service/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import SelectEmpployee from "../../../components/common/SelectEmpployee";
import moment from "moment";

const columns = [
  {
    title: "User",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  ,
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Days",
    dataIndex: "Days",
    key: "Days",
  },
];

const data = [
  {
    key: "1",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "Meet chotaliya",
    Date: "1 oct",
    Days: "0.5 Day",
  },
  {
    key: "2",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "Jigar patel",
    Date: "25oct - 28oct",
    Days: "1 Day",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
];
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
// main component
const LeaveForm = (item) => {
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const showEmployee = item.showEmployee ? item.showEmployee : false;

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const [emergency, setEmergency] = useState(false);
  const [selecttedFile, setSelecttedFile] = useState([]);
  const [justifyActive, setJustifyActive] = useState("tab1");
  const [value, setValue] = useState();
  const [valueFirstDay, setValueFirstDay] = useState("First Half");
  const [valueSecondDay, setValueSecondDay] = useState("Second Half");
  const [employeeId, setEmployeeId] = useState([]);
  const [leaveDates, setLeaveDates] = useState();
  const [notifiedEmployee, setNotifiedEmployee] = useState([]);
  const [status, setStatus] = useState("");

  const props = {
    onChange({ file, fileList }) {
      console.log(file, 111)
      if (file.status !== "uploading") {
        setSelecttedFile(file.originFileObj);
      }
    },
  };



  const optionsWithDisabled = [
    { label: "First Half", value: "First Half" },
    { label: "Second Half", value: "Second Half" },
  ];
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  let history = useHistory();

  const onFinish = (values) => {
    const startDate = leaveDates[0].format("YYYY-MM-DD");
    const endDate = leaveDates[1].format("YYYY-MM-DD");
    // console.log(startDate, endDate)

    if (showEmployee) {
      if (status == "") {
        alert("Please select status");

        return false;
      }
    }

    if (values) {
      const postData = new FormData();
      var date2 = new Date();
      let fomatedDate = moment(date2).format("MMM DD YYYY hh:mm a");

      postData.append("Email", values.email);
      postData.append("Leave_Date_to_Date", values.dateToDate);
      postData.append("StartDate", startDate);
      postData.append("EndDate", endDate);
      postData.append("Reason", values.reason);
      postData.append("Leave_half_from", valueFirstDay);
      postData.append("Leave_half_to", valueSecondDay);
      postData.append("Notified_Employee", notifiedEmployee);
      postData.append("Create_at", fomatedDate);
      postData.append("Emergency_leave", emergency);
      postData.append("image", selecttedFile);



      // postData.append('Employee_ID', userData[0]?._id)

      postData.append(
        "Employee_ID",
        showEmployee ? employeeId[0] : userData[0]?._id
      );

      postData.append("Status", showEmployee ? status : "Pending");
      // if(showEmployee){}
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      AxiosInstance.post("auth/leave/add", postData, config)
        .then((res) => {
          if (res.data.status == 1) {
            toast.success("Leave applied Successfully");
            props.propData();
          }
        })
        .catch((err) => console.log(err, "err"));
    }
    // props.propData(true);
    if (showEmployee) {
      history.push("/leaves-report");
    } else {
      history.push("/leaves");
    }

    form.resetFields();
    setVisible(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeFirstDay = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio4 checked", value);
    setValueFirstDay(value);
  };
  const onChangeSecondDay = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio4 checked", value);
    setValueSecondDay(value);
  };

  const handleCancel = (errorInfo) => {
    form.resetFields();
    form1.resetFields();
    props.propData();
    setVisible(false);
  };

  return (
    <>
      <Button
        className="pms-same-btn leave-btn"
        onClick={() => setVisible(true)}
      // style={{ marginRight: '6px', float: 'right' }
      >
        Apply Leave
      </Button>

      <Modal
        title="Employee Leave Form"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={550}
        // height={640}
        className="leave-form modal-back modal-title close-btn"
        footer={null}
      >
        {/* <MDBTabsContent>
            <MDBTabsPane show={justifyActive === 'tab1'}> */}
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          // name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <Row className="mx-0">
            {showEmployee && (
              <Col span={24} className="text-area">
                <Form.Item
                  label="Employee"
                  name="reason"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Reason!",
                    },
                  ]}
                >
                  <SelectEmpployee
                    mode="single"
                    setEmployeeIds={(value) => setEmployeeId(value)}
                  />
                </Form.Item>
              </Col>
            )}
            <Col className="date-module">
              <Form.Item
                className="date-input"
                name="emergency"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Checkbox onChange={(e) => setEmergency(e.target.checked)}>
                  Mark as an emergency (1 leave per month)
                </Checkbox>
              </Form.Item>
            </Col>
            <Col className="date-module">
              <Form.Item
                className="date-input"
                label="Leave Date"
                name="dateToDate"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Leave dates!",
                  },
                ]}
              >
                <RangePicker
                  format={"DD/MM/YYYY"}
                  onChange={setLeaveDates}
                  // disabledDate={(current) => {
                  //   return (
                  //     !showEmployee &&
                  //     moment().add(+3, "days") >= current &&
                  //     !emergency
                  //   );
                  // }}
                  disabledDate={(current) => {
                    if(emergency){
                      return !showEmployee &&
                      moment().subtract(1, "days") >= current || (current > moment().add(1, "month") && !showEmployee)
                    }else{
                    return (
                      (!showEmployee &&
                      moment().add(+3, "days") >= current &&
                      !emergency) || (current > moment().add(1, "month") && !showEmployee)
                    );
                  }}}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mx-0">
            <Col span={24} className="half-leave-section">
              <Radio.Group
                options={optionsWithDisabled}
                onChange={onChangeFirstDay}
                value={valueFirstDay}
                optionType="button"
                buttonStyle="solid"
                className=""
              />{" "}
              To
              <Radio.Group
                options={optionsWithDisabled}
                onChange={onChangeSecondDay}
                value={valueSecondDay}
                optionType="button"
                buttonStyle="solid"
                className=""
              />
            </Col>
          </Row>

          <Row className="mx-0">
            <Col span={24} className="text-area">
              <Form.Item
                label="Reason For Leave"
                name="reason"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Reason!",
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span={24} className="colemwidth">
              <Form.Item
                label="File Upload (Only image and pdf)"
                name="image"
                style={{ alignItems: "center" }}
              // onChange={onImageChange}
              >
                <Upload {...props}>
                  <Button style={{ width: "31.8rem" }} icon={<UploadOutlined />} className="pms-same-btn">
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24} className="text-area">
              <Form.Item
                label="Notify Employee"
                name="Notified_Employee"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: false,
                    message: "Please Enter Reason!",
                  },
                ]}
              >
                <SelectEmpployee
                  mode="multiple"
                  setEmployeeIds={(value) => setNotifiedEmployee(value)}
                />
              </Form.Item>
            </Col>{" "}
            {showEmployee && (
              <Col span={24} className="leave-status">
                <Form.Item
                  label="Status"
                  name="Status"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Status!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Status"
                    // style={{ paddingLeft: '10px', width: '70%' }}
                    className="select-dropdown leave-4"
                    onChange={(e) => {
                      setStatus(e);
                    }}
                  >
                    <Option value="Approved">Approved</Option>
                    <Option value="Conditional Approved">
                      Conditional Approved
                    </Option>
                    <Option value="Unapproved">Unapproved</Option>
                    <Option value="Rejected">Rejected</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row className="mx-0"></Row>

          <Form.Item>
            {/* <div style={{ textAlign: 'end', marginRight: '60px' }}> */}
            <div>
              <Button
                className="pms-same-btn mb-0"
                htmlType="submit"
                style={{ marginRight: "10px" }}
              >
                Request for Leave
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">Cancel</Button>
              {/* </div> */}
            </div>
          </Form.Item>
        </Form>
        {/* <div className='right-leaveform rightside_calender'>
          <div className='calender-detail'>
            <p className='calender-heading'>
              Leave Calender
            </p>
            <div className='calnder'>
              <div className="site-calendar-demo-card">
                <Calendar fullscreen={false}  />
              </div>
            </div>
          </div>
          <div className='leave-table'>
            <div className='leave-point'>
              <p className='leave-circle'><div className='color-circle'></div>WeekOff</p>
              <p className='leave-circle'><div className='color-circle'></div>Leave</p>
              <p className='leave-circle'><div className='color-circle'></div>Holiday</p>
            </div>
            <div className='leave-name'>
              <Table columns={columns} dataSource={data} />
            </div>
          </div>

        </div> */}
        {/* </MDBTabsPane>
          </MDBTabsContent> */}
      </Modal>
    </>
  );
};

export default LeaveForm;
