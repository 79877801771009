import React from "react";
import { Modal, Form, Button, Row, Col, Select } from "antd";
import SelectEmpployee from "../../../components/common/SelectEmpployee";
import AxiosInstance from "../../../service/AxiosInstance";
import { toast, ToastContainer } from "react-toastify";
import { PositionEmployeeListOption } from "../Options";

const { Option } = Select;

function AddMember({ reRenderMembers, isEdit, cancelEdit }) {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [selectedEmployee, setSelectedEmployee] = React.useState([]);
  const [PmNamechng, setPmNamechng] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [allEmployees, setAllEmployees] = React.useState([]);
  const [isSubmitDisable, setIsSubmitDisable] = React.useState(false);

  const isEditData = React.useMemo(() => {
    return isEdit?.isEditTime;
  }, [isEdit?.isEditTime, isEdit]);

  // when the user cancel the modal then used...
  const handleCancel = () => {
    setVisible(false);
    setPmNamechng("");
    setSelectedEmployee([]);
    form.resetFields();
    cancelEdit();
  };

  // when the first time page load then set the employee list
  React.useEffect(() => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      if (res.data.status == 1) {
        setAllEmployees(res.data.data);
        let EmList = await res.data.data.map((item) => {
          if (item?.Status === "Active") {
            return {
              label:
                item.First_name + " " + item.Last_name + " (" + item.Role + ")",
              value: item._id,
              id: item._id,
            };
          }
        });
        setOptions(EmList);
      }
    });
  }, []);

  // when the user submit the form then used...
  const onFinish = React.useCallback(() => {
    setIsSubmitDisable(true);
    if (isEdit?.isEditTime) {
      // edit basis
      const editTimeTeamUrlEncoded = new URLSearchParams();

      editTimeTeamUrlEncoded.append(
        "tpm_employee_id",
        JSON.parse(PmNamechng)?.id
      );
      editTimeTeamUrlEncoded.append("_id", isEdit?._id);
      if (selectedEmployee?.length > 0) {
        let newArray = [];
        selectedEmployee?.map((el) => {
          // editTimeTeamUrlEncoded.append("members", {memberId:el});

          newArray.push({ memberId: el });
        });
        editTimeTeamUrlEncoded.append("members", JSON.stringify(newArray));
      }

      // return;
      AxiosInstance.post("auth/updateTeamMembers", editTimeTeamUrlEncoded)
        .then((res) => {
          if (res.data.status === 201 || res.data.status === 1) {
            toast.success("Team update successfully");
            setVisible(false);
            setSelectedEmployee([]);
            form.resetFields();
            reRenderMembers();
            setIsSubmitDisable(false);
            cancelEdit();
          }
        })
        .catch((e) => {
          console.log("error::", e);
          setVisible(false);
          setSelectedEmployee([]);
          setIsSubmitDisable(false);
          form.resetFields();
          reRenderMembers();
          cancelEdit();
        });
    } else {
      // add basis
      const teamUrlEncoded = new URLSearchParams();
      teamUrlEncoded.append("tpm_employee_id", JSON.parse(PmNamechng)?.id);
      if (selectedEmployee?.length > 0) {
        teamUrlEncoded.append("members", JSON.stringify(selectedEmployee));
      }

      AxiosInstance.post("/auth/create-project-team", teamUrlEncoded)
        .then((res) => {
          if (res.data.status === 201 || res.data.status === 1) {
            toast.success("Team added successfully");
            setVisible(false);
            setSelectedEmployee([]);
            form.resetFields();
            reRenderMembers();
            setIsSubmitDisable(false);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          toast.error(`${err.response.data.message}`);
          setIsSubmitDisable(false);
        });
    }
  }, [selectedEmployee, PmNamechng, isEdit]);

  // when the submit time failed the used...
  const onFinishFailed = () => {
    console.log("Finish failed time called... ");
  };

  // when the edit then used...
  React.useEffect(() => {
    if (isEditData) {
      setVisible(true);
      setPmNamechng(
        JSON.stringify({
          id: isEdit?.tpm_employee_id?._id,
          name:
            isEdit?.tpm_employee_id?.First_name +
            " " +
            isEdit?.tpm_employee_id?.Last_name,
        })
      );
      // setSelectedEmployee(isEdit?.members?.map((el) => el?.memberId?._id));
    }
  }, [isEditData, isEdit]);

  // when the user search for memebers then used...
  const handleSearch = (newValue) => {
    if (newValue) {
      let newOptions = allEmployees
        ?.filter((item) => {
          let name =
            item.First_name + " " + item.Last_name + " (" + item.Role + ")";

          return name
            .toLocaleLowerCase()
            .includes(newValue.toLocaleLowerCase());
        })
        .map((item) => {
          return {
            label:
              item.First_name + " " + item.Last_name + " (" + item.Role + ")",
            value: item._id,
            id: item._id,
          };
        });
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}

      <Button
        className="pms-same-btn leave-btn"
        onClick={() => setVisible(true)}
        // style={{ marginRight: '6px', float: 'right' }
      >
        Add Member
      </Button>
      <Modal
        title= {isEdit?.isEditTime ? "Edit Teams " : " Add Teams" }
        centered
        visible={visible}
        onOk={() => setVisible(false)}  
        onCancel={handleCancel}
        width={550}
        // height={640}
        className="leave-form modal-back modal-title close-btn"
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <Row className="mx-0">
            <Col span={11} className="mr-55">
              <Form.Item
                label="Technical Project Manager"
                name="technicalprojectManagerId"
                // initialValue={{
                //   id: isEdit?.tpm_employee_id?._id,
                //   name:
                //     isEdit?.tpm_employee_id?.First_name +
                //     " " +
                //     isEdit?.tpm_employee_id?.Last_name + " (CMS)" ,
                // }}
                initialValue={
                  isEdit !== ""
                    ? {
                        value: isEdit?.tpm_employee_id?._id,
                        label:
                          isEdit?.tpm_employee_id?.First_name +
                          " " +
                          isEdit?.tpm_employee_id?.Last_name,
                      }
                    : "Please Select Technical Project Manager"
                }
              >
                <Select
                  onChange={(e) => setPmNamechng(e)}
                  placeholder="Please Select Technical Project Manager"
                  showSearch="true"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                >
                  {PositionEmployeeListOption(
                    ["Technical Project Manager","CTO","JIG Head","VP Sales","HR Assistant","CPO","VP Strategy Operations"],
                    "withDesignation"
                  )?.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={JSON.stringify({
                          id: item.value,
                          name: item.label,
                        })}
                      >
                        {/*  <Select.Option key={index} value={item.value}> */}
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className="text-area">
              {/* <Form.Item
                label="Employee"
                name="employee"
                style={{ width: "100%" }}
              >
                <SelectEmpployee
                  mode="multiple"
                  setEmployeeIds={(value) => setSelectedEmployee(value)}
                  // setEmployeeIds={(value) => console.log("value", value)}
                  initialvalue={isEdit?.members?.map(
                    (el) =>
                      el.memberId?.First_name + " " + el.memberId?.Last_name
                  )}
                />
              </Form.Item> */}
              <Form.Item
                label="Project Member:"
                name="projectMember"
                initialValue={isEdit?.members?.map(
                  // (el) => el.memberId?.First_name + " " + el.memberId?.Last_name
                  (el) => ({
                    value: el?.memberId?._id,
                    label:
                      el?.memberId?.First_name + " " + el?.memberId?.Last_name,
                  })
                )}
              >
                <Select
                  mode="multiple"
                  onChange={(e) => setSelectedEmployee(e)}
                  filterOption={false}
                  onSearch={handleSearch}
                  placeholder="Please Select Project Members"
                  // options={options}
                  options={options.map((d) => ({
                    value: d.value,
                    label: d.label,
                  }))}
                />
              </Form.Item>
            </Col>{" "}
          </Row>

          <Form.Item>
            <div>
              <Button
                type="primary"
                className="pms-same-btn-cancel mb-0"
                htmlType="submit"
                disabled={isSubmitDisable ? true : false}
              >
                Submit
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default AddMember;
