import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Select, Menu } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import { MDBIcon } from 'mdb-react-ui-kit'
import AxiosInstance from '../../../service/AxiosInstance'
import { Link, useHistory } from 'react-router-dom'
import { EmployeeList } from '../Options'
import AddTask from '../tasks/Add'
import Permission from '../Permission'
import { ToastContainer, toast } from "react-toastify";
const { SubMenu } = Menu

// Delete button model
export const DeleteModel = ({ id }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const handleCancel = (errorInfo) => {
    form.resetFields()
    setVisible(false)
  }
  const deleteData = async () => {
    const formdata = new URLSearchParams()
    formdata.append('id', id)
    await AxiosInstance.post('auth/projects/delete', formdata).then((res) => {
      if (res.data.status == 1) {
        // setList((pre) => pre + 1);
        setVisible(false)
        history.push('/')
      }
    })
  }
  return (
    <>
      <Button className="btn-tresh-link" onClick={() => setVisible(true)}>
        <MDBIcon fas icon="trash" />
        <span style={{ paddingLeft: '15px', fontWeight: '600' }}>
          Delete Project
        </span>
        {/* <MDBIcon style={{ color: 'red', paddingLeft:'10px' }} fas icon="trash" /> */}
      </Button>
      {/* Delete model */}
      <Modal
        title="Are you sure to delete this project ?"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={380}
        bodyStyle={{
          marginLeft: '16%',
          paddingBottom: '6% !important',
          marginTop: '6% !important',
        }}
        footer={null}
      >
        <Button
          type="danger"
          onClick={deleteData}
          style={{
            marginRight: '10%',
            marginLeft: '5%',
            marginTop: '26px !important',
          }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Modal>
    </>
  )
}

// Add Member model
export const AddMemberModel = ({ id, setCount, setFlage }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [projectData, setProjectData] = useState({})

  useEffect(() => {
    const formData = new URLSearchParams()
    formData.append('id', id)

    //API
    AxiosInstance.post('auth/projects/detail', formData).then(async (res) => {
      if (res.data.status == 1) {
        setProjectData(res.data.data)
      }
    })
    setCount((pre) => pre + 1)
  }, [visible])

  const handleCancel = (errorInfo) => {
    form.resetFields()
    setVisible(false)
  }
  const onFinish = (values) => {
    
    if (values) {
      const formData = new URLSearchParams()

      formData.append('Project_member', values.projectMember)
      formData.append('id', id)

      AxiosInstance.post('auth/projects/edit', formData)
        .then((res) => {
          if (res.data.status == 1) {
            setCount((pre) => pre + 1)
            setFlage((pre) => pre + 1)
          }
        })
        .catch((err) => console.log(err, 'err'))
    }
    form.resetFields()
    setVisible(false)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    form.resetFields()
  }, [visible])
  return (
    <>
      <Button className="btn-add-link" onClick={() => setVisible(true)}>
        Add Member
        {/* <MDBIcon style={{ color: 'red', paddingLeft:'10px' }} fas icon="trash" /> */}
      </Button>
      {/* Delete model */}
      <Modal
        title="Add new members"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={800}
        className="add-member-body"
        bodyStyle={{ paddingTop: '20px', paddingBottom: '0px !important' }}
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <Row className="mx-0">
            <Col span={14} className="m-left">
              <Form.Item
                label="Project Member2:"
                name="projectMember"
                initialValue={
                  projectData.Project_member
                    ? projectData.Project_member.split(',')
                    : ''
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Project Member!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please Select Project Members"
                  options={EmployeeList()}
                />
              </Form.Item>
            </Col>
            <Col span={6} className="m-left ml">
              <Form.Item>
                {/* <div style={{ textAlign: 'end', marginRight: '60px' }}> */}
                <Button
                  type="primary"
                  className="margin-left-5"
                  htmlType="submit"
                  style={{ marginRight: '10px' }}
                >
                  Add Member
                </Button>
                <Button onClick={handleCancel}>cancel</Button>
                {/* </div> */}
              </Form.Item>
            </Col>
          </Row>

          {/* <Form.Item>
            <div style={{ textAlign: 'end', marginRight: '60px' }}>
              <Button
                type="primary"
                className="margin-left-5"
                htmlType="submit"
                style={{ marginRight: '10px' }}
              >
                Submit
              </Button>
              <Button onClick={handleCancel}>cancel</Button>
            </div>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  )
}

//main component
const ProjectSidebar = ({ setCount, count, activeId, projectId }) => {
  const [projectDetail, setProjectDetail] = useState([])
  const [taskAddIsShow, setTaskAddIsShow] = useState(false)

  // console.log(projectDetail,'projectDetail:::::::::');
  // const [PID, setPID] = useState(projectId)
  const handleClick = (e) => {
    
  }

  useEffect((value) => {
    console.log(value)
    //API
    const formData = new URLSearchParams()
    formData.append('id', projectId)
    AxiosInstance.post('auth/projects/detail', formData).then(async (res) => {
      if (res.data.status == 1) {
        setProjectDetail(res.data.data)
      }
    })
  }, [count])
  // employee list

  return (
    <>
  {/* <ToastContainer /> */}
      <div className="">
      <Menu
        className="mt-51 abc"
        onClick={handleClick}
        // style={{ width: 250 }}
        defaultSelectedKeys={[activeId]}
        defaultOpenKeys={['sub1']}
        mode="inline"
      // theme='dark'
      >
        <SubMenu key="sub1" icon={<AppstoreOutlined />} title="General">
          <Menu.Item key="View">
            <Link to={`/project/view/${projectId}`}>View</Link>
          </Menu.Item>
          <Menu.Item key="AllTasks">
            <Link to={`/project/tasks/${projectId}`}>All Tasks</Link>
          </Menu.Item>
          <Menu.Item key="Members">
            <Link to={`/project/member/${projectId}`}>Members</Link>
          </Menu.Item>
          <Menu.Item key="Milestone">
            <Link to={`/project/milestone/${projectId}`}>Milestone</Link>
          </Menu.Item>
          <Menu.Item key="Sprint">
            <Link to={`/project/sprint/${projectId}`}>Sprint</Link>
          </Menu.Item>
        </SubMenu>

        {true || Permission()[0]?.All == '1' ? (
          <>
            <Menu.Item key="34">
              <Button
                className="btn-add-link tablHeader"
                onClick={() => setTaskAddIsShow(true)}
              >
                <MDBIcon className="plus-btn" fas icon="plus" />
                <span
                  style={{
                    paddingLeft: '15px',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  Create Task
                </span>
              </Button>
              {taskAddIsShow ? (
                <AddTask
                  inSidebar={true}
                  btnStyl={true}
                  projectDetail={projectDetail}
                  setFlage={setCount}
                  setTaskAddIsShow={(a) => {
                    setTaskAddIsShow(a)
                  }}
                />
              ) : null}
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        {Permission()[0]?.All == '1' ? (
          <>
            <Menu.Item key="33">
              <DeleteModel id={projectId} />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        {/* <Add btnStyl={true} projectDetail={projectDetail} setList={setCount} /> */}
      </Menu>
    </div>
    </>
  )
}

export default ProjectSidebar
