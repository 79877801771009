import moment from "moment";
import { Button, Form, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AxiosInstance from "../../../service/AxiosInstance";
import response from "../../../assets/images/response.jpg";
import { MDBCard } from "mdbreact";
import ReactLoader from "../../../components/common/ReactLoader";
const KRA = () => {
  const [form] = Form.useForm();
  const [fill, setFill] = useState(false);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const UserData = localStorage.getItem("userdata");
  let Position_Id = UserData ? JSON.parse(UserData)[0].Position_id : undefined;
  const [employees, setEmployees] = useState();
  const [select, setSelect] = useState({});
  const [searchEmployees, setSearchEmployees] = useState();
  const [data, setData] = useState();
  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [q4, setQ4] = useState(null);
  const [q5, setQ5] = useState(null);
  const [TLname, setTLname] = useState("");
  const options = [];
  const handleCancel = (errorInfo) => {
    form.resetFields();
  };

  const setSelectValue = (value) => {
    setSelect(JSON.parse(value));
    setTLname(value);
    setError(false);
  };

  const [error, setError] = useState(false);
  const [errorq1, setErrorQ1] = useState(false);
  const [errorq2, setErrorQ2] = useState(false);
  const [errorq3, setErrorQ3] = useState(false);
  const [errorq4, setErrorQ4] = useState(false);
  const [errorq5, setErrorQ5] = useState(false);

  const onFinish = () => {
    setLoading(true)
    if (
      q1 !== null &&
      q2 !== null &&
      q3 !== null &&
      q4 !== null &&
      q5 !== null &&
      Object.keys(select).length !== 0
    ) {
      setError("");
      const formdata = new URLSearchParams();
      formdata.append("Q1", q1);
      formdata.append("Q2", q2);
      formdata.append("Q3", q3);
      formdata.append("Q4", q4);
      formdata.append("Q5", q5);
      formdata.append("teamLeader_id", select.id);
      AxiosInstance.post("/auth/kra/employee", formdata).then((res) => {
        toast.success("Your Response has been recorded!");
        setLoading(false)
        setData(res.data.data);
        KraList();
      });
    } else {
      if (TLname === "") {
        setError("Please select the TL* ");
      }
      if (q1 === null) {
        setErrorQ1("Please add rating for Q1*");
      }
      if (q2 === null) {
        setErrorQ2("Please add rating for Q2*");
      }
      if (q3 === null) {
        setErrorQ3("Please add rating for Q3*");
      }
      if (q4 === null) {
        setErrorQ4("Please add rating for Q4*");
      }
      if (q5 === null) {
        setErrorQ5("Please add rating for Q5*");
      }
    }
  };

  const KraList = () => {
    const formdata = new URLSearchParams();
    // formdata.append("Q1", TLname);
    // formdata.append("Role", "React/Node");
    AxiosInstance.post("auth/kra/list").then(async (result) => {
      let res = result.data.data.filter((item) => {
        const currentMonth = new Date().getMonth()+1;
        var previousMonth = moment(item?.date).format("M")
        if(currentMonth==previousMonth){
          if (item.name._id == JSON.parse(UserData)[0]._id) {
            setFill(true);
          }
        }
      });
    });
  };

  useEffect(() => {
    KraList();
  }, []);

  const onFinishFailed = (errorInfo) => {
    if (TLname === "") {
      setError("Please select the TL* ");
    }
    if (q1 === null) {
      setErrorQ1("Please add rating for Q1*");
    }
    if (q2 === null) {
      setErrorQ2("Please add rating for Q2*");
    }
    if (q3 === null) {
      setErrorQ3("Please add rating for Q2*");
    }
    if (q4 === null) {
      setErrorQ4("Please add rating for Q2*");
    }
    if (q5 === null) {
      setErrorQ5("Please add rating for Q2*");
    }
  };

  const onSearch = (value) => {
    const emp = searchEmployees.filter((e) => {
      // return e.name.includes(value);
      return e.name.toLowerCase().includes(value.toLowerCase());
    });
    // setTLname(emp.name);
    setEmployees(emp);
  };

  useEffect(() => {
    AxiosInstance.get("auth/employees").then(async (result) => {
      if (result.data.data) {
        let employeesdata = await result.data.data.map((item) => {
          return {
            name: `${item.First_name} ${item.Middle_name} ${item.Last_name}`,
            id: item._id,
          };
        });
        setEmployees(employeesdata);
        setSearchEmployees(employeesdata);
      }
    });
  }, []);

  return (
    <div className="demo">
      {loading ? <ReactLoader loading={loading}>

      </ReactLoader> : <>
        {fill !== true ? (
          <div className="page-content">
            <div className="leave-set-btn2">
              <h3 className="tablHeader kra-form-heading">
                KRA Form For Employee:
              </h3>
            </div>
            <MDBCard className="page-content">
              <div className="questions">
                {/* <ToastContainer /> */}
                <Form
                  form={form}
                  name="control-hooks"
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  initialValues={{
                    remember: true,
                  }}
                >
                  <div className="kra-col">
                    <div className="kra-field">
                      <h6>
                        1.How well you performed your task in{" "}
                        {moment().subtract(1, "month").format("MMM")}{" "}
                        {moment().format("YYYY")} ?
                      </h6>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        value={q1}
                        onChange={(e) => {
                          setErrorQ1(false);
                          setQ1(e.target.value);
                        }}
                      >
                        <Radio.Group>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                          <Radio value="6">6</Radio>
                          <Radio value="7">7</Radio>
                          <Radio value="8">8</Radio>
                          <Radio value="9">9</Radio>
                          <Radio value="10">10</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <span style={{ color: "red" }}>{errorq1}</span>
                    </div>

                    <div className="kra-field">
                      <h6>4.Rate yourself in punctuality from 1 to 10 ?</h6>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        value={q1}
                        onChange={(e) => {
                          setErrorQ4(false);
                          setQ4(e.target.value);
                        }}
                      >
                        <Radio.Group>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                          <Radio value="6">6</Radio>
                          <Radio value="7">7</Radio>
                          <Radio value="8">8</Radio>
                          <Radio value="9">9</Radio>
                          <Radio value="10">10</Radio>
                          {/* {values.map((item, index) => {
                return <Radio value={values[index]}>{values[index]}</Radio>;
              })} */}
                        </Radio.Group>
                      </Form.Item>
                      <span style={{ color: "red" }}>{errorq4}</span>
                    </div>
                  </div>
                  <div className="kra-col">
                    <div className="kra-field">
                      <h6>2.How capable were you to meet your deadlines ?</h6>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        value={q1}
                        onChange={(e) => {
                          setErrorQ2(false);
                          setQ2(e.target.value);
                        }}
                      >
                        <Radio.Group>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                          <Radio value="6">6</Radio>
                          <Radio value="7">7</Radio>
                          <Radio value="8">8</Radio>
                          <Radio value="9">9</Radio>
                          <Radio value="10">10</Radio>
                          {/* {values.map((item, index) => {
                return <Radio value={values[index]}>{values[index]}</Radio>;
              })} */}
                        </Radio.Group>
                      </Form.Item>
                      <span style={{ color: "red" }}>{errorq2}</span>
                    </div>
                    <div className="kra-field">
                      <h6>
                        5.Rate yourself on overall performance from 1 to 10 ?
                      </h6>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        value={q1}
                        onChange={(e) => {
                          setErrorQ5(false);
                          setQ5(e.target.value);
                        }}
                      >
                        <Radio.Group>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                          <Radio value="6">6</Radio>
                          <Radio value="7">7</Radio>
                          <Radio value="8">8</Radio>
                          <Radio value="9">9</Radio>
                          <Radio value="10">10</Radio>
                          {/* {values.map((item, index) => {
                return <Radio value={values[index]}>{values[index]}</Radio>;
              })} */}
                        </Radio.Group>
                      </Form.Item>
                      <span style={{ color: "red" }}>{errorq5}</span>
                    </div>
                  </div>
                  <div className="kra-col">
                    <div className="kra-field">
                      <h6>3.Rate yourself as a team player from 1 to 10 ?</h6>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        value={q5}
                        onChange={(e) => {
                          setErrorQ3(false);
                          setQ3(e.target.value);
                        }}
                      >
                        <Radio.Group>
                          <Radio value="1">1</Radio>
                          <Radio value="2">2</Radio>
                          <Radio value="3">3</Radio>
                          <Radio value="4">4</Radio>
                          <Radio value="5">5</Radio>
                          <Radio value="6">6</Radio>
                          <Radio value="7">7</Radio>
                          <Radio value="8">8</Radio>
                          <Radio value="9">9</Radio>
                          <Radio value="10">10</Radio>
                          {/* {values.map((item, index) => {
                return <Radio value={values[index]}>{values[index]}</Radio>;
              })} */}
                        </Radio.Group>
                      </Form.Item>
                      <span style={{ color: "red" }}>{errorq3}</span>
                    </div>

                    <div className="kra-field">
                      <Form.Item
                        label="Please Select Your TL"
                        rules={[
                          {
                            name: "value",
                            required: true,
                            message: "Please Select Your Team Leader.",
                          },
                        ]}
                      >
                        <Select
                          className="search-bar-kra"
                          placeholder="Please Select Your TL"
                          showSearch="true"
                          value={TLname}
                          onChange={setSelectValue}
                          onSearch={onSearch}
                        >
                          {employees?.map((item, index) => {
                            return (
                              <Option
                                value={JSON.stringify({
                                  name: item?.name,
                                  id: item.id,
                                })}
                              >
                                {item?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <span style={{ color: "red" }}>{error}</span>
                    </div>
                  </div>
                  <Form.Item>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        className="pms-same-btn"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        // onClick={onFinish}
                        style={{ marginRight: "10px" }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={handleCancel}
                        className="pms-same-btn-cancel"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </MDBCard>
          </div>
        ) : (
          <div className="page-content">
            <div className="response-main-card">
              <MDBCard className="page-content response-img-part">
                <img src={response}></img>
                <h3 className="tablHeader kra-form-heading">
                  Your Response has been recorded!
                </h3>
              </MDBCard>
            </div>
          </div>
        )}
      </>}
    </div>
  );
};

export default KRA;
