import { Button, Modal, Row, Form, Col } from 'antd'
import TextArea from 'antd/lib/input/TextArea'


import React, { useState } from 'react'
import AxiosInstance from '../../../../service/AxiosInstance'

const Reject = (props) => {
  const [form] = Form.useForm()

  const showModal = () => {
    props.setIsModalVisible(true)
  }

  const handleOk = () => {
    props.setIsModalVisible(false)
  }

  const handleCancel = () => {
    props.setIsModalVisible(false)
  }
  const onFinish = (values) => {
    const formData = new URLSearchParams()

    formData.append('leave_id', props.leaveId._id)
    formData.append('status_comment', values.status_comment)
    formData.append('status', props.status)

    
    AxiosInstance.post('auth/leave/action/takenByTeamLeader', formData)
      .then((res) => {
        if (res.data.status == 1) {
          props.propData()
          handleCancel()
          
        }

      })
      .catch((err) => console.log(err, 'err'))





  }
  const onFinishFailed = (values) => { }

  return (
    <>

      <Modal
        title={'Reason for rejection'}
        visible={props.isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          // name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <Row className="mx-0">
            <Col span={24} className="text-area">
              <Form.Item
                label="Reason"
                name="status_comment"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Reason!',
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div>
              <Button
                type="danger"
                className="mb-0 pms-same-btn"
                htmlType="submit"
                style={{ marginRight: '10px' }}
              >
                Reject
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">Cancel</Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Reject;
