import React, { useState } from 'react'
import { Card, Drawer, Timeline, Avatar, Comment, Tooltip, Badge } from 'antd'
import { LeaveDates } from '../../../../components/common/Coomon'
import moment from 'moment'

const Logs = (props) => {
  const { leave, visible, logs } = props
  const color = leave.Status == 'Approved' ? 'green' : 'gray'

  return (
    <>
      <Drawer
        title="Leave logs"
        placement="right"
        onClose={props.onClose} 
        visible={visible}
      >
        <Badge.Ribbon text={leave.Status} color={color}>
          <Card
            size="small"
            title={ leave?.Employee_ID ? 
                leave?.Employee_ID?.First_name +
              ' ' +
              leave?.Employee_ID?.Last_name : 'Team Member Activity'
            }
            style={{ width: 300 }}
          >
            {leave?.Status && <LeaveDates items={leave} />}
            <p>
              Unpaid{' '}
              <span className="info">
                Requested on{' '}
                {moment(leave?.Create_at).format('MMM DD, YYYY HH:mm a')}
              </span>
            </p>
            <p>{leave.HR_reject_reason}</p>
            {/* <p>Card content</p> */}
          </Card>
        </Badge.Ribbon>
        <Timeline>
          {logs.map((log) => (
            <Timeline.Item>
              <Comment
                author={
                  <a>
                    {log.Created_by?.First_name} {log.Created_by?.Last_name}
                  </a>
                }
                avatar={
                  <Avatar
                    src="https://joeschmoe.io/api/v1/random"
                    alt= {log.Created_by?.First_name +' '+log.Created_by?.Last_name}
                  />
                }
                content={
                  <p>
                    <b>{log.Action_Log}</b>
                    <br />
                    {log.Comment}
                  </p>
                }
                datetime={
                  <Tooltip
                    title={moment(log.Create_at).format('YYYY-MM-DD HH:mm:ss')}
                  >
                    <span>{moment(log.Create_at).fromNow()}</span>
                  </Tooltip>
                }
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </Drawer>
    </>
  )
}
export default Logs
