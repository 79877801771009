export const Loading = () => (
    <div class="vh-loader">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"
            viewBox="0 0 385.515 408.68">
            <path id="Path_24" data-name="Path 24"
                d="M59,47,179.471,320.793l67.392-132.7,52.951.144-94.5,191.231L237.3,452.3,441.359,47.078H367.971l-41.777,87.439H272.858l44.61-87.554H150.695l21.178,51.67H216.09l-34.9,87.064L119.713,47Z"
                transform="translate(-57.467 -45.963)" fill="#2e9ac2" stroke="#2e9ac2" stroke-width="6"
                class="svg-elem-1">
            </path>
        </svg>
    </div>
  );