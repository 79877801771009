import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SortingSelect = ({ setSortingValue, optionsArray, addStyle, placeholder }) => {
  const handleOnChange = (value) => {
    // console.log(`selected ${value}`);
    setSortingValue(value);
  };
console.log(placeholder , "placeHolder")
  return (
    <>
      <Select
       placeholder='Select'
        onChange={handleOnChange}
        /*defaultValue="lucy"*/ style={addStyle}
        allowClear
      >
        {optionsArray?.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default SortingSelect;
