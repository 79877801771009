import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import { Link, useParams, useLocation } from "react-router-dom";
const OtherDetailsTab = () => {
  const params = useParams();
  const location = useLocation();
  return (
    <>
      <MDBNavbarNav className="employee-new-tab">
        <MDBNavbarItem>
          <Link to={`/employeeOtherDetail/${params?.id}`}>
            {" "}
            <MDBNavbarLink
              className={
                location.pathname === `/employeeOtherDetail/${params?.id}` &&
                "active"
              }
              aria-current="page"
              href="#"
            >
              My Leave
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to={`/employeeAttendance/${params?.id}`}>
            {" "}
            <MDBNavbarLink
              aria-current="page"
              href="#"
              className={
                location.pathname === `/employeeAttendance/${params?.id}` &&
                "active"
              }
            >
              My attendance
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to={`/employeeDailyLogs/${params?.id}`}>
            {" "}
            <MDBNavbarLink
              aria-current="page"
              href="#"
              className={
                location.pathname === `/employeeDailyLogs/${params?.id}` &&
                "active"
              }
            >
              My Daily Logs
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </>
  );
};

export default OtherDetailsTab;
